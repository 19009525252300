
<template>
  <div class="main">
    <div class="wrap d-flex justify-content-between gap-3">
      <div class="menus">
        <el-card shadow="never">
          <h3>My account</h3>
          <el-menu
              :default-active="menuIndex"
              @select="handleSelectMenu">
            <el-menu-item index="1">
              <span>Overview</span>
            </el-menu-item>
            <el-menu-item index="2">
              <span slot="title">Wish list</span>
            </el-menu-item>
            <el-menu-item index="3">
              <span slot="title">Viewed history</span>
            </el-menu-item>
            <el-menu-item index="4">
              <span slot="title">Orders</span>
            </el-menu-item>
            <el-menu-item index="5">
              <span slot="title">Sample Request</span>
            </el-menu-item>
            <el-menu-item index="6">
              <span slot="title">RFQ History</span>
            </el-menu-item>
            <el-menu-item index="7">
              <span slot="title">Shipping address</span>
            </el-menu-item>
            <el-menu-item index="8">
              <span slot="title">Settings</span>
            </el-menu-item>
            <el-menu-item index="9" @click="openHelp">
              <span slot="title">Support</span>
            </el-menu-item>
            <el-menu-item index="10" @click="openPrivacy">
              <span slot="title">Privacy Policy</span>
            </el-menu-item>
          </el-menu>
        </el-card>
      </div>
      <div class="banners">
        <div v-if="menuIndex === '1'">
          <overview-panel/>
        </div>

        <div v-if="menuIndex === '2'">
          <favourite-panel/>
        </div>

        <div v-if="menuIndex === '3'">
          <viewed-panel/>
        </div>

        <div v-if="menuIndex === '4'">
          <order-panel :orderType="orderType"/>
        </div>

        <div v-if="menuIndex === '5'">
          <sample-request-panel/>
        </div>

        <div v-if="menuIndex === '6'">
          <RFQHistoryPanel/>
        </div>

        <div v-if="menuIndex === '7'">
          <address-panel/>
        </div>

        <div v-if="menuIndex === '8'">
          <setting-panel/>
        </div>

        <div v-if="menuIndex === '9'" @click="openHelp">
          <overview-panel/>
        </div>

        <div v-if="menuIndex === '10'" @click="openPrivacy">
          <overview-panel/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OverviewPanel from "@/components/PersonalCenter/OverviewPanel/index.vue";
import OrderPanel from "@/components/PersonalCenter/OrderPanel/index.vue";
import AddressPanel from "@/components/PersonalCenter/AddressPanel/index.vue";
import FavouritePanel from "@/components/PersonalCenter/FavouritePanel/index.vue";
import SettingPanel from "@/components/PersonalCenter/SettingPanel/index.vue";
import ViewedPanel from "@/components/PersonalCenter/ViewedPanel/index.vue";
import SampleRequestPanel from "@/components/PersonalCenter/SampleRequestPanel/index.vue";
import RFQHistoryPanel from "@/components/PersonalCenter/RFQHistoryPanel/index.vue";
import { eventBus } from "@/common/eventBus";
import { isLogin } from "@/utils/utils";

export default {
  name: "PersonalCenterPage",
  components: {
    FavouritePanel,
    OverviewPanel,
    ViewedPanel,
    OrderPanel,
    SettingPanel,
    AddressPanel,
    SampleRequestPanel,
    RFQHistoryPanel
  },
  data() {
    return {
      menuIndex: "1",
      orderType: "all",
      user: {}
    }
  },
  created() {
    if (isLogin()) {
      this.user = localStorage.getItem("userinfo");
    } else {
      this.$router.push("/login");
    }

    if (this.$route.params.index) {
      this.menuIndex = this.$route.params.index;
    }

    eventBus.$on("toPanel", (msg) => {
      this.menuIndex = msg.toString();
    })

    eventBus.$on("toOrderPanel", (msg) => {
      this.menuIndex = "4";
      this.orderType = msg;
    })
  },
  methods: {
    handleSelectMenu(index) {
      this.menuIndex = index;
      if (this.menuIndex === "4") {
        this.orderType = "all";
      }
    },
    openHelp() {
      window.open("/contact-us");
    },
    openPrivacy() {
      window.open("/privacy-policy");
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
