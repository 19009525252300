<template>
  <div class="faq">
    <div class="banner">
      <img src="@/assets/images/FAQ Banner.jpg" alt=""/>
      <h1>FAQ</h1>
    </div>
    <div class="content">
      <el-collapse v-model="activeName">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-collapse-item title="How to Register/Login:?" v-model="activeNames" name="1">
              <div>
                Click on "Register" at the top right corner of the webpage, enter your valid email address, and receive a verification code to complete registration.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How do I buy on Bulkbuy.com?" name="2">
              <div>
                <p>Step 1: Browse for products on the homepage. You can search for products by image or keywords.</p>
                <p>Step 2: Click "Add to Cart" or "Chat Now" on the product details page to contact customer service and discuss order details such as pricing, shipping methods, and shipping costs.</p>
                <p>Step 3: Confirm your order and submit it.</p>
                <p>For custom products, please contact us via Messenger to send inquiries or order requests.</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="When will it be shipped?" name="3">
              <div>
                We strive to process and dispatch orders promptly. For standard products, expect shipment within 7-15 working days upon payment confirmation. Please note that shipping times may vary based on factors beyond our control.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="Will my goods be tested for quality before shipment?" name="4">
              <div>
                We have a stringent quality control process in place. Our dedicated team conducts thorough inspections to ensure that all products meet our high standards before they are shipped out.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How can I access customized services?" name="5">
              <div>
                <p>Currently, there are three ways to access our custom services on the platform:</p>
                <p>Locate the detail page of the product you wish to customize, add it to your cart, and proceed to checkout. Please make sure to note in the comments that customization is requested. Our customer service team will then reach out to you proactively to assist further.</p>
                <p>Find the detail page of the product you want to customize and click the "Customize" button. Submit your specific customization requirements, and our customer service team will proactively contact you to discuss your needs.</p>
                <p>Use the Request for Quotation (RFQ) feature available on all pages. Submit your customization request, and our customer service team will proactively reach out to you to help fulfill your request.</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How Can I get free samples?" name="6">
              <div>
                On the detail page of the product you want, click need a sample, submit the relevant information, you can get the sample you need.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How do I check my sample request?" name="7">
              <div>
                Click on "My Account," then go to "My Sample Requests" to view them.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to check order status?" name="8">
              <div>
                Go to Orders > All Orders, find all your orders, and check their statuses.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to cancel or modify an order?" name="9">
              <div>
                Go to Orders > All Orders, find the order you want to modify.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How do I check if my item has shipped?" name="10">
              <div>
                <p>Step 1: Go to Orders > All Orders and find your paid order.</p>
                <p>Step 2: Check the shipping status.</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to track delivery status?" name="11">
              <div>
                <p>Step 1: Go to Orders > All Orders, find your paid order, and view the tracking number.</p>
                <p>Step 2: Enter the tracking number to check the delivery status.</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to bookmark?" name="12">
              <div>
                <p>Step 1: Click on the heart icon at the top left corner of the product image to bookmark it.</p>
                <p>Step 2: Go to "My Account" and then "My Bookmarks" to view all your bookmarked products.</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to change my password?" name="13">
              <div>
                Go to "My Account" section and select "Settings". click Change Password, follow the prompts, you can finish.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to Modify Account Information?" name="14">
              <div>
                Go to "My Account" section and select "Settings". click on the details you'd like to change and make your edits.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="What if I forget my password?" name="15">
              <div>
                <p>Step 1: Go to the login page, click on 'Forgot Password.'</p>
                <p>Step 2: Proceed to the password reset page, enter your new password, and confirm by receiving a verification code in your registered email."</p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to subscribe?" name="16">
              <div>
                At the bottom left corner of the page, enter your email address to subscribe.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="How to participate in platform promotions?" name="17">
              <div>
                New registered users can enjoy a first-order discount with no minimum spend and 10% off the total order amount. This discount policy is automatically applied to your account after successful registration. It will be displayed and settled automatically during checkout in your shopping cart.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="Comments and Suggestions" name="18">
              <div>
                <p>We strive for excellence and your input drives our progress. If you have a suggestion or need to raise a concern, we're here to listen and take action. Please share your thoughts, and we'll ensure they contribute to our continuous improvement.</p>
                <p>Submit your feedback <a href="/contact-us">[here].</a></p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="24">
            <el-collapse-item title="Questions on the use of the platform's functionality" name="19">
              <div>
                <p>If you're finding issues while using the platform, we're here to provide answers and help streamline your experience.</p>
                <p>To learn more about the platform's features, find answers to common platform usage questions <a href="/contact-us">[here].</a></p>
                <p>If that still doesn't solve your problem, you can contact us directly <a href="/contact-us">[here]!</a></p>
              </div>
            </el-collapse-item>
          </el-col>
        </el-row>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQPage',
  data() {
      return {
        activeName: '1'
      };
    },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
