<template>
  <div class="content">
    <div class="success-content">
      <div class="title">
        <p><img src="@/assets/images/thank-you/chenggong2.svg" alt="success" width="80"></p>
        <h3>Thanks For Your Inquiry</h3>
      </div>
      <div class="description">
        <p>Appreciate your confidence in us! We would like to inform you that we will respond within one day. We kindly request that you check your inbox and spam folder for an email from (<span style="color:var(--theme-color);">@chinabulkbuy.com</span>).</p>
      </div>
      <div class="jump">
        <span><a href="/">Back To Home</a></span>
        <span><a href="/account">To My Account</a></span>
      </div>
      <div class="show-image">
<!--        <img src="@/assets/images/thank-you/thank-you-1.webp" alt="thank you">-->
        <img src="@/assets/images/thank-you/Email-Marketing-Automation.jpg" alt="thank you">
<!--        <img src="@/assets/images/thank-you/email-list-1.png" alt="thank you">-->
      </div>
    </div>
    <div class="product-category">
      <div class="product-title">
        <div class="title">
          <h3>China Sourcing Products</h3>
        </div>
        <div class="description">
          <span>We provide a varied range of high quality daily-use items with 10895 factory resources.</span>
        </div>
        <div class="categories">
          <div class="item">
            <a href="/product-category/apparel-accessories" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Apperal_Accessories.webp" alt="Apperal & Accessories" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Apperal & Accessories</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/gifts-promotion" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Crafts_Gifts.jpg" alt="Crafts & Gifts" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Gifts & Promotion</h3>
            </div>
              </a>
          </div>
          <div class="item">
            <a href="/product-category/electronics-accessories" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Electronics_Accessories.jpg" alt="Electronics & Accessories" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Electronics & Accessories</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/home-garden" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Home_Garden.webp" alt="Home & Garden" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Home & Garden</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/luggage-bags" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Luggage_bags.webp" alt="Luggage & bags" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Luggage & bags</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/office-culture" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Office_Culture.webp" alt="" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Office & Culture</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/pet-supplies" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Pet_Supplies.webp" alt="Pet Supplies" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Pet Supplies</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/sports-entertainment" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Sports_Entertainment.webp" alt="Sports & Entertainment" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Sports & Entertainment</h3>
            </div>
            </a>
          </div>

          <div class="item">
            <a href="/product-category/automotive-motorcycle" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Auto Accessories.jpg" alt="Sports & Entertainment" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Auto Accessories</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/toys-games" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Toys & Games(1).webp" alt="Sports & Entertainment" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Toys & Games</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/adult-products" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Adult Products(1).webp" alt="Sports & Entertainment" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Adult Products</h3>
            </div>
            </a>
          </div>
          <div class="item">
            <a href="/product-category/beauty-health" target="_blank">
            <div class="category-img">
              <img src="@/assets/images/thank-you/Beauty & Health.jpg" alt="Sports & Entertainment" width="420" height="263">
            </div>
            <div class="category-title">
              <h3>Beauty & Health</h3>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou"
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>