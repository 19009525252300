<template>
  <el-footer>
    <div class="pc-footer-wrap">
      <el-row type="flex" align="bottom">
        <el-col :span="7">
          <div class="logo">
            <a href="/">
              <img src="@/assets/images/logo.png" alt="logo" />
            </a>
          </div>
          <h4>
            Subscribe to our newsletter for timely updates on new products.
          </h4>
          <div class="pc-footer-subscribe">
            <el-form :model="form" status-icon :rules="rules" ref="form">
              <el-form-item prop="subscriber_email">
                <el-input
                  type="email"
                  placeholder="E-mail"
                  v-model="form.subscriber_email"
                ></el-input>
                <el-button type="quote" @click="submit">Subscribe</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="pc-footer-checked">
            <el-checkbox v-model="checked"
              >Send me emails about new arrivals, hot items, daily savings, & more.</el-checkbox
            >
          </div> -->
        </el-col>
        <el-col :span="14" :offset="3">
          <el-row :gutter="40">
            <el-col :span="12" class="pc-footer-links">
              <h3>Category</h3>
              <el-row>
                <el-col :span="12">
                  <a href="/product-category/apparel-accessories">Apparel & Accessories</a>
                  <a href="/product-category/pet-supplies">Pet Supplies</a>
                  <a href="/product-category/home-garden">Home & Garden</a>
                  <a href="/product-category/automotive-motorcycle">Auto Accessories</a>
                  <a href="/product-category/adult-supplies">Adult Supplies</a>
                  <a href="/product-category/toys-games">Toys & Games</a>
                </el-col>
                <el-col :span="12">
                  <a href="/product-category/sports-entertainment">Sports & Entertainment</a>
                  <a href="/product-category/luggage-bags">Luggage & Bags</a>
                  <a href="/product-category/gifts-promotion">Gifts & Promotion</a>
                  <a href="/product-category/beauty-health">Beauty & Health</a>
                  <a href="/product-category/office-culture">Office & Culture</a>
                  <a href="/product-category/electronics-accessories">Electronics & Accessories</a>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" class="pc-footer-links">
              <h3>About</h3>
              <a href="/about-us#whoweare">Who we are</a>
              <a href="/about-us#whatwedo">What we do</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/faq">FAQ</a>
            </el-col>
            <el-col :span="6" class="pc-footer-links">
              <h3>Contact Us</h3>
              <a href="/register">Register for Free</a>
              <a href="/request-for-quotation">Request for Quotation</a>
              <a href="/contact-us">Support</a>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="copyright">
          <p>Copyright 2024 Ningbo Bulkbuy Technology Co.,Ltd. All Rights Reserved.</p>
        </el-col>
      </el-row>
    </div>
  </el-footer>
</template>

<script>
import { post } from "@/utils/request";
import { Message } from "element-ui";

export default {
  name: "GlobalFooter",
  data() {
    return {
      // checked: true,
      form: {
        subscriber_email: "",
      },
      rules: {
        subscriber_email: [
          {
            required: true,
            message: "Please fill your email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address.",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      post("/subscribe", this.form)
        .then((res) => {
          window.open("/thank-you", "_blank");
          if (res.code === 1) {
            this.form = {};
          } else {
            Message.error({
              message: res.msg,
              offset: 300
            });
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
