import axios from "axios";
import { getToken } from "@/utils/utils";
import { Message } from "element-ui";

const baseURL = process.env.VUE_APP_BASEURL;

function request(options) {
    const token = getToken()

    const instance = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
            Authorization: token || undefined
        }
    })
    instance.interceptors.response.use(
        response => {
            return response
        },
        (error) => {
            // status 是 HTTP 状态码
            const status = get(error, "response.status")
            switch (status) {
                case "400":
                    error.message = "Bad Request"
                    break
                case "403":
                    error.message = "Request Refused"
                    break
                case "404":
                    error.message = "Not Found"
                    break
                case "408":
                    error.message = "Request Overtime"
                    break
                case "500":
                    error.message = "Service Error"
                    break
                default:
                    error.message = "Other Error"
                    break
            }
            Message.error(error.message)
            return Promise.reject(error)
        }
    )

    options = {...options, instance}

    return new Promise((resolve, reject) => {
        instance(options)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function get(url) {
    return request({
        method: "GET",
        url
    })
}

export function post(url, data) {
    return request({
        method: "POST",
        url,
        data
    })
}

export function postForm(url, data) {
    return request({
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        url,
        data
    })
}