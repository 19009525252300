<template>
  <div id="app">
    <GlobalHeader />
    <router-view />
    <GlobalFooter />
  </div>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter
  },
}
</script>
