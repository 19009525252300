<template>
  <div class="order-panel">
    <el-card class="order-tab-card" shadow="never">
      <div class="tab-panel">
        <div class="order-tabs">
          <el-tabs v-model="activeTab" @tab-click="handleTabChange">
            <el-tab-pane label="View all" name="all"/>
            <el-tab-pane name="pending">
              <template slot="label">
                <div>Pending<el-badge class="mark" :value="count.pending"></el-badge></div>
              </template>
            </el-tab-pane>
            <el-tab-pane name="producing">
              <template slot="label">
                <div>Producing<el-badge class="mark" :value="count.producing"></el-badge></div>
              </template>
            </el-tab-pane>
            <el-tab-pane name="shipping">
              <template slot="label">
                <div>In transit<el-badge class="mark" :value="count.shipping"></el-badge></div>
              </template>
            </el-tab-pane>
            <el-tab-pane name="complete">
              <template slot="label">
                <div>Complete<el-badge class="mark" :value="count.complete"></el-badge></div>
              </template>
            </el-tab-pane>
            <el-tab-pane label="Canceled" name="canceled"/>
          </el-tabs>
        </div>
      </div>
    </el-card>
    <el-card class="order-list-card" shadow="never">
      <div class="order-table">
        <el-table
            :data="orderList"
            empty-text="Empty"
            v-loading="loading">
          <el-table-column label="Sort" align="center" type="index">
            <template #default="scope">
              <span>{{ (pagination.current_page - 1) * pagination.page_size + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Order number" prop="orderNumber" align="center"/>
          <el-table-column label="Order time" prop="orderTime" align="center"/>
          <el-table-column label="Price" prop="price" align="center"/>
          <el-table-column label="Order status" prop="orderStatus" align="center"/>
          <el-table-column label="Commodity" align="center">
            <template #default="scope">
              <el-button type="text" @click="viewOrderDetail(scope)">view<b-icon icon="chevron-right"/></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="order-pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="pagination.total"
            :current-page.sync="pagination.current_page"
            @current-change="handlePageChange"
            @prev-click="handlePrevClick"
            @next-click="handleNextClick">
        </el-pagination>
      </div>
      <div class="hint">
        <span>If you need to cancel or edit order content, please contact support@chinabulkbuy.com</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { post } from "@/utils/request";
// import { eventBus } from "@/common/eventBus";

export default {
  name: 'OrderPanel',
  data() {
    return {
      activeTab: "all",
      type: "",
      loading: false,
      orderElement: {
        orderNumber: "",
        orderTime: "",
        price: "",
        orderStatus: ""
      },
      orderList: [],
      pagination: {
        total: 0,
        total_page: 10,
        current_page: 1,
        begin_page: 1,
        page_size: 10
      },
      count: {
        complete: 0,
        pending: 0,
        shipping: 0,
        producing: 0
      },
      beginPage: 1,
      pageSize: 10
    }
  },
  props: {
    orderType: String
  },
  created() {
    // this.activeTab = this.orderType;
    this.handleTabChange();
    this.getOrderCount();
  },
  methods: {
    async getOrderList() {
      this.loading = true;
      this.orderList = [];
      post("/order_list", {
        type: this.type,
        beginPage: this.beginPage,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 1) {
          this.pagination = {
            total: res.data.total,
            total_page: res.data.total_page,
            current_page: res.data.current_page,
            page_size: res.data.page_size
          }

          res.data.list.forEach(item => {
            this.orderElement = {
              orderNumber: item.order_number,
              orderTime: item.created_at,
              price: item.quote_currency_code + parseFloat(item.quote_grand_total).toFixed(2),
              orderStatus: item.status
            }
            this.orderList.push(this.orderElement);
          })
        } else {
          console.log(res.msg);
        }
        this.loading = false;
      })
    },
    getOrderCount() {
      post("/order_count").then(res => {
        if (res.code === 1) {
          this.count = res.data;
        }
      })
    },
    handleTabChange() {
      this.type = this.activeTab === "all" ? "" : this.activeTab;
      this.getOrderList();
    },
    handlePageChange() {
      this.beginPage = this.pagination.current_page;
      this.getOrderList();
    },
    handlePrevClick() {
      this.beginPage = --this.pagination.current_page;
      this.getOrderList();
    },
    handleNextClick() {
      this.beginPage = ++this.pagination.current_page;
      this.getOrderList();
    },
    viewOrderDetail(scope) {
      // eventBus.$emit("toOrderDetail", scope.row.orderNumber.toString());
      window.open("/order-detail?order=" + scope.row.orderNumber.toString());
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>