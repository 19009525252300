<template>
  <div class="content">
    <div class="find_us">
      <div class="how_to_find_us">
        <div class="about_find_us">
          <h1>We're here for you</h1>
          <p>Fill out your needs and a ChinaBulkBuy representative will reach out to you. Have a simple question? Check out our FAQ.</p>
        </div>
        <div class="about_us_msg">
          <!-- <div class="our_way">
            <div class="type">
              <span><img src="../../assets/images/contact_dianhua.svg" alt="" width="26"></span>
            </div>
            <div class="type_detail">
              <p>Roc Wang</p>
              <p>+8613735652051</p>
            </div>
          </div> -->
          <div class="our_way">
            <div class="type">
              <span><img src="../../assets/images/contact_youjian.svg" alt="" width="26"></span>
            </div>
            <div class="type_detail">
              <p>support@chinabulkbuy.com</p>
            </div>
          </div>
          <div class="our_way">
            <div class="type">
              <span><img src="../../assets/images/contact_dingwei.svg" alt="" width="26"></span>
            </div>
            <div class="type_detail">
              <p>Ningbo R&D Yuan C Zone 12 Building, Yinzhou, Ningbo, Zhejiang, China</p>
            </div>
          </div>
        </div>
      </div>
      <div class="inquiry">
        <div class="form">
          <el-form :model="contactusForm" status-icon :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Name" prop="name">
                  <el-input v-model="contactusForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Email" prop="email">
                  <el-input v-model="contactusForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Company" prop="company">
                  <el-input v-model="contactusForm.company"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Phone" prop="phone">
                  <el-input v-model="contactusForm.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Message" prop="message">
                  <el-input v-model="contactusForm.message" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="privacy">
              <el-checkbox v-model="checked">consent to the processing of my data in accordance with the data protecfion</el-checkbox>
            </div>
            <div class="submit" @click="submit">
              <span>Get The Latest Catalogs Now!</span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="faq">
      <el-collapse>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-collapse-item title="Comments and Suggestions" name="1">
              <div>
                <p>We strive for excellence and your input drives our progress. If you have a suggestion or need to raise a concern, we're here to listen and take action. Please share your thoughts, and we'll ensure they contribute to our continuous improvement.</p>
                <p>Submit your feedback <a href="/contact-us">[here].</a></p>
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="12">
            <el-collapse-item title="Questions on the use of the platform's functionality" name="2">
              <div>
                <p>If you're finding issues while using the platform, we're here to provide answers and help streamline your experience.</p>
                <p>To learn more about the platform's features, find answers to common platform usage questions <a href="/contact-us">[here].</a></p>
                <p>If that still doesn't solve your problem, you can contact us directly <a href="/contact-us">[here]!</a></p>
              </div>
            </el-collapse-item>
          </el-col>
        </el-row>
      </el-collapse>
    </div>
    <div class="google_map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.3677318773352!2d121.63664791164182!3d29.88250302612024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34529d782cda55af%3A0x6f7b99abc14fdb42!2sNingbo%20R%26D%20Yuan%20C%20Zone%2012%20Building%2C%20Yin%20Zhou%20Qu%2C%20Ning%20Bo%20Shi%2C%20Zhe%20Jiang%20Sheng%2C%20China%2C%20315101!5e0!3m2!1sen!2sus!4v1714112898850!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <el-dialog
        title="Message"
        :visible.sync="popVisible"
        width="30%">
      <span>Please check the privacy policy.</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="confirm" @click="popVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { post } from "@/utils/request";
import {Message} from "element-ui";

export default {
  name: 'ContactUsPage',
  data () {
    return {
      checked: false,
      popVisible: false,
      contactusForm: {},
      rules: {
        name: [
          { required: true, message: 'Please fill your name.', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Please fill your email.', trigger: 'blur' },
          { type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change'] },
        ],
        company: [
          { required: true, message: 'Please fill your company.', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please fill your phone.', trigger: 'blur' }
        ],
        message: [
          { required: true, message: 'Please fill your request.', max: 150, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit() {
      if (!this.checked) {
        this.popVisible = true;
      } else {
        post("/add_contact", this.contactusForm).then(res => {
          if (res.code === 1) {
            Message.success({
              message: "Submit success",
              offset: 500 
            });
            this.contactusForm = {};
          } else {
            Message.error({
              message: res.msg,
              offset: 500 
            });
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
