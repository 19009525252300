<template>
  <el-header v-if="!isHomePage">
    <div class="pc-home-top-banner">
      <div class="wrap">
        <p>
          <strong>10%</strong> off on first order for new users. <a href="/register">Register for a Free Account Now!</a>
        </p>
      </div>
    </div>
    <div class="header-and-searchbar">
      <div class="header-wrapper">
        <div
          class="the-new-header theme-bg-dark"
          :class="{ 'theme-bg-fixed': isScrolled }"
        >
          <div class="header-content">
            <div class="header-main">
              <div class="logo">
                <a href="/">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </a>
              </div>
              <div class="searchbar">
                <input
                  type="text"
                  v-model="searchTerm"
                  @keyup.enter="search"
                  @input="inputValue"
                  @blur="association = false"
                  @focus="association = associationResult.length > 0"
                  placeholder="Keywords or the image of the product you are looking for"
                />
                <div class="upload">
                  <el-upload
                      class="upload-demo"
                      action="#"
                      accept=".png,.jpg,.jpeg"
                      :auto-upload="false"
                      :on-change="(file) => { return imageSearch(file) } ">
                    <el-button size="small" type="primary" icon="el-icon-camera"></el-button>
                  </el-upload>
<!--                  <b-icon icon="camera" />-->
<!--                  <input-->
<!--                    ref="fileInput"-->
<!--                    type="file"-->
<!--                    name="file"-->
<!--                    accept=".jpg,.jpeg,.png"-->
<!--                    style="display: none"-->
<!--                    @change="handleImageUpload"-->
<!--                  />-->
                </div>
                <button class="search" @click="search">
                  <b-icon icon="search" />Search
                </button>
                <div v-if="association" class="association">
                  <div class="title" v-if="searchTerm.trim() == ''">Recommended for you</div>
                  <div class="list" v-for="item in associationResult" :key="item.query_text">
                    <a @mousedown="handleAssociationLinkClick($event, item)"><span>{{item.query_text}}</span></a>
                  </div>
                </div>
              </div>
              <div class="functional">
                <div class="sign-in">
                  <div v-if="!isLoggedIn">
                    <a @click="clickLogin">
                      <b-icon icon="person" />
                      <span>Sign in</span>
                    </a>
                  </div>
                  <div v-else>
                    <a>
                      <b-icon icon="person" />
                      <span>{{ username }}</span>
                    </a>
                  </div>
                  <div class="tooltip">
                    <h3>
                      Hello,<strong v-if="username">{{ username }}</strong
                      ><br />Welcome to chinabulkbuy.com!
                    </h3>
                    <button v-if="isLoggedIn" @click="logout">Log Out</button>
                    <a @click="clickLogin" v-else>Sign in</a>
                    <p>
                      By signing in via social media, I agree to the
                      chinabulkbuy.com Free Membership Agreement and
                      <a href="/privacy-policy">Privacy Policy,</a> and to
                      receive emails about the platform's products and services.
                    </p>
                    <ul>
                      <li @click="myAccount">My Account</li>
                      <li>
                        <a href="/request-for-quotation"
                          >Request for Quotation</a
                        >
                      </li>
                      <li @click="goWishList"><a>Wish List</a></li>
                    </ul>
                  </div>
                </div>
                <div class="cart">
                  <a href="/cart"><b-icon icon="cart" /></a>
                  <span>{{ amount }}</span>
                </div>
                <a href="/register" class="register">Sign up</a>
              </div>
            </div>
          </div>
          <div class="sub-header" v-if="!isScrolled">
            <div class="menu-toggle" @click="toggleSubMenu" :class="{ 'animate-toggle': subHeaderVisible }">
              <i></i>
              <i></i>
              <i></i>
            </div>
            <div class="sub-header-default" v-if="subHeaderVisible">
              <div class="sub-main">
                <div
                  @mouseenter="handleMouseEnter"
                  @mouseleave="handleMouseLeave"
                >
                  <div class="tab-title">
                    <b-icon icon="list-task" />
                    All categories
                  </div>
                  <div class="animated-tab-content">
                    <div class="tab-content">
                      <div class="animated-tab-content-children">
                        <div class="header-categories">
                          <!-- primary categories -->
                          <div class="panel-wrapper main-cate">
                            <div class="panel-content main-cate-content">
                              <a
                                :href="getDynamicUrl(category)"
                                v-for="(category, index) in categories"
                                :key="index"
                                :class="{
                                  current: activeFirstCategory === category,
                                }"
                                @mouseover="showSubCategories(category)"
                              >
                                {{ category.category_name_trans }}
                              </a>
                            </div>
                          </div>
                          <!-- secondary categories -->
                          <div
                            class="panel-wrapper secondary-cate"
                            v-if="currentSubCategories"
                          >
                            <div class="panel-content secondary-cate-content">
                              <a
                                :href="getDynamicUrl(subCategory)"
                                v-for="(
                                  subCategory, index
                                ) in currentSubCategories"
                                :key="index"
                                :class="{
                                  current: activeSecondCategory === subCategory,
                                }"
                                @mouseover="showFinalCategories(subCategory)"
                              >
                                {{ subCategory.category_name_trans }}
                              </a>
                            </div>
                          </div>
                          <!-- final categories -->
                          <div class="final-cate">
                            <a
                              v-if="activeSecondCategory"
                              :href="getDynamicUrl(activeSecondCategory)"
                              class="title"
                            >
                              {{ activeSecondCategory.category_name_trans
                              }}<b-icon icon="chevron-right" />
                            </a>
                            <ul>
                              <li
                                v-for="(
                                  finalCategory, index
                                ) in currentFinalCategories"
                                :key="index"
                              >
                                <a :href="getDynamicUrl(finalCategory)"
                                  ><span :title="finalCategory.category_name_trans">{{
                                    finalCategory.category_name_trans
                                  }}</span></a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="tab-title">
                    <a href="/product-category/home-garden">Home & Garden</a>
                  </div>
                </div>
                <div>
                  <div class="tab-title">
                    <a href="/product-category/gifts-promotion"
                      >Gifts & Promotion</a
                    >
                  </div>
                </div>
                <div>
                  <div class="tab-title">
                    <a href="/request-for-quotation">Request for Quotation</a>
                  </div>
                </div>
                <div>
                  <div class="tab-title">
                    <a href="/about-us">About us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </el-header>
</template>
<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
import {eventBus} from "@/common/eventBus";
import LoginDialog from "@/components/LoginDialog/index.vue";
export default {
  name: "GlobalHeader",
  components: {
    LoginDialog
  },
  data() {
    return {
      isHomePage: false,
      isScrolled: false,
      searchTerm: "",
      username: "",
      isLoggedIn: false,
      isLoading: false,
      isImageSearch: false,
      imageBase64: null,
      association: false,
      associationResult: [],
      categories: [],
      currentSubCategories: [],
      currentFinalCategories: [],
      activeFirstCategory: null,
      activeSecondCategory: null,
      amount: 0,
      subHeaderVisible: true,
      loginDialog: false
    };
  },
  async created() {
    this.checkLocalStorage();

    // 监听本地存储的变化
    window.addEventListener("storage", this.handleStorageChange);

    if (this.$route.path === "/") {
      this.isHomePage = true;
    } else {
      await this.getCategories();
      this.getCartAmount();
    }

  },
  watch: {
    // Watch for route changes and update isHomePage accordingly
    $route(to) {
      if (to.path === "/") {
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }
    },
  },
  mounted() {
    if (!this.isMobileDevice()) {
      window.addEventListener("scroll", this.handleScroll);
    }
    if (this.isMobileDevice()) {
      this.subHeaderVisible = false; 
    }
  },
  destroyed() {
    // 在组件销毁时移除监听
    window.removeEventListener("storage", this.handleStorageChange);
  },
  beforeDestroy() {
    if (!this.isMobileDevice()) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    checkLocalStorage() {
      // 检查本地存储中的登录信息
      this.isLoggedIn = isLogin();
      if (this.isLoggedIn) {
        let logInEmail = localStorage.getItem("loginUserEmail");
        this.username = localStorage.getItem(logInEmail + "_username");
      } else {
        this.username = "Visitor";
      }
      
    },
    handleStorageChange(event) {
      // 当本地存储发生变化时触发
      if (event.key === "token" || event.key === "userinfo") {
        this.checkLocalStorage();
      }
    },
    toggleSubMenu() {
      this.subHeaderVisible = !this.subHeaderVisible;
    },
    async logout() {
      const url = `/logout`; // 使用全局访问路径
      get(url)
        .then((res) => {
          if (res.code === 1) {
            // 清除本地存储中的用户信息
            //localStorage.clear();
            for (let key in localStorage) {
              if (key !== "historyViewed") {
                delete localStorage[key];
              }
            }
            this.isLoggedIn = false;
            this.username = "";
          } else {
            console.log(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    search() {
      if (this.searchTerm.trim() === "") {
        Message.error({
          message: "Please enter your keywords!",
          offset: 300,
        });
        return;
      }
      
      const currentRoute = this.$router.currentRoute;
      const newRoute = { name: "search", query: { SearchText: this.searchTerm } };
      if (currentRoute.name === newRoute.name && JSON.stringify(currentRoute.query) === JSON.stringify(newRoute.query)) {
        eventBus.$emit("keywordSearch", this.searchTerm);
      } else {
        this.$router.push(newRoute);
      }
    },

    imageSearch(file) {
      if (this.$router.currentRoute.name === "search") {
        eventBus.$emit("imageSearch", file.raw);
        console.log(file.raw)
      } else {
        this.$router.push({
          name: 'search',
          params: {
            file: file.raw
          }
        });
      }
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 106;
    },
    handleMouseEnter() {
      this.isHovering = true;
    },
    handleMouseLeave() {
      this.isHovering = false;
    },
    inputValue() {
      post("/search_lenovo", {
        keyword: this.searchTerm,
      })
        .then((res) => {
          if (res.code === 1) {
            this.associationResult = res.data;
            this.association = this.associationResult.length;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCategories() {
      try {
        const res = await get("/home_category");
        if (res.code === 1) {
          this.categories = res.data;
          this.showSubCategories(this.categories[0]);
        } else {
          Message.error({
            message: res.msg,
            offset: 500,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    showSubCategories(category) {
      this.currentFinalCategories = [];
      this.currentSubCategories = category.children || [];
      this.activeFirstCategory = category;

      if (category.children && Array.isArray(category.children)) {
        let num = 1;
        category.children.forEach((subCategory) => {
          if (num === 1) {
            this.showFinalCategories(subCategory);
            num++;
          }
        });
      }
    },
    showFinalCategories(subCategory) {
      this.currentFinalCategories = subCategory.children || [];
      if (this.currentFinalCategories.length === 0) {
        this.activeSecondCategory = null;
      } else {
        this.activeSecondCategory = subCategory;
      }
    },
    getDynamicUrl(itemOrCategory) {
      if (itemOrCategory.url_path) {
        // 对于产品分类
        return `/product-category/${itemOrCategory.url_path}`;
      }
    },
    clickLogin() {
      const currentUrl = window.location.pathname + window.location.search;
      localStorage.setItem("linkDump", currentUrl);
      window.location.href = "/login";
    },
    myAccount() {
      if (isLogin()) {
        this.$router.push("/account");
      } else {
        this.loginDialog = true;
      }
    },
    getCartAmount() {
      if (isLogin()) {
        get("/cart").then((res) => {
          if (res.code === 1) {
            if (res.data.items) {
              this.amount = res.data.items.length;
            }
          }
        });
      } else {
        if (localStorage.getItem("cartList")) {
          let cartList = JSON.parse(localStorage.getItem("cartList"));
          this.amount = cartList.length;
        }
      }
    },
    goWishList() {
      if (isLogin()) {
        if (this.$router.currentRoute.name === 'account') {
          eventBus.$emit("toPanel", "2");
        } else {
          this.$router.push({
            name: 'account',
            params: {
              index: "2"
            }
          })
        }
      } else {
        this.loginDialog = true;
      }
    },
    handleAssociationLinkClick(event, item) {
      event.stopPropagation();
      this.searchTerm = item.query_text;
      this.search();
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
