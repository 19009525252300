<template>
  <div>
    <div class="search">
      <div class="topbar d-flex justify-content-between align-items-center">
        <p v-if="!isImageSearch">Keyword: {{ keyword }}</p>
        <img v-if="isImageSearch" :src="imageUrl" alt="" />
        <!-- <div class="right d-flex align-items-center">
          <span>
            Total <b>{{ data.totalRecords }}</b> Products
          </span>
        </div> -->
      </div>
      <div class="loading" v-if="isLoading">
        <img src="@/assets/images/loading.gif" />
      </div>
      <div class="content" v-else-if="searchResults.length > 0">
        <ul>
          <li v-for="(list, index) in searchResults" :key="index">
            <div class="image">
              <a :href="getDynamicUrl(list)">
                <img :src="list.imageUrl" :alt="list.imageUrl" />
              </a>
              <div class="collect" @click="addCollect(list)">
                <b-icon
                  :icon="isCollected(list.offerId) ? 'heart-fill' : 'heart'"
                />
              </div>
            </div>
            <a
              :href="getDynamicUrl(list)"
              class="title"
              :title="list.subjectTrans"
              >{{ list.subjectTrans }}</a
            >
            <span class="price">${{ list.transPriceInfo.price }}</span>
            <div class="order">
              <span>Door to Door Delivery</span>
            </div>
            <div class="sample">
              <img src="@/assets/images/free.png" />
              <span>Sample</span>
            </div>
            <div class="deal">
              <div class="scrolling-text">
                <div>First Deal <strong>10% OFF</strong></div>
                <div>First Deal2 <strong>10% OFF</strong></div>
                <div>First Deal3 <strong>10% OFF</strong></div>
                <div>First Deal4 <strong>10% OFF</strong></div>
              </div>
            </div>
            <button @click="openDrawer(list)" class="addtocart">Add to cart</button>
          </li>
        </ul>
        <div class="pagination">
          <el-pagination
              layout="prev, pager, next"
              :page-count="pagination.total_page"
              :current-page.sync="pagination.current_page"
              @current-change="handlePageChange"
              @prev-click="handlePrevClick"
              @next-click="handleNextClick">
          </el-pagination>
        </div>
      </div>
      <div v-if="!isResult" class="no-result">
        <img src="@/assets/images/not-found.png" alt="" />
        <h1>Can't find related products, try another keyword search</h1>
      </div>
    </div>

    <my-drawer :isShow.sync="drawer" :item="item"></my-drawer>

    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </div>
</template>
<script>
import { get, post, postForm } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
import cartDrawer from "@/components/CartDrawer/index.vue";
import { eventBus } from "@/common/eventBus";
import LoginDialog from "@/components/LoginDialog/index.vue";
export default {
  name: "SearchPage",
  components: {
    LoginDialog,
    myDrawer: cartDrawer,
  },
  data() {
    return {
      data: {},
      // searchFile: {},
      searchResults: [],
      imageBase64: "",
      imageUrl: "",
      isImageSearch: false,
      keyword: "",
      isLoading: false, 
      isResult: true, 
      maxDisplayedPages: 7, 
      item: {},
      drawer: false,
      collectedProducts: {},
      paginationRequest: {
        beginPage: 1,
        pageSize: 25
      },
      pagination: {
        total_page: 10,
        current_page: 1
      },
      file: {},
      loginDialog: false
    };
  },
  async created() {
    if (this.$route.params.file) {
      this.file = this.$route.params.file;
      this.isImageSearch = true;
      this.imageSearch();
    }

    if (this.$route.query.SearchText) {
      this.keyword = this.$route.query.SearchText;
      this.isImageSearch = false;
      this.keywordSearch();
    }

    this.$watch(
      () => this.$route.query.SearchText,
      () => {
        // 当路由参数发生变化时，重新执行搜索操作
        this.keyword = this.$route.query.SearchText;
        this.isImageSearch = false;
        this.keywordSearch();
      }
    );

    await this.loadCollectedList();
  },
  mounted() {
    eventBus.$on("imageSearch", msg => {
      this.file = msg;
      this.isImageSearch = true;
      this.imageSearch();
    })

    eventBus.$on("keywordSearch", msg => {
      this.keyword = msg;
      this.isImageSearch = false;
      this.keywordSearch();
    })
  },
  methods: {
    imageSearch() {
      this.searchResults = [];
      this.isLoading = true;

      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      }
      reader.readAsDataURL(this.file);

      const form = new FormData();
      form.append("ip", "127.0.0.1");
      form.append("beginPage", this.paginationRequest.beginPage);
      form.append("pageSize", this.paginationRequest.pageSize);
      form.append("file", this.file);

      postForm("/upload_image_search", form).then(res => {
        if (res.code === 1) {
          this.searchResults = res.data.data;
          this.data.totalRecords = res.data.totalRecords;
          this.pagination = {
            total_page: res.data.totalPage,
            current_page: res.data.currentPage
          }
          if (this.searchResults.length === 0) {
            this.isResult = false;
          }
          this.isLoading = false;
        }
      })
    },
    keywordSearch() {
      this.searchResults = [];
      this.isLoading = true;
      const keyword = this.keyword;
      const url = `/search_product?${keyword ? "SearchText=" + keyword : ""}`;
      post(url, {
        ip: "127.0.0.1",
        keyword: keyword,
        beginPage: this.paginationRequest.beginPage,
        pageSize: this.paginationRequest.pageSize,
        filter: null,
        priceStart: null,
        priceEnd: null,
      }).then(res => {
        this.searchResults = res.data.data;
        this.data.totalRecords = res.data.totalRecords;
        this.pagination = {
          total_page: res.data.totalPage,
          current_page: res.data.currentPage
        }
        if (this.searchResults.length === 0) {
          this.isResult = false;
        }
        this.isLoading = false;
      })
    },
    handlePageChange() {
      this.paginationRequest.beginPage = this.pagination.current_page;
      if (this.isImageSearch) {
        this.imageSearch();
      } else {
        this.keywordSearch();
      }
    },
    handlePrevClick() {
      this.paginationRequest.beginPage = --this.pagination.current_page;
      if (this.isImageSearch) {
        this.imageSearch();
      } else {
        this.keywordSearch();
      }
    },
    handleNextClick() {
      this.paginationRequest.beginPage = ++this.pagination.current_page;
      if (this.isImageSearch) {
        this.imageSearch();
      } else {
        this.keywordSearch();
      }
    },
    async loadCollectedList() {
      try {
        const res = await get("/my_collect_list");
        if (res.code === 1) {
          // 如果返回的数据包含已收藏的 offerId，则将其添加到 collectedProducts 中
          res.data.forEach((offerId) => {
            this.$set(this.collectedProducts, offerId, true);
          });
        } 
      } catch (err) {
        console.log(err);
      }
    },
    addCollect(list) {
      if (isLogin()) {
        let productImage = "";
        if (list.imageUrl.includes("http")) {
          productImage = list.imageUrl;
        }
        if (this.isCollected(list.offerId)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + list.offerId + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的offerId
                this.$set(this.collectedProducts, list.offerId, false);
              } else {
                Message.error({
                  message: res.msg,
                  offset: 300
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          post("/add_collect", {
            category: 'keyword_'+this.keyword,
            source_id: list.offerId,
            product_image: productImage,
            product_name: list.subjectTrans,
            product_price: list.transPriceInfo.price,
          }).then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的offerId
                this.$set(this.collectedProducts, list.offerId, true);
              } else {
                Message.error({
                  message: res.msg,
                  offset: 300
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    isCollected(offerId) {
      // 判断产品是否已被收藏
      return (
        Object.prototype.hasOwnProperty.call(
          this.collectedProducts,
          offerId
        ) && this.collectedProducts[offerId]
      );
    },
    getDynamicUrl(list) {
      return `product-details/?product_id=${list.offerId}`;
    },
    openDrawer(list) {
      this.item = {
        ip: "127.0.0.1",
        product_id: list.offerId.toString(),
        product_name: list.subject,
        product_name_trans: list.subjectTrans,
        product_image: list.imageUrl,
        sku_image: list.imageUrl,
        qty: 1,
        base_row_single: list.priceInfo.price,
        quote_row_single: list.transPriceInfo.price,
        category_id: list.source_category_id
      };
      this.drawer = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
