<template>
  <div>
    <el-card shadow="never">
      <h3>RFQ History({{ amount }})</h3>
    </el-card>
    <el-card class="RFQ-content" shadow="never">
      <div class="RFQ-table">
        <el-table
            :data="records"
            empty-text="Empty"
            v-loading="loading">
<!--          <el-table-column type="expand">-->
<!--            <template #default="props">-->
<!--              <el-form label-position="left" inline class="demo-table-expand">-->
<!--                <el-form-item label="Name">-->
<!--                  <span>{{ props.row.name }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="Email">-->
<!--                  <span>{{ props.row.email }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="Company">-->
<!--                  <span>{{ props.row.company }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="Phone">-->
<!--                  <span>{{ props.row.phone }}</span>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="File">-->
<!--                  <a :href="props.row.upload_file" target="_blank" >File</a>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="Message">-->
<!--                  <span>{{ props.row.message }}</span>-->
<!--                </el-form-item>-->
<!--              </el-form>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="Sort" align="center" type="index">
            <template #default="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Inquiry date" prop="inquiry_date" align="center"/>
          <el-table-column label="Name" prop="name" align="center"/>
          <el-table-column label="Email" prop="email" align="center"/>
          <el-table-column label="Company" prop="company" align="center"/>
          <el-table-column label="Phone" prop="phone" align="center"/>
<!--          <el-table-column label="File" prop="upload_file" align="center" width="80">-->
<!--            <template #default="scope">-->
<!--              <div v-if="scope.row.upload_file !== ''">-->
<!--                <a :href="scope.row.upload_file" target="_blank" >File</a>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="Detail" align="center" type="expand" width="120">
            <template #default="props">
              <el-form label-position="top" class="demo-table-expand">
                <el-form-item label="Message">
                  <span>{{ props.row.message }}</span>
                </el-form-item>
                <div v-if="props.row.upload_file !== ''">
                  <el-form-item label="File">
<!--                    <a :href="props.row.upload_file" target="_blank" >File</a>-->
                    <div v-for="(item, index) in props.row.upload_file" :key="index">
                      <a :href="item" target="_blank">{{ props.row.origin_upload_file_name[index] }}</a>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { post } from "@/utils/request";

export default {
  name: 'RFQHistoryPanel',
  data () {
    return {
      amount: 0,
      records: [],
      loading: false,
      form: {}
    }
  },
  created() {
    this.getRecords();
  },
  methods: {
    async getRecords() {
      this.records = [];
      this.loading = true;
      post("/inquiry_list").then(res => {
        if (res.code === 1) {
          this.records = res.data;
          if (this.records) {
            this.amount = this.records.length;
          }
          this.loading = false;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
