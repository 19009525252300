<template>
  <div class="login">
    <div class="login-image">
      <img src="@/assets/images/login.png" alt="" />
      <div class="login-text">
        <h3>Access exclusive features through a FREE account</h3>
        <ul>
          <li><b-icon icon="check" />Use the 'Wish List' feature to save.</li>
          <li><b-icon icon="check" />Send inquiries anytime, anywhere.</li>
          <li>
            <b-icon icon="check" />Easily track your orders and browsing
            history.
          </li>
          <li>
            <b-icon icon="check" />Quickly track the progress of logistics.
          </li>
          <li>
            <b-icon icon="check" />Learn about the latest promotional policies.
          </li>
        </ul>
      </div>
    </div>
    <div class="login-form">
      <div class="title">
        <img src="@/assets/images/logo.png" alt="" />
        <h1 class="signup-text">Sign In</h1>
      </div>
      <el-form :model="loginForm" status-icon :rules="rules">
        <el-form-item label="Email address" prop="email">
          <el-input type="email" v-model="loginForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input
            :type="passwordFieldType"
            v-model="loginForm.password"
          ></el-input>
          <b-icon
            class="password-eye"
            icon="eye-slash-fill"
            @click="togglePasswordVisibility('text')"
            v-if="!showPassword"
          />
          <b-icon
            class="password-eye"
            icon="eye-fill"
            @click="togglePasswordVisibility('password')"
            v-if="showPassword"
          />
        </el-form-item>
        <div class="remember">
          <el-checkbox v-model="checked">Remember Me</el-checkbox>
        </div>
        <div class="submit" @click="submit">
          <span>Login Now</span>
        </div>
        <div class="forgot">
          <a href="/forgot-password">Forgot your password?</a>
        </div>
      </el-form>
      <p>New User? <a href="/register">Join Free</a></p>
    </div>
  </div>
</template>

<script>
// import { API_BASE_URL } from '@/api/apiConfig';
import { post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
export default {
  name: "LoginPage",
  data() {
    return {
      checked: false,
      loginForm: {},
      rules: {
        email: [
          {
            required: true,
            message: "Please fill your email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address.",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill your password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;
              if (!regex.test(value)) {
                callback(
                  new Error(
                    "Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."
                  )
                );
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      showPassword: false,
      username: ""
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    togglePasswordVisibility(type) {
      this.showPassword = type === "text";
    },
    submit() {
      post("/login", this.loginForm)
        .then((res) => {
          if (res.code === 1) {
            Message.success({
              message: "Login success!",
              offset: 300,
            });
            // 将 token 存储在本地
            const token = res.data.token;
            const email = res.data.userinfo.email;
            localStorage.setItem("loginUserEmail", res.data.userinfo.email); //email
            localStorage.setItem(email + "_token", token); //token
            const username = res.data.userinfo.username; // 提取用户名
            localStorage.setItem(email + "_username", username); //username
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo)); //userinfo

            if (localStorage.getItem("cartList")) {
              let cartList = JSON.parse(localStorage.getItem("cartList"));
              localStorage.setItem("cartList", "");

              if (cartList !== undefined) {
                const promises = [];
                cartList.forEach(item => {
                  const p = new Promise(function (resolve) {
                    setTimeout(() => {
                      if (item.spec_id) {
                        post("/add_to_cart", item).then(res => {
                          resolve(res);
                        })
                      } else {
                        post("/add_final_to_cart", item).then(res => {
                          resolve(res);
                        })
                      }
                    }, 300);
                  })
                  promises.push(p);
                })
                Promise.all(promises).then(function () {
                  if (isLogin()) {
                    if (localStorage.getItem("linkDump")) {
                      window.location.href = localStorage.getItem("linkDump");
                      localStorage.removeItem("linkDump");
                    } else {
                      window.location.href = "/";
                    }
                  } else {
                    window.location.href = "/register";
                  }
                })
              } else {
                this.jump();
              }
            } else {
              this.jump();
            }
          } else {
            Message.error({
              message: "Submit Error: " + res.msg,
              offset: 300,
            });
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },

    async jump() {
      if (isLogin()) {
        if (localStorage.getItem("linkDump")) {
          window.location.href = localStorage.getItem("linkDump");
          localStorage.removeItem("linkDump");
        } else {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/register";
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
