<template>
  <div class="login">
    <div class="login-image">
      <img src="@/assets/images/login.png" alt=""/>
      <div class="login-text">
        <h3>
          Building An Integratedservice Platfom Of Foreigntrade Attentively.
        </h3>
        <ul>
          <li><b-icon icon="check" />Faster response to your inquiries</li>
          <li><b-icon icon="check" />Multiple quotes per each request</li>
          <li><b-icon icon="check" />Sourcing assistant to help you source easier</li>
        </ul>
      </div>
    </div>
    <div class="login-form">
      <div class="title">
        <img src="@/assets/images/logo.png" alt=""/>
        <h1 class="signup-text">Reset Your Password</h1>
      </div>
      <el-form :model="resetpassForm" status-icon :rules="rules" ref="resetpassForm">
        <el-form-item label="Your Email Address" prop="email">
          <el-input type="email" v-model="resetpassForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input
          :type="passwordFieldType"
            v-model="resetpassForm.password"
          ></el-input>
          <b-icon class="password-eye" icon="eye-slash-fill" @click="togglePasswordVisibility('text')" v-if="!showPassword" />
          <b-icon class="password-eye" icon="eye-fill" @click="togglePasswordVisibility('password')" v-if="showPassword" />
        </el-form-item>
        <el-form-item label="Confirm Password" prop="password_confirmation">
          <el-input :type="passwordconfirmationFieldType" v-model="resetpassForm.password_confirmation"></el-input>
          <b-icon class="password-eye" icon="eye-slash-fill" @click="togglePasswordConfirmationVisibility('text')" v-if="!showPasswordconfirmation" />
          <b-icon class="password-eye" icon="eye-fill" @click="togglePasswordConfirmationVisibility('password')" v-if="showPasswordconfirmation" />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="Enter Verification Code"
              prop="verify_code"
            >
              <el-input v-model="resetpassForm.verify_code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="resetpass-code">
              <span v-show="!showSuccess && !showError" @click.prevent="getVerificationCode" class="code">Get Verification Code</span>
              <div class="success" v-show="showSuccess">
                <b-icon icon="check-circle" />Code sent
                {{ timerSeconds }}s
              </div>
              <div class="error" v-show="showError">
                Didn't receive it? <span @click.prevent="getVerificationCode" class="code">Get another code</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="submit" class="continue">Continue</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import { API_BASE_URL } from '@/api/apiConfig';
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
export default {
  name: "ResetPasswordPage",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter password.'));
      } else {
        if (this.resetpassForm.password_confirmation !== '') {
          this.$refs.resetpassForm.validateField('password_confirmation');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password again.'));
      } else if (value !== this.resetpassForm.password) {
        callback(new Error('The password confirmation does not match.'));
      } else {
        callback();
      }
    };
    return {
      resetpassForm: {
        email:'',
        password:'',
        password_confirmation:'',
        verify_code:'',
      },
      rules: {
        email: [
          { required: true, message: 'Please fill your email.', trigger: 'blur' },
          { type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, validator: validatePass, message: 'Please fill your password.', trigger: 'blur' },
          { 
            validator: (rule, value, callback) => {
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;
              if (!regex.test(value)) {
                callback(new Error("Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."));
              } else {
                callback();
              }
            }, 
            trigger: ["blur", "change"], }
        ],
        password_confirmation: [
          { required: true, validator: validatePass2, trigger: ["blur"], }
        ],
        verify_code: [
          { required: true, message: 'Please enter verification code.', trigger: ["blur"], }
        ],
      },
      showSuccess: false,
      showError: false,
      showPassword: false,
      showPasswordconfirmation: false,
      timerSeconds: 0,
      timerInterval: null
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordconfirmationFieldType() {
      return this.showPasswordconfirmation ? 'text' : 'password';
    }
  },
  methods: {
    togglePasswordVisibility(type) {
      this.showPassword = type === 'text';
    },
    togglePasswordConfirmationVisibility(type) {
      this.showPasswordconfirmation = type === 'text';
    },
    getVerificationCode() {
      const url = `/verifyCode/?email=${this.resetpassForm.email}&type=1`;
      get(url).then(res => {
        if (res.code === 1) {
          this.showSuccess = true; 
          this.showError = false; 
          this.startTimer();
        } else {
          Message.error({
            message: "Sending code error: " + res.msg,
            offset: 500 
          });
        }
      }).catch(err => {
        console.error("Error:", err);
      });
    },
    startTimer() {
      this.timerSeconds = 60; // 设置倒计时初始时间
      this.timerInterval = setInterval(() => {
        if (this.timerSeconds > 0) {
          this.timerSeconds--; // 每秒减少一秒
        } else {
          clearInterval(this.timerInterval); // 倒计时结束，清除计时器
          this.showSuccess = false;
          this.showError = true; // 显示错误提示
        }
      }, 1000); // 每秒执行一次
    },
    submit() {
      this.$refs.resetpassForm.validate(valid => {
        if (valid) {
          post("/changePassword", this.resetpassForm).then(res => {
            if (res.code === 1) {
              Message.success({
                message: "Your password has been changed successfully.",
                offset: 500 
              });
              this.resetpassForm = {};
              this.showSuccess = false; 
              this.showError = false; 
              this.$router.push("/login");
            } else {
              Message.error({
                message: "Submit Error: " + res.msg,
                offset: 500 
              });
            }
          }).catch(err => {
            console.error("Error:", err);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
