<template>
  <div class="product-wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">HOME</el-breadcrumb-item>
      <el-breadcrumb-item>{{ subjectTrans }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="product-cont">
      <div class="product-image">
        <figure>
<!--          <vue-photo-zoom-pro-->
<!--            :url="slides[current]"-->
<!--            :high-url="slides[current]"-->
<!--          />-->
          <vue-photo-zoom-pro
              :url="showImage"
              :high-url="showImage"
          />
          <div class="collect" @click="productImageAddCollect">
            <b-icon
              :icon="isproductImageCollected() ? 'heart-fill' : 'heart'"
            />
          </div>
        </figure>
        <swiper id="previewSwiper" ref="previewSwiper" :options="swiperOption">
          <!-- <swiper-slide v-if="mainVideo">
            <video :src="mainVideo" poster="@/assets/images/大图.png" controls autoplay muted loop width="60" height="60"></video>
          </swiper-slide> -->
          <swiper-slide
            v-for="(slide, index) in slides"
            :key="index"
            :class="{ swiper_slide_item: true, current: current === index }"
          >
            <img :src="slide" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="product-info">
        <div>
          <h1 class="title">{{ subjectTrans }}</h1>
          <ul>
            <li v-for="(price, index) in transPriceRangeList" :key="index">
              <span class="sale_price">${{ price.price }}</span>
              <span class="start_quantity"
                >From {{ price.startQuantity }} pieces</span
              >
            </li>
          </ul>
        </div>
        <div>
          <div class="attr" v-if="attrCategoryType.length > 0">
            <div v-if="attrCategoryType.length > 1">
              <div
                class="attr-content"
                v-for="(categoryItem, index) in attrCategory"
                :key="index"
              >
                <span class="title">{{ index }}:</span>
                <swiper ref="swiperColor" :options="swiperOptionColor">
                  <swiper-slide
                    v-for="(attributeItem, attrIndex) in categoryItem"
                    :key="attrIndex"
                  >
                    <div
                      class="attr-item"
                      :class="{
                        selected:
                          selectedColor === attributeItem ||
                          (attrIndex === 0 && !selectedColor),
                      }"
                      @click="selectAttributeItem(attributeItem)"
                    >
                      <img
                        v-if="attributeItem.skuImageUrl"
                        :src="attributeItem.skuImageUrl"
                        :alt="attributeItem.valueTrans"
                      />
                      <h3 :title="attributeItem.valueTrans">
                        {{ attributeItem.valueTrans }}
                      </h3>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
            <table>
              <tr>
                <span>{{ keysValue }}</span>
              </tr>
              <tr>
                <th>Products</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
              <tr
                v-for="(
                  childAttribute, childAttributeIndex
                ) in displayedChildrenAttributeItem"
                :key="childAttributeIndex"
              >
                <td>
                  <img
                    v-if="childAttribute.skuImageUrl"
                    :src="childAttribute.skuImageUrl"
                    :alt="childAttribute.valueTrans"
                  />
                  <span>{{ childAttribute.valueTrans }}</span>
                </td>
                <td>
                  {{ childAttribute.transPrice }}
                </td>
                <td>
                  <el-input-number v-model="childAttribute.count" @change="handleChange(childAttribute)" :min="0"></el-input-number>
<!--                  <button-->
<!--                    @click="minus(childAttribute)"-->
<!--                    :disabled="childAttribute.count === 0"-->
<!--                    :class="{ 'is-disabled': childAttribute.count === 0 }"-->
<!--                  >-->
<!--                    - -->
<!--                  </button>-->
<!--                  <input-->
<!--                    type="number"-->
<!--                    v-model="childAttribute.count"-->
<!--                    min="0"-->
<!--                    @focus="handleFocus(childAttribute)"-->
<!--                    @blur="handleBlur($event, childAttribute)"-->
<!--                  />-->
<!--                  <button @click="add(childAttribute)">+</button>-->
                </td>
              </tr>
            </table>
            <div
              v-if="allRowsDisplayed.length > 0"
              @click="expandRows"
              class="more"
            >
              <b-icon icon="chevron-compact-down" />
            </div>
            <div v-if="!isCollapsed" @click="collapseRows" class="more">
              <b-icon icon="chevron-compact-up" />
            </div>
          </div>
        </div>
        <div>
          <div class="total">
            <p>
              Quantity: <span>{{ totalQuantity }}</span>
            </p>
            <p>
              Total: <span>${{ parseFloat(totalPrice).toFixed(2) }}</span>
            </p>
          </div>
          <div>
            <button
              class="add-to-cart"
              @click="addToCartAction()"
            >
              <b-icon icon="cart" />Add To Cart
            </button>
            <a @click="needSample" class="sample">Send a sample request</a>
          </div>
        </div>
      </div>
    </div>
    <div class="product-desc">
      <div class="product_desc">
        <h3>Product attribute</h3>
        <div class="product_attribute">
          <div
            v-for="(value, key) in productAttribute"
            :key="key"
            class="attribute"
          >
            <span class="attribute-key" :title="key">{{ key }}:</span>
            <span class="attribute-value" :title="value">{{ value }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="product_desc">
        <h3>Product description</h3>
        <div class="product_detail" v-html="productDescription"></div>
      </div> -->
    </div>
    <div class="solution">
      <div class="wrap">
        <h3 class="title">We Offer Solutions for All Types of Buyers</h3>
        <el-row type="flex" :gutter="36">
          <el-col :span="8">
            <div class="item">
              <img src="@/assets/images/solution_01.png" />
              <div class="txt">
                <h3>Haven't Found Your Ideal Product?</h3>
                <p>
                  Whatever solution you're seeking, just one step to initiate an
                  inquiry, and we'll tailor a solution just for you.
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="@/assets/images/solution_02.png" />
              <div class="txt">
                <h3>Have a Product List?</h3>
                <p>
                  Share your requirements list with us, and we guarantee to
                  provide matching high-quality products.
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="@/assets/images/solution_03.png" />
              <div class="txt">
                <h3>Got a Product Design?</h3>
                <p>
                  Your creativity deserves to be realized. Send us your design,
                  and let us help you turn your dream into reality.
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"
            ><a href="/request-for-quotation"
              >Take Action Nows<strong>→</strong></a
            ></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="product-related">
      <h3>Related products</h3>
      <swiper :options="relatedProductOption" ref="mySwiper">
        <swiper-slide v-for="(product, index) in relatedProduct" :key="index">
          <div class="image">
            <a :href="getDynamicUrl(product)">
              <img :src="product.imageUrl" />
            </a>
            <div class="collect" @click="addCollect(product)">
              <b-icon
                :icon="isCollected(product.offerId) ? 'heart-fill' : 'heart'"
              />
            </div>
          </div>
          <a
            :href="getDynamicUrl(product)"
            class="title"
            :title="product.subjectTrans"
            >{{ product.subjectTrans }}</a
          >
          <span class="price">${{ product.transPriceInfo.price }}</span>
          <div class="order">
            <span>Door to Door Delivery</span>
          </div>
          <div class="sample">
            <img src="@/assets/images/free.png" />
            <span>Sample</span>
          </div>
          <div class="deal">
            <div class="scrolling-text">
              <div>First Deal <strong>10% OFF</strong></div>
              <div>First Deal2 <strong>10% OFF</strong></div>
              <div>First Deal3 <strong>10% OFF</strong></div>
              <div>First Deal4 <strong>10% OFF</strong></div>
            </div>
          </div>
          <button @click="openDrawer(product)" class="addtocart">
            Add to cart
          </button>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev prev-related" slot="button-prev"></div>
      <div class="swiper-button-next next-related" slot="button-next"></div>
    </div>
    <div v-if="historyViewed.length > 0" class="product-related">
      <h3>You also viewed</h3>
      <swiper :options="viewedProductOption" ref="mySwiper">
        <swiper-slide v-for="(product, index) in historyViewed" :key="index">
          <div class="image">
            <a :href="'/product-details/?product_id=' + product.offerId">
              <img :src="product.image" />
            </a>
            <div class="collect" @click="historyViewedAddCollect(product)">
              <b-icon
                :icon="isCollected(product.offerId) ? 'heart-fill' : 'heart'"
              />
            </div>
          </div>
          <a
            :href="'/product-details/?product_id=' + product.offerId"
            class="title"
            :title="product.subjectTrans"
            >{{ product.subjectTrans }}</a
          >
          <span class="price">${{ product.price }}</span>
          <div class="order">
            <span>Door to Door Delivery</span>
          </div>
          <div class="sample">
            <img src="@/assets/images/free.png" />
            <span>Sample</span>
          </div>
          <div class="deal">
            <div class="scrolling-text">
              <div>First Deal <strong>10% OFF</strong></div>
              <div>First Deal2 <strong>10% OFF</strong></div>
              <div>First Deal3 <strong>10% OFF</strong></div>
              <div>First Deal4 <strong>10% OFF</strong></div>
            </div>
          </div>
          <button @click="openDrawer(product)" class="addtocart">
            Add to cart
          </button>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev prev-viewed" slot="button-prev"></div>
      <div class="swiper-button-next next-viewed" slot="button-next"></div>
    </div>
    <div class="faq">
      <h3>faq</h3>
      <el-collapse>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-collapse-item title="Can I order samples?" name="1">
              <div>
                Absolutely! We offer sample requests to help you make informed
                purchasing decisions. However, please note that while the
                samples are free, you'll need to cover the shipping costs.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="12">
            <el-collapse-item
              title="Do you offer customization options?"
              name="2"
            >
              <div>
                Yes, we provide customization services tailored to your specific
                needs. From product specifications to branding, we're here to
                ensure your requirements are met.
              </div>
            </el-collapse-item>
          </el-col>
        </el-row>
      </el-collapse>
      <el-collapse>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-collapse-item
              title="How do you ensure product quality?"
              name="3"
            >
              <div>
                We have a stringent quality control process in place. Our
                dedicated team conducts thorough inspections to ensure that all
                products meet our high standards before they are shipped out.
              </div>
            </el-collapse-item>
          </el-col>
          <el-col :span="12">
            <el-collapse-item title="What is your shipping policy?" name="4">
              <div>
                We strive to process and dispatch orders promptly. For standard
                products, expect shipment within 7-15 working days upon payment
                confirmation. Please note that shipping times may vary based on
                factors beyond our control.
              </div>
            </el-collapse-item>
          </el-col>
        </el-row>
      </el-collapse>
    </div>
    <div class="contact">
      <h3>
        Get a Free Account and Send Your Inquery Now!
      </h3>
      <p>
        Now, over 500 wholesalers, retailers, online sellers are purchasing hot-selling products on chinabulkbuy.com, <br />
        and reducing their procurement costs by 10-50% compared to before, and greatly increasing their sales volume. <br />
        Want to explore the reasons behind this and join them?
        <a href="/request-for-quotation">Take 1 minute to Get a Free Account Now!</a>
      </p>
      <ul>
        <li><b-icon icon="check" />0 commission permanently</li>
        <li><b-icon icon="check" />10% off for first order</li>
        <li><b-icon icon="check" />Free samples available</li>
        <li><b-icon icon="check" />7*24 service accessible</li>
      </ul>
    </div>

    <my-drawer :isShow.sync="drawer" :item="item"></my-drawer>

    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </div>
</template>

<script>
import vuePhotoZoomPro from "vue-photo-zoom-pro";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
import cartDrawer from "@/components/CartDrawer/index.vue";
import LoginDialog from "@/components/LoginDialog/index.vue";

export default {
  name: "ProductDetailsPage",
  components: {
    LoginDialog,
    vuePhotoZoomPro,
    myDrawer: cartDrawer,
  },
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        { charset: "utf-8" },
        { name: "keywords", content: this.metaData.keywords },
        { name: "description", content: this.metaData.description },
      ],
    };
  },
  data() {
    return {
      metaData: {
        title: "",
        keywords: "",
        description: "",
      },
      showImage: "",
      product_id: null, // product_id 从 URL 参数中获取
      current: 0,
      slides: [],
      swiperOption: {
        slidesPerView: 7,
        on: {
          click: this.handleClick(),
        },
      },
      //mainVideo: false,
      subjectTrans: "",
      transPriceRangeList: [],
      swiperOptionColor: {
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
        breakpoints: {
          375: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 12,
          },
        },
      },
      attrCategoryType: [],
      attrCategory: {},
      childrenAttributeItem: {},
      selectedColor: null,
      keysValue: "",
      productAttribute: [],
      productDescription: "",
      skuImageUrl: "",
      attributeSelect: {},
      skuInfos: {},
      // addTocartNumber: 0,
      totalPrice: 0,
      // currentInputNum: { oldValue: 0, newValue: 0 },
      addToCart: {},
      skuAttrTrans: [],
      baseGrandTotal: 0,
      minOrderQuantity: 0,
      isDisabled: true,
      exchange: 0,
      seller_open_id: "",
      shipping_info: "",
      relatedProduct: [],
      relatedProductOption: {
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next.next-related",
          prevEl: ".swiper-button-prev.prev-related",
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            slidesPerGroup: 2,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 35,
            slidesPerGroup: 5,
          },
        },
      },
      viewedProductOption: {
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next.next-viewed",
          prevEl: ".swiper-button-prev.prev-viewed",
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            slidesPerGroup: 2,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 35,
            slidesPerGroup: 5,
          },
        },
      },
      item: {},
      drawer: false,
      category_id: null,
      collectedProducts: {},
      historyViewed: [],
      visibleRowCount: 5,
      isCollapsed: true,
      localCart: [],
      totalQuantity: 0,
      loginDialog: false
    };
  },
  async created() {

    const params = new URLSearchParams(window.location.search);
    this.product_id = params.get("product_id"); // 假设 product_id 是 URL 中的查询参数

    if (this.product_id) {
      // 如果成功获取到 product_id，就调用请求函数
      await this.getProductDetail();
    } else {
      console.error("Product ID not found in URL parameters");
    }
    await this.loadCollectedList();
    this.retrieveHistoryViewed();
  },
  computed: {
    displayedChildrenAttributeItem() {
      return this.childrenAttributeItem.slice(0, this.visibleRowCount);
    },
    allRowsDisplayed() {
      return this.childrenAttributeItem.slice(this.visibleRowCount);
    },
  },
  methods: {
    handleClick() {
      let _this = this;
      return function () {
        _this.current = this.clickedIndex;
        _this.showImage = _this.slides[_this.current];
        // this.slideTo(_this.current, 1000, false); //切换到第一个slide，速度为1秒
      };
    },
    async getProductDetail() {
      const url = `/product_detail/?product_id=${this.product_id}`;
      try {
        const res = await post(url, {
          ip: "131.113.55.2",
          product_id: this.product_id,
          country: "en",
        });
        //meta信息
        this.metaData.title = res.data.meta.title;
        this.metaData.keywords = res.data.meta.keywords;
        this.metaData.description = res.data.meta.description;
        //this.mainVideo = responseData.data.mainVideo;
        this.slides = res.data.productImage.images;
        this.showImage = this.slides[0];
        this.subject = res.data.subject;
        this.subjectTrans = res.data.subjectTrans;
        this.priceRangeList = res.data.productSaleInfo.priceRangeList;
        this.transPriceRangeList = res.data.productSaleInfo.transPriceRangeList;
        this.productAttribute = res.data.productAttribute;
        this.productDescription = res.data.description;
        this.attrCategory = res.data.attrCategory ?? {};
        this.attrCategoryType = res.data.attrCategoryType ?? [];
        this.skuAttrTrans = res.data.skuAttrTrans;
        this.minOrderQuantity = res.data.minOrderQuantity;
        this.exchange = res.data.exchange;
        this.seller_open_id = res.data.sellerOpenId;
        this.shipping_info = res.data.productShippingInfo.sendGoodsAddressText;
        this.quoteType = res.data.productSaleInfo.quoteType;
        Object.values(this.attrCategory).forEach((item) => {
          let num = 1;
          if (num === 1) {
            if (item[0].children) {
              this.childrenAttributeItem = Object.values(item[0].children)[0];
              this.skuImageUrl = item[0].skuImageUrl;
            } else {
              this.childrenAttributeItem = Object.values(item);
              this.skuImageUrl = item[0].skuImageUrl;
            }
            // console.log(this.skuImageUrl);
            // this.skuImageUrl = this.childrenAttributeItem.skuImageUrl;
            this.attributeSelect = item[0];
          }
        });
        //关联产品
        this.category_id = res.data.categoryId;
        await this.getrelatedProduct(this.category_id); // 在这里调用getrelatedProduct() 并传递 category_id
        //历史浏览产品
        const product = {
          offerId: res.data.offerId,
          subjectTrans: res.data.subjectTrans,
          image: res.data.productImage.images[0],
          price: res.data.productSaleInfo.transPriceRangeList[0].price,
          time: new Date()
        };
        // 从本地存储中获取之前浏览过的产品列表
        let historyViewed =
          JSON.parse(localStorage.getItem("historyViewed")) || [];
        // 检查是否已存在相同的产品，如果存在则不添加
        const isDuplicate = historyViewed.findIndex(
          (item) => item.offerId === product.offerId
        );
        if (isDuplicate !== -1) {
          historyViewed.splice(isDuplicate, 1);
        }
        // 将当前产品信息添加到浏览历史列表中
        historyViewed.push(product);
        // 将更新后的浏览历史列表保存到本地存储中
        localStorage.setItem("historyViewed", JSON.stringify(historyViewed));
        this.retrieveHistoryViewed();
      } catch (err) {
        console.error("Error:", err);
      }
    },
    selectAttributeItem(attributeItem) {
      this.attributeSelect = attributeItem;
      this.showImage = attributeItem.skuImageUrl;
      this.skuImageUrl = attributeItem.skuImageUrl;
      this.selectedColor = attributeItem;
      const children = attributeItem.children;
      const keys = Object.keys(children);
      this.keysValue = keys.join(", ");
      for (const key of keys) {
        this.childrenAttributeItem = children[key];
      }
    },
    expandRows() {
      this.visibleRowCount = this.childrenAttributeItem.length;
      this.isCollapsed = false;
    },
    collapseRows() {
      this.visibleRowCount = 5;
      this.isCollapsed = true;
    },
    async getrelatedProduct() {
      const url = "/related_products";
      try {
        const res = await post(url, {
          ip: "127.0.0.1",
          category_id: this.category_id,
        });
        if (res.code === 1) {
          this.relatedProduct = res.data;
        } else {
          Message.error({
            message: res.msg,
            offset: 500,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    retrieveHistoryViewed() {
      // 从本地存储中检索浏览历史数据
      const historyViewedData = JSON.parse(localStorage.getItem("historyViewed")) || [];
      // 更新组件中的数据
      this.historyViewed = historyViewedData;
    },
    // 加入购物车
    async addToCartAction() {
      let cartList = [];
      if (localStorage.getItem("cartList")) {
        cartList = JSON.parse(localStorage.getItem("cartList"));
      }

      if (Object.keys(this.attrCategory).length === 0) {
        let element = {
          ip: "127.0.0.1",
          product_id: this.product_id,
          product_name: this.subject,
          product_name_trans: this.subjectTrans,
          sku_image: this.slides[0],
          product_image: this.slides[0],
          qty: this.priceRangeList[0].startQuantity,
          base_row_single: this.priceRangeList[0].price,
          base_row_total: this.priceRangeList[0].price * this.priceRangeList[0].startQuantity,
          quote_row_single: this.transPriceRangeList[0].price,
          quote_grand_total: this.transPriceRangeList[0].price * this.transPriceRangeList[0].startQuantity
        }
        if (isLogin()) {
          post("/add_final_to_cart", element).then(res => {
            if (res.code === 1) {
              Message.success({
                message: "Add success",
                offset: 300
              })
            } else {
              console.log(res);
            }
          })
        } else {
          const existingCartItemIndex = cartList.findIndex((item) => item.product_id.toString() === element.product_id.toString());
          if (existingCartItemIndex === -1) {
            cartList.push(element);
          } else {
            cartList[existingCartItemIndex].qty += element.qty;
            cartList[existingCartItemIndex].base_grand_total = cartList[existingCartItemIndex].base_row_total * cartList[existingCartItemIndex].qty;
            cartList[existingCartItemIndex].quote_grand_total = cartList[existingCartItemIndex].quote_row_single * cartList[existingCartItemIndex].qty;
          }

          if (this.quoteType === 2) {
            let amount = 0, price = 0;
            cartList.forEach(m => {
              if (m.product_id === element.product_id) {
                amount += m.qty;
              }
            })
            this.transPriceRangeList.forEach(p => {
              if (amount >= p.startQuantity) {
                price = p.price;
              }
            })
            cartList.forEach(m => {
              if (m.product_id === element.product_id) {
                m.quote_row_single = price;
                m.quote_grand_total = price * m.qty;
              }
            })
          }
          localStorage.setItem("cartList", JSON.stringify(cartList));
          Message.success({
            message: "Add success",
            offset: 300
          })
        }
      } else {
        if (this.localCart.length === 0) {
          Message.warning({
            message: "There is nothing selected",
            offset: 300
          })
        } else {
          let flag = true;
          this.localCart.forEach(item => {
            let option = {};
            option[item.spec_id] = {
              base_row_single: item.base_row_single,
              base_row_total: item.base_row_single * item.qty,
              quote_row_single: item.quote_row_single,
              quote_row_total: item.quote_row_single * item.qty,
              qty: item.qty,
              sku_image: item.product_image,
              zh: item.zh,
              trans: item.trans
            }

            let element = {
              sku_image: item.product_image,
              product_id: this.product_id,
              product_name: this.subject,
              product_name_trans: this.subjectTrans,
              product_image: this.slides[0],
              price_range_list: JSON.stringify(this.transPriceRangeList),
              options: JSON.stringify(option),
              quote_type: this.quoteType,
              exchange: this.exchange,
              quote_currency: "USD",
              quote_currency_code: "$",
              base_row_single: item.base_row_single,
              base_grand_total: item.base_row_single * item.qty,
              quote_row_single: item.quote_row_single,
              quote_grand_total: item.quote_row_single * item.qty,
              seller_open_id: this.seller_open_id,
              shipping_info: this.shipping_info,
              is_detail: 1,
              spec_id: item.spec_id,
              qty: item.qty
            }

            if (isLogin()) {
              post("/add_to_cart", element).then(res => {
                if (res.code !== 1) {
                  flag = false;
                  console.log(res);
                }
              })
            } else {
              const existingSpec = cartList.findIndex((n) => n.spec_id === element.spec_id);
              if (existingSpec === -1) {
                cartList.push(element);
              } else {
                cartList[existingSpec].qty += element.qty;
                cartList[existingSpec].base_grand_total = cartList[existingSpec].base_row_total * cartList[existingSpec].qty;
                cartList[existingSpec].quote_grand_total = cartList[existingSpec].quote_row_single * cartList[existingSpec].qty;
              }

              if (this.quoteType === 2) {
                let amount = 0, price = 0;
                cartList.forEach(m => {
                  if (m.product_id === element.product_id) {
                    amount += m.qty;
                  }
                })
                this.transPriceRangeList.forEach(p => {
                  if (amount >= p.startQuantity) {
                    price = p.price;
                  }
                })
                cartList.forEach(m => {
                  if (m.product_id === element.product_id) {
                    m.quote_row_single = price;
                    m.quote_grand_total = price * m.qty;
                  }
                })
              }
              localStorage.setItem("cartList", JSON.stringify(cartList));
            }
          })
          if (flag) {
            Message.success({
              message: "Add success",
              offset: 300
            })
          } else {
            Message.error({
              message: "Something unexpected happened, please contact us by support@chinabulkbuy.com",
              offset: 300
            })
          }
        }
      }
    },
    handleChange(childAttribute) {
      // console.log(this.childrenAttributeItem);
      const index = this.localCart.findIndex((item) => item.spec_id === childAttribute.specId);
      if (index === -1) {
        let zh = {}, trans = {};
        zh[childAttribute.attributeName] = childAttribute.value;
        trans[childAttribute.attributeNameTrans] = childAttribute.valueTrans;

        if (this.attrCategoryType.length > 1) {
          zh[this.attributeSelect.attributeName] = this.attributeSelect.value;
          trans[this.attributeSelect.attributeNameTrans] = this.attributeSelect.valueTrans;
        }

        let skuImage = childAttribute.skuImageUrl === undefined ? this.skuImageUrl : childAttribute.skuImageUrl;

        let element = {
          product_image: skuImage,
          base_row_single: childAttribute.price,
          quote_row_single: childAttribute.transPrice,
          spec_id: childAttribute.specId,
          qty: childAttribute.count,
          zh: zh,
          trans: trans
        }
        this.localCart.push(element);
      } else {
        this.localCart[index].qty = childAttribute.count;
        if (this.localCart[index].qty === 0) {
          this.localCart.splice(index, 1);
        }
      }
      this.calculateTotalPrice();
      this.calculateTotalQuantity();
    },
    calculateTotalPrice() {
      this.totalPrice = 0;
      // this.baseGrandTotal = 0;

      // 如果不是类型2，按照原有逻辑计算总价
      if (this.quoteType !== 2) {

        // 将之前点击过的总价加到当前的总价里
        // let itemQuoteTotal = 0;
        // let itemTotal = 0;
        // for (let specId in this.addToCart) {
        //   if (Object.prototype.hasOwnProperty.call(this.addToCart, specId)) {
        //     itemQuoteTotal += this.addToCart[specId].quote_row_total;
        //     itemTotal += this.addToCart[specId].base_row_total;
        //   }
        // }
        if (this.localCart.length > 0) {
          this.localCart.forEach(item => {
            this.totalPrice += item.quote_row_single * item.qty;
            // this.baseGrandTotal += item.price * item.count;
          })
        }
        // this.totalPrice = itemQuoteTotal;
        // this.baseGrandTotal = itemTotal;
      } else {
        // 类型2时，遍历价格区间列表，找到合适的价格
        let count = 0, price = 0, transPrice = 0;
        this.localCart.forEach(item => {
          count += item.qty;
        })

        for (let i=0; i < this.transPriceRangeList.length; i++) {
          if (count >= this.transPriceRangeList[i].startQuantity) {
            transPrice = this.transPriceRangeList[i].price;
            price = this.priceRangeList[i].price;
          }
        }
        this.localCart.forEach(item => {
          item.quote_row_single = transPrice;
          item.base_row_single = price;
        })
        this.totalPrice = count * transPrice;
        // let currentTransPrice = 0;
        // let currentPrice = 0;
        // for (let i = 0; i < this.transPriceRangeList.length; i++) {
        //   const range = this.transPriceRangeList[i];
        //   if (currentTransPrice === 0) {
        //     currentTransPrice = range.price;
        //     currentPrice = this.priceRangeList[i].price;
        //   }
        //   if (this.addTocartNumber >= range.startQuantity) {
        //     currentTransPrice = range.price;
        //   } else {
        //     break;
        //   }
        // }
        // this.localCart.forEach(item => {
        //   item.base_row_single = currentPrice;
        //   item.quote_row_single = currentTransPrice;
        // })
        // 将合适的价格乘以数量得到总价
        // this.totalPrice = this.addTocartNumber * currentTransPrice;
        // this.baseGrandTotal = this.addTocartNumber * currentPrice;
      }
    },
    calculateTotalQuantity() {
      this.totalQuantity = this.localCart.reduce((total, item) => {
        return total + item.qty;
      }, 0);
    },
    needSample() {
      if (isLogin()) {
        post("/apply_sample", {
          offer_id: this.product_id,
          product_name: this.subjectTrans,
          product_image: this.slides[0],
        })
          .then((res) => {
            if (res.code === 1) {
              window.open("/thank-you", "_blank");
              // Message.success({
              //   message: res.msg,
              //   offset: 300,
              // });
            } else {
              Message.error({
                message: res.msg,
                offset: 300,
              });
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      } else {
        this.loginDialog = true;
      }
    },
    async loadCollectedList() {
      try {
        const res = await get("/my_collect_list");
        if (res.code === 1) {
          // 如果返回的数据包含已收藏的 offerId，则将其添加到 collectedProducts 中
          res.data.forEach((offerId) => {
            this.$set(this.collectedProducts, offerId, true);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    addCollect(product) {
      if (isLogin()) {
        let productImage = "";
        if (product.imageUrl.includes("http")) {
          productImage = product.imageUrl;
        }
        if (this.isCollected(product.offerId)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + product.offerId + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的offerId
                this.$set(this.collectedProducts, product.offerId, false);
                // Message.success({
                //   message: "Remove success.",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          post("/add_collect", {
            category: "product_detail_" + this.product_id,
            source_id: product.offerId,
            product_image: productImage,
            product_name: product.subjectTrans,
            product_price: product.transPriceInfo.price,
          })
            .then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的offerId
                this.$set(this.collectedProducts, product.offerId, true);
                // Message.success({
                //   message: "Collect success!",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    historyViewedAddCollect(product) {
      if (isLogin()) {
        let productImage = "";
        if (product.image.includes("http")) {
          productImage = product.image;
        }
        if (this.isCollected(product.offerId)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + product.offerId + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的offerId
                this.$set(this.collectedProducts, product.offerId, false);
                // Message.success({
                //   message: "Remove success.",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          post("/add_collect", {
            category: "product_detail_" + this.product_id,
            source_id: product.offerId,
            product_image: productImage,
            product_name: product.subjectTrans,
            product_price: product.price,
          })
            .then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的offerId
                this.$set(this.collectedProducts, product.offerId, true);
                // Message.success({
                //   message: "Collect success!",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    productImageAddCollect() {
      if (isLogin()) {
        let productImage = "";
        if (this.slides[0].includes("http")) {
          productImage = this.slides[0];
        }
        if (this.isproductImageCollected(this.product_id)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + this.product_id + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的offerId
                this.$set(this.collectedProducts, this.product_id, false);
                // Message.success({
                //   message: "Remove success.",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          post("/add_collect", {
            category: "product_detail_" + this.product_id,
            source_id: this.product_id,
            product_image: productImage,
            product_name: this.subjectTrans,
            product_price: this.transPriceRangeList[0].price,
          })
            .then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的offerId
                this.$set(this.collectedProducts, this.product_id, true);
                // Message.success({
                //   message: "Collect success!",
                //   offset: 500,
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500,
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    isCollected(offerId) {
      // 判断产品是否已被收藏
      return (
        Object.prototype.hasOwnProperty.call(this.collectedProducts, offerId) &&
        this.collectedProducts[offerId]
      );
    },
    isproductImageCollected() {
      // 判断产品是否已被收藏
      return (
        Object.prototype.hasOwnProperty.call(
          this.collectedProducts,
          this.product_id
        ) && this.collectedProducts[this.product_id]
      );
    },
    // 点击加购按钮前的验证判断
    // addToMyCart() {
    //   let token = getToken();
    //   let haslogin = this.checkUserLogin(token);
    //   this.addToCartAction(token);
    //   if (haslogin) {
    //     //已登录成功
    //   } else {
    //     Message.error({
    //       message: "Please login first.",
    //       offset: 500
    //     });
    //   }
    // },
    // 检查本地存储中是否存在 token
    // checkUserLogin(token) {
    //   if (token) {
    //     return true;
    //   }
    //   return false;
    // },
    getDynamicUrl(product) {
      return `/product-details/?product_id=${product.offerId}`;
    },
    openDrawer(product) {
      this.item = {
        ip: "127.0.0.1",
        product_id: product.offerId.toString(),
        product_name: product.subject,
        product_name_trans: product.subjectTrans,
        product_image: product.imageUrl,
        sku_image: product.imageUrl,
        qty: 1,
        base_row_single: product.priceInfo.price,
        quote_row_single: product.transPriceInfo.price,
        category_id: product.source_category_id
      };
      // console.log(this.item);
      this.drawer = true;
      // this.sid = product.offerId.toString();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
