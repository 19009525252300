<template>
  <div>
    <el-card class="favourite-head" shadow="never">
      <h3>Viewed({{ amount }})</h3>
    </el-card>
    <el-card class="viewed-content" shadow="never" v-loading="loading">
      <div v-if="amount > 0">
        <div v-for="(item, index) in showList" :key="index">
          <div class="content-container">
            <div class="content-text">
              <img :src="item.image" @click="viewProductDetail(item)"/>
              <div class="content-title">
                <span @click="viewProductDetail(item)">{{ item.subjectTrans }}</span>
                <span class="content-price" @click="viewProductDetail(item)">${{ item.price }}</span>
              </div>
            </div>
            <div class="content-button">
              <el-button type="moveCart" @click="moveToCart(item)">Add to cart</el-button>
              <el-button type="moveWish" @click="moveToCollection(item)">Add to wish list</el-button>
            </div>
          </div>
          <el-divider/>
        </div>
        <div class="pagination">
          <el-pagination
              layout="prev, pager, next"
              :page-count="totalPage"
              :current-page.sync="currentPage"
              @current-change="handlePageChange"
              @prev-click="handlePrevClick"
              @next-click="handleNextClick">
          </el-pagination>
        </div>
      </div>
      <div v-else class="empty">
        <span><b-icon icon="inbox"/>Empty</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { post } from "@/utils/request";
import { Message } from "element-ui";

export default {
  name: 'ViewedPanel',
  data () {
    return {
      amount: 0,
      viewedList: [],
      showList: [],
      product: {},
      loading: false,
      totalPage: 1,
      currentPage: 1,
      pageSize: 10
    }
  },
  created() {
    if (window.localStorage.getItem("historyViewed")) {
      let list = JSON.parse(window.localStorage.getItem("historyViewed"));
      this.amount = list.length;
      this.totalPage = this.amount / this.pageSize;
      for (let i = this.amount-1; i >= 0; i--) {
        this.viewedList.push(list[i]);
      }
      this.getPagination();
    }
  },
  methods: {
    getPagination() {
      this.showList = [];
      let i = (this.currentPage - 1) * this.pageSize;
      let max = (this.amount < this.pageSize) ? this.amount : this.pageSize * this.currentPage;
      for (i; i < max; i++) {
        this.showList.push(this.viewedList[i]);
      }
    },
    handlePageChange() {
      this.getPagination();
    },
    handlePrevClick() {
      this.currentPage--;
      this.getPagination();
    },
    handleNextClick() {
      this.currentPage++;
      this.getPagination();
    },
    moveToCart(item) {
      post("/product_detail", {
        ip: "127.0.0.1",
        product_id: item.offerId,
        country: "en"
      }).then(res => {
        if (res.code === 1) {
          this.product = {
            ip: "127.0.0.1",
            product_name: res.data.subject,
            product_name_trans: res.data.subjectTrans,
            product_id: res.data.offerId,
            product_image: res.data.productImage.images[0],
            qty: res.data.minOrderQuantity,
            base_row_single: res.data.productSaleInfo.priceRangeList[0].price,
            quote_row_single: res.data.productSaleInfo.transPriceRangeList[0].price
          }
          post("/add_final_to_cart", this.product).then(res => {
            if (res.code === 1) {
              Message.success("Added to the cart");
            } else {
              console.log(res.msg);
            }
          })
        } else {
          console.log(res.msg);
        }
      })
    },
    moveToCollection(item) {
      post("/product_detail", {
        ip: "127.0.0.1",
        product_id: item.offerId,
        country: "en"
      }).then(res => {
        console.log(res);
        if (res.code === 1) {
          this.product = {
            source_id: res.data.offerId,
            product_name: res.data.subjectTrans,
            product_price: res.data.productSaleInfo.transPriceRangeList[0].price,
            product_image: res.data.productImage.images[0],
            category: "product_detail_001" + res.data.categoryId
          }
          post("/add_collect", this.product).then(res => {
            if (res.code === 1) {
              Message.success("Added to the wish list");
            } else {
              console.log(res.msg);
            }
          })
        } else {
          console.log(res.msg);
        }
      })
    },
    viewProductDetail(item) {
      console.log(item);
      if (item.offerId) {
        window.location.href = `/product-details/?product_id=${item.offerId}`;
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
