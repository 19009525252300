<template>
  <div class="overview-panel">
    <el-card shadow="never">
      <div class="head-panel">
        <div class="icon-panel">
          <div class="icon-element">
            <div class="avatar">
              <img src="@/assets/images/default_avatar.png"/>
              <div class="userinfo">
                <span>{{ user.username }}</span>
                <span>{{ user.email }}</span>
              </div>
            </div>
          </div>
          <div class="icon-element" @click="toPanel(2)" style="cursor: pointer;">
            <el-badge :value="count.wishlist" class="item">
              <img src="@/assets/images/personal_center/follow-fill.png"/>
            </el-badge>
            <span>Wish List</span>
          </div>
          <div class="icon-element" @click="toPanel(3)" style="cursor: pointer;">
            <img src="@/assets/images/personal_center/time-fill.png"/>
            <span>Viewed</span>
          </div>
          <div class="icon-element" @click="toPanel(5)" style="cursor: pointer;">
            <img src="@/assets/images/personal_center/settings-fill.png"/>
            <span>Settings</span>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="my-order-card" shadow="never">
      <div class="my-order-card-head">
        <h4>My inquiry</h4>
<!--        <span @click="toOrderPanel('all')">View All<b-icon icon="chevron-right"/></span>-->
      </div>
      <el-divider/>
      <div class="my-order-card-content">
        <div class="my-order-card-content-icon-element" @click="toPanel('4')">
          <el-badge :value="count.order" class="item">
            <img src="@/assets/images/personal_center/order.png"/>
          </el-badge>
          <span>Orders</span>
        </div>
        <div class="my-order-card-content-icon-element" @click="toPanel('5')">
          <el-badge :value="count.sample" class="item">
            <img src="@/assets/images/personal_center/request.png"/>
          </el-badge>
          <span>Sample requests</span>
        </div>
        <div class="my-order-card-content-icon-element" @click="toPanel('6')">
          <el-badge :value="count.inquiry" class="item">
            <img src="@/assets/images/personal_center/quotation.png"/>
          </el-badge>
          <span>RFQ history</span>
        </div>
<!--        <div class="my-order-card-content-icon-element" @click="toOrderPanel('complete')">-->
<!--          <img src="@/assets/images/personal_center/order-success-fill.png"/>-->
<!--          <span>Complete</span>-->
<!--        </div>-->
      </div>
<!--      <el-divider/>-->
<!--      <div class="my-order-card-content-appeal">-->
<!--        <img src="@/assets/images/personal_center/catalog-check.png"/>-->
<!--        <span>My appeal</span>-->
<!--        <div class="my-order-card-content-appeal-icon">-->
<!--          <b-icon icon="chevron-right"/>-->
<!--        </div>-->
<!--      </div>-->
    </el-card>
    <el-card class="FAQ-card" shadow="never">
      <h4>FAQ</h4>
      <el-collapse v-model="activeFAQ">
        <el-collapse-item title="How to check order status?" name="1">
          <div>Go to Orders > All Orders, find all your orders, and check their statuses.</div>
        </el-collapse-item>
        <el-collapse-item title="How can I know if my items have been shipped?" name="3">
          <div>Step 1: Go to Orders > All Orders and find your paid order.</div>
          <div>Step 2: Check the shipping status.</div>
        </el-collapse-item>
        <el-collapse-item title="How to track delivery status?" name="4">
          <div>Step 1: Go to Orders > All Orders, find your paid order, and view the tracking number.</div>
          <div>Step 2: Enter the tracking number to check the delivery status.</div>
        </el-collapse-item>
        <el-collapse-item title="How to change my password?" name="5">
          <div>Go to "My Account" section and select "Settings".click Change Password, follow the prompts, you can finish.</div>
        </el-collapse-item>
        <el-collapse-item title="How to Modify Account Information?" name="6">
          <div>Go to"My Account" section and select "Settings". click on the details you'd like to change and make your edits.</div>
        </el-collapse-item>
        <el-collapse-item title="How to bookmark?" name="7">
          <div>Step 1: Click on the heart icon at the top left corner of the product image to bookmark it.</div>
          <div>Step 2: Go to "My Account" and then "My Bookmarks" to view all your bookmarked products.</div>
        </el-collapse-item>
        <el-collapse-item title="How do I check my sample request?" name="8">
          <div>Click on "My Account," then go to "My Sample Requests" to view them.</div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
// import { get } from "@/utils/request";
import { eventBus } from "@/common/eventBus";
import {isLogin} from "@/utils/utils";
import {post} from "@/utils/request";

export default {
  name: 'OverviewPanel',
  data() {
    return {
      user: {},
      activeFAQ: "",
      count: {
        inquiry: 0,
        order: 0,
        sample: 0,
        wishlist: 0
      }
    }
  },
  // watch: {
  //   '$route'() {
  //     this.getUserInfo();
  //   }
  // },
  created() {
    this.getUserInfo();
    this.getOverviewCount();
  },
  // mounted() {
  //   this.getUserInfo();
  // },
  methods: {
    toPanel(index) {
      eventBus.$emit("toPanel", index);
    },
    // toOrderPanel(string) {
    //   eventBus.$emit("toOrderPanel", string);
    // },
    getUserInfo() {
      if (isLogin()) {
        this.user = JSON.parse(localStorage.getItem("userinfo"));
      }
      // get('/user_info').then(res => {
      //   if (res.code === 1) {
      //     this.user = res.data;
      //   }
      // }).catch(err => {
      //   console.log(err);
      // })
    },
    getOverviewCount() {
      post("/overview_count").then(res => {
        if (res.code === 1) {
          this.count = res.data;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
