<template>
  <div>
    <el-card shadow="never">
      <h3>Settings</h3>
    </el-card>
    <el-card class="setting-content" shadow="never">
      <div class="setting-module">
        <span class="head">
          Personal information
        </span>
        <span class="hint">
          Complete personal information helps us provide you with better services.
        </span>
        <el-link @click="profileDialog = true" :underline="false">Edit profile</el-link>
      </div>
      <div class="setting-module">
        <span class="head">
          Security information
        </span>
        <el-link @click="passwordDialog = true" :underline="false">Change password</el-link>
      </div>
    </el-card>

    <el-dialog
      title="Edit profile"
      class="dialog"
      :visible.sync="profileDialog">
      <el-form
          label-position="top"
        :model="profileForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Email">
              <el-input v-model="profileForm.email" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Name">
              <el-input v-model="profileForm.name"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Gender">
              <el-select v-model="profileForm.gender" style="width: 100%" placeholder="please select">
                <el-option label="Female" value="0"/>
                <el-option label="Male" value="1"/>
                <el-option label="Other" value="2"/>
                <el-option label="Prefer not to say" value="3"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Phone">
              <el-input v-model="profileForm.phone"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Country">
              <el-input v-model="profileForm.country"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Company">
              <el-input v-model="profileForm.company"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Company type">
              <el-select v-model="profileForm.company_type" style="width: 100%" placeholder="please select">
                <el-option label="Importer" value="importer"/>
                <el-option label="Supermarket" value="supermarket"/>
                <el-option label="Chain-shop" value="chain-shop"/>
                <el-option label="Grocery store" value="grocery-store"/>
                <el-option label="Online shop" value="online-shop"/>
                <el-option label="Other" value="other"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="Company address">
              <el-input v-model="profileForm.company_address"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="Company Website">
              <el-input v-model="profileForm.company_website"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Main business product">
              <el-input v-model="profileForm.main_business_product"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Annual import volume(USD)">
              <el-input v-model="profileForm.annual_import_volume"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="Other instructions">
              <el-input type="textarea" v-model="profileForm.other_instructions"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel" @click="profileDialog = false">Cancel</el-button>
        <el-button type="confirm" @click="profileConfirm">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="Change password"
        width="30%"
        class="dialog"
        :visible.sync="passwordDialog">
      <el-form
          ref="password"
          :rules="passwordRule"
          :model="passwordForm">
        <el-form-item label="Old password" prop="old_password">
          <el-input
              v-model="passwordForm.old_password"
              show-password/>
        </el-form-item>
        <el-form-item label="New password" prop="password">
          <el-input
              v-model="passwordForm.password"
              show-password/>
        </el-form-item>
        <el-form-item label="Confirm password" prop="password_confirmation">
          <el-input
              v-model="passwordForm.password_confirmation"
              show-password/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel" @click="passwordDialog = false">Cancel</el-button>
        <el-button type="confirm" @click="passwordConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;

export default {
  name: 'SettingPanel',
  data () {
    const validateNewPassword = (rule, value, callback) => {
      if (value === this.passwordForm.old_password) {
        callback(new Error("The new password cannot be the same as the original password."));
      } else if (!regex.test(value)) {
        callback(new Error("Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."));
      } else {
        callback();
      }
    };
    const validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.passwordForm.password) {
        callback(new Error("Password not match."));
      } else {
        callback();
      }
    };
    return {
      profileDialog: false,
      passwordDialog: false,
      profileForm: {},
      passwordForm: {
        old_password: "",
        password: "",
        password_confirmation: ""
      },
      passwordRule: {
        old_password: [
          { required: true, message: "please input current password", trigger: "blur" },
        ],
        password: [
          { required: true, message: "please input new password", trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
        ],
        password_confirmation: [
          { required: true, message: "please confirm new password", trigger: "blur" },
          { validator: validateNewPassword2, trigger: "blur" },
        ],
      }
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.profileForm = {};
      get('/user_info').then(res => {
        if (res.code === 1) {
          this.profileForm = res.data;
          this.profileForm.gender = res.data.gender.toString();
        }
      }).catch(err => {
        console.log(err);
      })
    },
    profileConfirm() {
      post("/user_change_name", this.profileForm).then(res => {
        if (res.code === 1) {
          Message.success({
            message: "Update success",
            offset: 300
          });
        } else {
          console.log(res.msg);
        }
      })
      this.profileDialog = false;
    },
    passwordConfirm() {
      this.$refs['password'].validate((valid) => {
        if (valid) {
          post("/change_password_in_personal_center", this.passwordForm).then(res => {
            if (res.code === 1) {
              Message.success({
                message: "Password updated",
                offset: 300
              })
              localStorage.clear();
              get("/logout").then(result => {
                if (result.code === 1) {
                  localStorage.setItem("linkDump", this.$route.path);
                  window.location.href = "/login";
                }
              })
            } else {
              console.log(res.msg);
            }
          })
        } else {
          Message.warning({
            message: "Please check your password",
            offset: 300
          })
        }
      })

    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
