<template>
  <div class="privacy-policy">
    <div class="banner">
      <img src="@/assets/images/privacy-policy-banner.webp" alt=""/>
      <h1>Privacy Policy</h1>
    </div>
    <div class="content">
      <div class="title">
        <h3>Privacy Policy</h3>
      </div>
      <div class="test">
        <p class="paragraph">
            This Privacy Policy explains how “we” collect, use, share and process your information as well as the rights and choices you have associated with that information. This privacy policy applies to all personal information collected during any written, electronic and oral communication, or personal information collected online or offline, including: our website, and any other email.
        </p>
        <p class="paragraph">
            Please read our Terms and Conditions and this Policy before accessing or using our Services. If you cannot agree with this Policy or the Terms and Conditions, please do not access or use our Services. If you are located in a jurisdiction outside the European Economic Area, by using our Services, you accept the Terms and Conditions and accept our privacy practices described in this Policy.
        </p>
        <p class="paragraph">
            We may modify this Policy at any time, without prior notice, and changes may apply to any Personal Information we already hold about you, as well as any new Personal Information collected after the Policy is modified. If we make changes, we will notify you by revising the date at the top of this Policy. We will provide you with advanced notice if we make any material changes to how we collect, use or disclose your Personal Information that impact your rights under this Policy. If you are located in a jurisdiction other than the European Economic Area, the United Kingdom or Switzerland (collectively “European Countries”), your continued access or use of our Services after receiving the notice of changes, constitutes your acknowledgement that you accept the updated Policy.
        </p>
        <p class="paragraph">
            In addition, we may provide you with real time disclosures or additional information about the Personal Information handling practices of specific parts of our Services. Such notices may supplement this Policy or provide you with additional choices about how we process your Personal Information.
        </p>
        <p class="paragraph subtitle">
            Personal Information We Collect
        </p>
        <p class="paragraph">
          We collect personal information when you use our Services, submit personal information when requested with the Site. Personal information generally is any information that relates to you, identifies you personally or could be used to identify you, such as your name, email address, phone number and address. The definition of personal information varies by jurisdiction. Only the definition that applies to you based on your location applies to you under this Privacy Policy. Personal information does not include data that has been irreversibly anonymized or aggregated so that it can no longer enable us, whether in combination with other information or otherwise, to identify you.
        </p>
        <p class="paragraph subtitle">
            The types of personal information that we may collect about you include:
        </p>
        <p class="paragraph">
            Information You Directly and Voluntarily Provide to Us to execute the purchase or services contract. We collect your personal information you give us when you use our Services. For example, if you visit our Site and place an order, we collect information that you provide to us during the ordering process. This information will include your last name, mailing address, email address, phone number, PRODUCTS_INTERESTED, WHATSAPP, COMPANY,COUNTRY . We may also collect personal information when you communicate with any of our departments such as customer service, or when you complete online forms or surveys provided on the Site. You may also choose to provide your email address to us if you would like to receive information about the products and services that we offers.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
