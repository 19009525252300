<template>
  <div class="register">
    <div class="register-image">
      <img src="@/assets/images/login.png" />
      <div class="register-text">
        <h3>Access exclusive features through a FREE account</h3>
        <ul>
          <li><b-icon icon="check" />Use the 'Wish List' feature to save.</li>
          <li><b-icon icon="check" />Send inquiries anytime, anywhere.</li>
          <li>
            <b-icon icon="check" />Easily track your orders and browsing
            history.
          </li>
          <li>
            <b-icon icon="check" />Quickly track the progress of logistics.
          </li>
          <li>
            <b-icon icon="check" />Learn about the latest promotional policies.
          </li>
        </ul>
      </div>
    </div>
    <div class="register-form">
      <div class="title">
        <img src="@/assets/images/logo.png" />
      </div>
      <el-form
        :model="registerForm"
        status-icon
        :rules="rules"
        ref="registerForm"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h3>Get Started with a Free account!</h3>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="First name" prop="firstname">
              <el-input v-model="registerForm.firstname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Last name" prop="lastname">
              <el-input v-model="registerForm.lastname"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item label="Email address" prop="email">
              <el-input type="email" v-model="registerForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-form-item label="Password" prop="password">
              <el-input
                :type="passwordFieldType"
                v-model="registerForm.password"
              ></el-input>
              <b-icon
                class="password-eye"
                icon="eye-slash-fill"
                @click="togglePasswordVisibility('text')"
                v-if="!showPassword"
              />
              <b-icon
                class="password-eye"
                icon="eye-fill"
                @click="togglePasswordVisibility('password')"
                v-if="showPassword"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Enter Verification Code" prop="verify_code">
              <el-input v-model="registerForm.verify_code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="register-code">
              <span
                v-show="!showSuccess && !showError"
                @click.prevent="getVerificationCode"
                class="code"
                >Get Verification Code</span
              >
              <div class="success" v-show="showSuccess">
                <b-icon icon="check-circle" />Code sent {{ timerSeconds }}s
              </div>
              <div class="error" v-show="showError">
                Didn't receive it?
                <span @click.prevent="getVerificationCode" class="code"
                  >Get another code</span
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-checkbox v-model="checked"
              >Send me emails about new arrivals, hot items, daily savings, &
              more.</el-checkbox
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="middle">
            <el-button type="primary" @click="register" plain
              ><strong>Creat Account</strong></el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";

export default {
  name: "RegisterPage",
  data() {
    return {
      registerForm: {
        ip: "131.113.55.2",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        verify_code: "",
      },
      rules: {
        firstname: [
          {
            required: true,
            message: "Please fill your first name.",
            trigger: ["blur"],
          },
        ],
        lastname: [
          {
            required: true,
            message: "Please fill your last name.",
            trigger: ["blur"],
          },
        ],
        email: [
          {
            required: true,
            message: "Please fill your email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address.",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill your password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;
              if (!regex.test(value)) {
                callback(
                  new Error(
                    "Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."
                  )
                );
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        verify_code: [
          {
            required: true,
            message: "Please enter verification code.",
            trigger: ["blur"],
          },
        ],
      },
      checked: true,
      showSuccess: false,
      showError: false,
      showPassword: false,
      timerSeconds: 0,
      timerInterval: null,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    togglePasswordVisibility(type) {
      this.showPassword = type === "text";
    },
    getVerificationCode() {
      if (!this.registerForm.email) {
        Message.error({
          message: "Please Enter Your Email.",
          offset: 500,
        });
        return;
      }
      const url = `/verifyCode/?email=${this.registerForm.email}&type=0`;
      get(url)
        .then((res) => {
          if (res.code === 1) {
            this.showSuccess = true;
            this.showError = false;
            this.startTimer();
          } else {
            Message.error({
              message: "Sending code error: " + res.msg,
              offset: 500,
            });
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },
    startTimer() {
      this.timerSeconds = 60; // 设置倒计时初始时间
      this.timerInterval = setInterval(() => {
        if (this.timerSeconds > 0) {
          this.timerSeconds--; // 每秒减少一秒
        } else {
          clearInterval(this.timerInterval); // 倒计时结束，清除计时器
          this.showSuccess = false;
          this.showError = true; // 显示错误提示
        }
      }, 1000); // 每秒执行一次
    },
    register() {
      post("/register", this.registerForm)
        .then((res) => {
          if (!this.checked) {
            Message.warning({
              message:
                "Please check the send me emails about new arrivals, hot items, daily savings, & more..",
              offset: 500,
            });
          }
          if (res.code === 1) {
            Message.success({
              message: "Registered successfully.",
              offset: 500,
            });
            this.registerForm = {};
            this.showSuccess = false;
            this.showError = false;

            // 将 token 存储在本地
            const token = res.data.token;
            localStorage.setItem("loginUserEmail", res.data.userinfo.email); //email
            localStorage.setItem(res.data.userinfo.email + "_token", token); //token
            const username = res.data.userinfo.username; // 提取用户名
            localStorage.setItem(
              res.data.userinfo.email + "_username",
              username
            ); //username
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo)); //userinfo

            if (localStorage.getItem("cartList")) {
              let cartList = JSON.parse(localStorage.getItem("cartList"));
              localStorage.setItem("cartList", "");

              if (cartList !== undefined) {
                const promises = [];
                cartList.forEach(item => {
                  const p = new Promise(function (resolve) {
                    setTimeout(() => {
                      if (item.spec_id) {
                        post("/add_to_cart", item).then(res => {
                          resolve(res);
                        })
                      } else {
                        post("/add_final_to_cart", item).then(res => {
                          resolve(res);
                        })
                      }
                    }, 300);
                  })
                  promises.push(p);
                })
                Promise.all(promises).then(function () {
                  if (isLogin()) {
                    if (localStorage.getItem("linkDump")) {
                      window.location.href = localStorage.getItem("linkDump");
                      localStorage.removeItem("linkDump");
                    } else {
                      window.location.href = "/";
                    }
                  } else {
                    window.location.href = "/register";
                  }
                })
              } else {
                this.jump();
              }
            } else {
              this.jump();
            }
          } else {
            Message.error({
              message: "Register Error: " + res.msg,
              offset: 300,
            });
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },
    async jump() {
      if (isLogin()) {
        if (localStorage.getItem("linkDump")) {
          window.location.href = localStorage.getItem("linkDump");
          localStorage.removeItem("linkDump");
        } else {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/register";
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
