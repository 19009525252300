
<template>
  <div class="main">
<!--    <div class="orderDetail">-->
<!--      <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--        <el-breadcrumb-item :to="{ path: '/account?type=' + type }">Order Management</el-breadcrumb-item>-->
<!--        <el-breadcrumb-item>Order Details</el-breadcrumb-item>-->
<!--      </el-breadcrumb>-->
<!--    </div>-->
<!--    <el-divider></el-divider>-->

    <el-card class="orderDetail" >
      <h3>Order Details</h3>
      <h6>Order number: {{ orderNumber }}</h6>
      <el-table
        empty-text="No data"
        :header-cell-style="{'text-align':'center'}"
        :data="orderData">
        <el-table-column label="Pictures" width="150px" align="center">
          <template #default="scope">
            <el-popover placement="top-start" title="" trigger="hover">
              <img :src="scope.row.product_image" alt="" width="200px" height="200px">
              <img
                  slot="reference"
                  :src="scope.row.product_image"
                  width="100px"
                  height="100px"
                  style="cursor: pointer"
                  @click="handleRowClick(scope.row)">
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="product_name_trans" label="Products" width="500px" align="center">
          <template #default="scope">
            <p @click="handleRowClick(scope.row)" style="cursor: pointer;">{{ scope.row.product_name_trans }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="options" label="Details" align="center">
          <template #default="scope">
            <p v-for="(value, key) in scope.row.options" :key="key">
              {{key}}:{{value}}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="priceByUnit" label="Price By Unit" align="center"></el-table-column>
        <el-table-column prop="qty" label="Amount" align="center"></el-table-column>
        <el-table-column prop="price" label="Price" align="center"></el-table-column>
      </el-table>
    </el-card>

    <el-card class="remark">
      <h3>Order remark</h3>
      <el-divider></el-divider>
      <h6>{{ remark }}</h6>
      <div class="file-uploaded" v-if="uploadFile.length > 0">
        <h3>Uploaded files:</h3>
        <div v-for="(item, index) in uploadFile" :key="index">
          <a :href="item" target="_blank">{{ fileName[index] }}</a>
        </div>
      </div>
    </el-card>

    <el-card class="address">
      <h3>Shipping address</h3>
      <el-divider></el-divider>
      <h6><i class="el-icon-location-outline"></i>{{ addressInfo }}</h6>
    </el-card>

    <el-card class="price">
      <h6>Total: <h3>{{ totalPrice }}</h3></h6>
      <el-button type="newOrder" @click="handleNewButton()">Start New Order</el-button>
    </el-card>
  </div>
</template>
<script>

import { post } from "@/utils/request";

export default {
  name: "OrderDetailPage",
  data() {
    return {
      orderNumber: "",
      type: "",
      orderData: [],
      orderElement: {
        picture: "",
        product: "",
        detail: "",
        priceByUnit: "",
        amount: "",
        price: ""
      },
      addressInfo: "",
      remark: "",
      uploadFile: [],
      fileName: [],
      totalPrice: "",
      pagination: {
        totalPage: 1,
        currentPage: 1,
        pageSize: 10
      }
    };
  },
  created() {
    let searchUrl = window.location.href;
    let searchData = searchUrl.split('=')[1];
    this.orderNumber = searchData;
    this.getOrderDetail();
  },
  methods: {
    async getOrderDetail() {
      post("/order_detail", {
        order_number: this.orderNumber,
        beginPage: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        // 处理成功响应
        const responseData = res.data;
        if (res.code === 1) {
          responseData.list.forEach(element => {
            element.priceByUnit = element.quote_currency_code + parseFloat(element.quote_row_single).toFixed(2);
            element.price = element.quote_currency_code + parseFloat(element.quote_row_total).toFixed(2);
            element.options = JSON.parse(element.options_trans);

            // if (element.options_trans !== null) {
            //   let temp = JSON.parse(element.options_trans);
            //   let keys = Object.keys(temp);
            //   let options = "";
            //   for (let i = 0; i < keys.length; i++) {
            //     options += keys[i] + ":" + temp[keys[i]] + "\n";
            //   }
            //   element.options = options;
            // }

            this.orderData.push(element);
          });
          this.remark = res.data.remark === "" ? "None" : res.data.remark;
          this.uploadFile = res.data.upload_file ?? [];
          this.fileName = res.data.origin_upload_file_name ?? [];
          this.pagination.totalPage = responseData.total;
          if (responseData.shipping_address === undefined) {
            this.addressInfo = "The address might be deleted";
          } else {
            this.addressInfo = responseData.shipping_address.customer_name + ", " +
                responseData.shipping_address.phone + ", " +
                responseData.shipping_address.address + ", " +
                responseData.shipping_address.city + ", " +
                responseData.shipping_address.country;
          }
          this.totalPrice = responseData.currency_code + parseFloat(responseData.total_price).toFixed(2);
        } else {
          console.log(responseData.msg);
        }
      }).catch(err => {
        console.log(err);
      })
    },
    handleRowClick(row) {
      window.location.href = "/product-details/?product_id=" + row.product_id;
    },
    handleNewButton() {
      window.location.href='/';
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
