<template>
  <div class="cart_wrap">
    <div class="my_cart">
      <h1>Cart({{ count }})</h1>
      <div class="list">
        <table>
          <!-- <thead>
            <tr>
              <th width="5%">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @change="handleSelectAll"
                />
              </th>
              <th width="10%">Image</th>
              <th width="25%">Product</th>
              <th width="20%">Details</th>
              <th width="10%">Price by unit</th>
              <th width="10%">Amount</th>
              <th width="10%">Price</th>
              <th width="10%">Eliminate</th>
            </tr>
          </thead> -->
          <tbody v-if="quote_items.length > 0">
            <tr v-for="(quote_item, index) in quote_items" :key="index">
              <!-- <td>
                <input
                  type="checkbox"
                  v-model="quote_item.isSelected"
                  @change="handleItemSelect(index)"
                />
              </td> -->
              <td width="5%">
                <input
                  type="checkbox"
                  v-model="quote_item.isSelected"
                />
              </td>
              <td width="10%">
                <div class="d-flex justify-content-center">
                  <a :href="getDynamicUrl(quote_item)">
                    <img :src="quote_item.sku_image" width="75" />
                  </a>
                </div>
              </td>
              <td width="25%">
                <div class="name">
                  <a :href="getDynamicUrl(quote_item)"
                    ><p>{{ quote_item.product_name_trans }}</p></a
                  >
                </div>
              </td>
              <td width="20%">
                <div class="option">
                  <p
                    v-for="(optionValue, optionKey) in quote_item.options_trans"
                    :key="optionKey"
                  >
                    {{ optionKey }}:{{ optionValue }}
                  </p>
                </div>
              </td>
              <td width="10%">
                <p class="unit_price">
                  {{ quote_item.quote_currency_code
                  }}{{ parseFloat(quote_item.quote_row_single).toFixed(2) }}
                </p>
              </td>
              <td width="10%">
                <el-input-number
                  v-model="quote_item.qty"
                  @change="handleQtyChange(quote_item)"
                  :min="1"
                />
              </td>
              <td width="10%">
                <p class="price">
                  {{ quote_item.quote_currency_code
                  }}{{
                    parseFloat(
                      quote_item.quote_row_single * quote_item.qty
                    ).toFixed(2)
                  }}
                </p>
              </td>
              <td width="10%">
                <div class="delete" @click="removeItem(quote_item)">
                  <img src="@/assets/images/shanchu.svg" title="delete" width="26" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <el-card class="remark" shadow="never">
        <h3>Order remark</h3>
        <el-input
          v-model="remark"
          type="textarea"
          placeholder="Please fill in your remarks about the order here"
          :autosize="{ minRows: 4 }"
        />
        <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :before-upload="beforeFileUpload"
            :on-change="uploadChange"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="5"
            accept=".xlsx,.xls,.pdf,.txt,.doc,.docx,.png,.jpg,.jpeg "
            :on-exceed="handleExceed"
            :auto-upload="false"
        >
          <el-button size="small" type="upload">Upload</el-button>
          <div slot="tip" class="el-upload__tip">Tips：Please upload any files( &lt; 5MB) related to your requirements, such as product design drawings/product lists/or others. This will help us understand and meet your needs more accurately.</div>
        </el-upload>
      </el-card>

      <div class="address">
        <div class="address_content">
          <div class="title">
            <h3>Shipping address</h3>
            <button type="button" @click="selectAddress">set</button>
          </div>
          <div class="area">
            <!-- <div class="add" v-if="selectedAddressInfo.length === 0">
              <div class="add_icon">
                <img
                  id="select_address"
                  src="@/assets/images/jiaru.svg"
                  alt=""
                  width="26"
                  @click="selectAddress"
                />
              </div>
              <div class="add_text">
                <span @click="selectAddress"
                  >Select an address or add a new address</span
                >
              </div>
            </div> -->
            <div class="address_list" v-if="selectedAddressInfo !== ''">
              <div class="item">
                <div class="icon">
                  <img src="@/assets/images/dingwei.svg" alt="" width="30" />
                </div>
                <div class="message">
                  <p>{{ selectedAddressInfo }}</p>
                </div>
              </div>
              <!-- <button type="button" @click="selectAddress">adjust</button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="cart_total">
        <ul>
          <li class="">
            * To effectively reduce transportation costs, we request a minimum
            order value of $1000.
          </li>
          <li class="">
            * Please be advised that the total provided is intended as an
            estimate only.
          </li>
        </ul>
        <!-- <div class="selectAll">
          <input
            type="checkbox"
            v-model="selectAll"
            @change="handleSelectAll"
          />
          <span>Select All</span>
          <img
            src="@/assets/images/shanchu.svg"
            class="delete"
            alt=""
            width="26"
            @click="deleteSelectedItems"
          />
        </div> -->
        <div class="order">
          <div>
            <p>
              Total:
              <span class="total_price"
                >${{ parseFloat(quote_grand_total).toFixed(2) }}</span
              >
            </p>
            <strong v-if="showMinPriceWarning"
              >The minimum order price = ${{ minPrice }}</strong
            >
          </div>
          <button
            type="button"
            @click="order"
            :disabled="parseFloat(quote_grand_total) < minPrice"
          >
          submit your inquiry
          </button>
        </div>
      </div>
    </div>

    <!--overlay-->
    <div class="overlay" id="overlay" v-if="showOverlay"></div>
    <!--添加地址-->
    <div class="popup_add_address" id="popup_add_address" v-if="showAddAddress">
      <p class="title"><strong>Add Delivery Address</strong></p>
      <form @submit.prevent="submitNewAddress">
        <div class="row">
          <div class="left">
            <div class="field">
              <label for="name">Name <span>*</span></label>
              <input type="text" name="name" v-model.trim="newAddress.name" />
            </div>
          </div>
          <div class="right">
            <div class="field">
              <label for="address">Address <span>*</span></label>
              <input
                type="text"
                name="address"
                v-model.trim="newAddress.address"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="left">
            <div class="field field_left">
              <label for="postcode">Postcode <span>*</span></label>
              <input
                type="number"
                name="postcode"
                v-model.trim="newAddress.postcode"
              />
            </div>
            <div class="field field_right">
              <label for="city">City <span>*</span></label>
              <input type="text" name="city" v-model.trim="newAddress.city" />
            </div>
          </div>
          <div class="right">
            <div class="field">
              <label for="country">Country <span>*</span></label>
              <input
                type="text"
                name="country"
                v-model.trim="newAddress.country"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="left">
            <div class="field">
              <label for="phone">Phone <span>*</span></label>
              <input type="tel" name="phone" v-model.trim="newAddress.phone" />
            </div>
          </div>
          <div class="right">
            <div class="field">
              <label for="email">E-mail <span>*</span></label>
              <input
                type="email"
                name="email"
                v-model.trim="newAddress.email"
              />
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="button">
            <div class="button_cancellation">
              <button id="cancellation" @click="cancelAddAddress">
                Cancel
              </button>
            </div>
            <div class="button_confirm">
              <button id="confirm" type="submit" @click="confirmAddressSelection">Confirm</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--选择地址-->
    <div
      class="popup_select_address"
      id="popup_select_address"
      v-if="showSelectAddress"
    >
      <div class="address_header">
        <h3><strong>Shipping address</strong></h3>
        <button @click="addAddress">Add Address</button>
      </div>
      <div class="address_list">
        <table>
          <tr v-for="(address, index) in addressList" :key="index" class="item">
            <td>
              <input
                type="radio"
                @change="toggleAddressSelection(index)"
                :checked="selectedAddresses === index"
              />
            </td>
            <td>
              <span>{{ address.name }}</span
              >,<span>{{ address.phone }}</span>
            </td>
            <td>
              <span>{{ address.address }},</span><span>{{ address.city }},</span
              ><span> {{ address.country }} </span
              ><span>Postal Code: {{ address.postcode }}</span>
            </td>
          </tr>
        </table>
        <div class="footer">
          <div class="button">
            <div class="button_cancellation">
              <button
                id="address_list_cancellation"
                @click="cancelSelectAddress"
              >
                Cancel
              </button>
            </div>
            <div class="button_confirm">
              <button
                id="address_list_confirm"
                @click="confirmAddressSelection"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog class="login-dialog" :visible.sync="loginVisible">
      <p>Please sign in before selecting an address and placing an order.</p>
      <p>Don't worry, your shopping cart data will not be lost.</p>
      <div class="login-footer">
        <div class="login-button">
          <el-button type="login" @click="clickLogin">Login</el-button>
          <el-button type="cancel" @click="loginVisible = false"
            >Cancel</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, postForm } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
export default {
  name: "CartPage",
  data() {
    return {
      quote_items: [],
      addToCartHistory: [],
      cartList: [],
      quote_grand_total: 0,
      selectAll: false, // 全选状态
      showOverlay: false,
      showAddAddress: false,
      showSelectAddress: false,
      addressList: [],
      selectedAddresses: [], // 用于存储用户选中的地址索引
      selectedAddressInfo: "", // 定义选中地址信息字符串
      newAddress: {
        // 新地址对象
        name: "",
        postcode: "",
        country: "",
        city: "",
        address: "",
        phone: "",
        email: "",
        is_default: 0,
      },
      selectedAddressId: null,
      myCartId: null,
      minPrice: "", // 存储后台返回的最低订单价格
      showMinPriceWarning: false, // 控制是否显示最低订单价格警告
      loginVisible: false,
      remark: "",
      count: "",
      fileList: [],
      uploadFile: []
    };
  },
  mounted() {
    // 进行异步请求
    if (isLogin()) {
      this.myCart();
    } else {
      this.getLocalCart();
    }
  },
  methods: {
    async getLocalCart() {
      this.quote_items = [];
      this.quote_grand_total = 0;

      if (localStorage.getItem("cartList")) {
        this.cartList = JSON.parse(localStorage.getItem("cartList"));
        this.cartList.forEach((item) => {
          let element = {
            base_currency: "CNY",
            base_currency_code: "¥",
            base_row_single: item.base_row_single,
            base_row_total: item.base_row_single * item.qty,
            created_at: "",
            exchange_rate: "0.1000",
            inputValue: "",
            isSelected: false,
            options: "",
            options_trans: "",
            originalValue: "",
            placeholder: null,
            product_id: item.product_id,
            product_image: item.product_image,
            product_name: item.product_name,
            product_name_trans: item.product_name_trans,
            qty: item.qty,
            quote_currency: "USD",
            quote_currency_code: "$",
            quote_row_single: item.quote_row_single,
            quote_row_total: item.quote_row_single * item.qty,
            quote_type: item.quote_type,
            remarks: null,
            sellerOpenId: null,
            sellerShopName: null,
            shipping_info: null,
            sku_image: item.sku_image,
            specId: item.spec_id,
            updated_at: "",
          };
          if (item.options !== undefined) {
            let options = JSON.parse(item.options);
            for (var key in options) {
              element.options_trans = options[key].trans;
            }
          }
          if (item.price_range_list !== undefined) {
            element.price_range_list = JSON.parse(item.price_range_list);
          }
          this.quote_grand_total += element.quote_row_total;
          this.quote_items.push(element);
          this.count = this.cartList.length;
        });
      }
    },
    async myCart() {
      this.quote_items = [];
      this.quote_grand_total = 0;
      get("/cart").then((res) => {
        if (res.code === 1) {
          if (res.data.items) {
            res.data.items.forEach((item) => {
              this.quote_items.push({
                id: item.id,
                quote_id: item.quote_id,
                base_currency: item.base_currency,
                base_currency_code: item.base_currency_code,
                base_row_single: item.base_row_single,
                base_row_total: item.base_row_single * item.qty,
                created_at: item.created_at,
                exchange_rate: item.exchange_rate,
                inputValue: "",
                isSelected: false,
                options: "",
                options_trans: JSON.parse(item.options_trans),
                originalValue: "",
                placeholder: "",
                price_range_list: JSON.parse(item.price_range_list),
                product_id: item.product_id,
                product_image: item.product_image,
                product_name: item.product_name,
                product_name_trans: item.product_name_trans,
                qty: item.qty,
                quote_currency: "USD",
                quote_currency_code: "$",
                quote_row_single: item.quote_row_single,
                quote_row_total: item.quote_row_single * item.qty,
                quote_type: item.quote_type,
                remarks: "",
                sellerOpenId: item.sellerOpenId,
                sellerShopName: item.sellerShopName,
                shipping_info: item.shipping_info,
                sku_image: item.sku_image || item.product_image,
                specId: item.spec_id,
                updated_at: "",
              });
              this.quote_grand_total += parseFloat(item.quote_row_total);
              this.myCartId = res.data.id;
              this.count = res.data.count;
            })
          }
        } else {
          Message.error({
            message: res.msg,
            offset: 300,
          });
        }
      })
    },

    // handleItemSelect(index) {
    //   // 处理单个项目的选中状态改变
    //   if (!this.quote_items[index].isSelected) {
    //     this.selectAll = false; // 如果有一个项目未被选中，则取消全选状态
    //   }
    // },

    // handleSelectAll() {
    //   // 处理全选状态改变
    //   this.quote_items.forEach(
    //     (quote_item) => (quote_item.isSelected = this.selectAll)
    //   );
    // },

    // deleteSelectedItems() {
    //   // 复制选中的项目列表
    //   const selectedItems = this.quote_items
    //       .filter((quote_item) => quote_item.isSelected)
    //       .slice(); // 使用 slice() 方法复制数组
    //   // 遍历复制的选中项目列表，并删除原始列表中对应的项目
    //   selectedItems.forEach((quote_item) => this.removeItem(quote_item));
    //   this.selectAll = false; // 删除后取消全选状态
    // },
    // 删除购物车内容
    async removeItem(quote_item) {
      if (isLogin()) {
        post("/remove_item", {
          cart_id: quote_item.quote_id,
          items: "[" + quote_item.id + "]",
        })
          .then((res) => {
            if (res.code === 1) {
              Message.success({
                message: "Delete complete",
                offset: 300,
              });
              const index = this.quote_items.findIndex((item) => item === quote_item);
              this.quote_items.splice(index, 1);
            } else {
              Message.error({
                message: res.msg,
                offset: 300,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        for (let i = 0; i < this.cartList.length; i++) {
          if (this.cartList[i].product_id === quote_item.product_id) {
            this.cartList.splice(i, 1);
          }
        }
        localStorage.setItem("cartList", JSON.stringify(this.cartList));
        Message.success({
          message: "Delete complete",
          offset: 300,
        });
        await this.getLocalCart();
      }
    },
    handleQtyChange(quote_item) {
      if (isLogin()) {
        post("/change_item", {
          cart_id: quote_item.quote_id,
          item_id: quote_item.id,
          qty: quote_item.qty,
        }).then((res) => {
          if (res.code === 1) {
            this.myCart();
          }
        });
      } else {
        this.quote_grand_total = 0;
        if (quote_item.quote_type === 2) {
          let amount = 0,
            price = 0;
          this.cartList.forEach((item) => {
            if (item.spec_id === quote_item.specId) {
              item.qty = quote_item.qty;
            }
            if (item.product_id === quote_item.product_id) {
              amount += item.qty;
            }
          });
          for (let i = 0; i < quote_item.price_range_list.length; i++) {
            if (amount > quote_item.price_range_list[i].startQuantity) {
              price = quote_item.price_range_list[i].price;
            }
          }
          this.cartList.forEach((item) => {
            if (item.product_id === quote_item.product_id) {
              item.quote_row_single = price;
              item.quote_grand_total = price * item.qty;
            }
          });
        } else {
          this.cartList.forEach((item) => {
            if (item.spec_id === quote_item.specId) {
              item.qty = quote_item.qty;
              item.quote_grand_total = quote_item.qty * item.quote_row_single;
            }
          });
        }

        localStorage.setItem("cartList", JSON.stringify(this.cartList));
        this.getLocalCart();
      }
    },
    async selectAddress() {
      if (isLogin()) {
        if (!this.quote_items || this.quote_items.length === 0) {
          Message.warning({
            message: "No items in the cart.",
            offset: 300,
          });
          return;
        }
        post("/address/list", {
          beginPage: `1`,
          pageSize: `100`,
        })
          .then((res) => {
            if (res.code === 1) {
              this.showOverlay = true;
              this.showAddAddress = false;
              this.showSelectAddress = true;
              this.addressList = res.data.list;
              // if (res.data.list.length === 0) {
              //   this.showOverlay = true;
              //   this.showAddAddress = true;
              //   this.showSelectAddress = false;
              // } else {
              //   this.showOverlay = true;
              //   this.showAddAddress = false;
              //   this.showSelectAddress = true;
              //   this.addressList = res.data.list;
              // }
            } else {
              Message.error({
                message: res.msg,
                offset: 300,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.loginVisible = true;
      }
    },
    // confirmdefaultAddressSelection() {
    //     // 如果没有选中地址，则将第一条地址信息设置为默认值
    //     if (this.addressList.length == 0) {
    //       const firstAddress = this.addressList[0];
    //       this.selectedAddressInfo = `${firstAddress.id}, ${firstAddress.name}, ${firstAddress.phone}, ${firstAddress.address}, ${firstAddress.city}, ${firstAddress.country}, Postal Code: ${firstAddress.postcode}`;
    //       this.selectedAddressId = `${firstAddress.id}`;

    //     console.log(this.addressList.length)
    //     console.log(this.selectedAddressInfo)
    //   this.showOverlay = true;
    //   this.showSelectAddress = false;
    // },
    confirmAddressSelection() {
      // 检查是否有选中的单选按钮
      const selectedAddressIndex = this.selectedAddresses;
      if (
        selectedAddressIndex !== null &&
        selectedAddressIndex !== undefined &&
        this.addressList[selectedAddressIndex]
      ) {
        // 获取选中的地址信息
        const selectedAddress = this.addressList[selectedAddressIndex];
        // 将地址信息更新到 selectedAddressInfo
        this.selectedAddressInfo = `${selectedAddress.id}, ${selectedAddress.name}, ${selectedAddress.phone}, ${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.country}, Postal Code: ${selectedAddress.postcode}`;
        this.selectedAddressId = `${selectedAddress.id}`;
      } else {
        // 如果没有选中地址，并且地址列表为空，则显示第一次填写的地址
        if (!this.selectedAddressInfo && this.addressList.length === 0) {
          // 如果是第一次填写地址，则使用新地址信息
          this.selectedAddressInfo = `${this.newAddress.name}, ${this.newAddress.phone}, ${this.newAddress.address}, ${this.newAddress.city}, ${this.newAddress.country}, Postal Code: ${this.newAddress.postcode}`;
          this.selectedAddressId = `${this.newAddress.id}`;
        }
      }
      this.showOverlay = false;
      this.showSelectAddress = false;
    },
    toggleAddressSelection(index) {
      this.selectedAddresses = index;
    },
    cancelSelectAddress() {
      this.showOverlay = false;
      this.showSelectAddress = false;
    },
    adjustAddress() {
      if (isLogin()) {
        if (!this.quote_items || this.quote_items.length === 0) {
          Message.warning({
            message: "No items in the cart.",
            offset: 300,
          });
          return;
        }
        this.showOverlay = true;
        this.showSelectAddress = true;
      } else {
        this.loginVisible = true;
      }
    },
    addAddress() {
      if (isLogin()) {
        if (!this.quote_items || this.quote_items.length === 0) {
          Message.warning({
            message: "No items in the cart.",
            offset: 300,
          });
          return;
        }
        this.showOverlay = true;
        this.showAddAddress = true;
        this.showSelectAddress = false;
      } else {
        this.loginVisible = true;
      }
    },
    async submitNewAddress() {
      if (
        !this.newAddress.name ||
        !this.newAddress.postcode ||
        !this.newAddress.country ||
        !this.newAddress.city ||
        !this.newAddress.address ||
        !this.newAddress.phone ||
        !this.newAddress.email
      ) {
        Message.error({
          message: "Please fill in all required fields.",
          offset: 300,
        });
        return;
      }
      post("/address/store", {
        name: this.newAddress.name,
        postcode: this.newAddress.postcode,
        country: this.newAddress.country,
        city: this.newAddress.city,
        address: this.newAddress.address,
        phone: this.newAddress.phone,
        email: this.newAddress.email,
        is_default: this.newAddress.is_default,
      })
        .then((res) => {
          if (res.code === 1) {
            this.addressList.push({ ...this.newAddress });

            // 清空新增地址表单中的数据
            this.newAddress = {
              name: "",
              postcode: "",
              country: "",
              city: "",
              address: "",
              phone: "",
              email: "",
              is_default: 0,
            };

            // 关闭新增地址表单的显示
            this.showOverlay = false;
            this.showAddAddress = false;
          } else {
            Message.error({
              message: res.msg,
              offset: 300,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelAddAddress() {
      this.showOverlay = false;
      this.showAddAddress = false;
    },
    async order() {
      const selectedItemsIds = [];
      this.quote_items.forEach((item) => {
        if (item.isSelected) {
          selectedItemsIds.push(item.id);
        }
      });
      if (isLogin()) {
        postForm("/order", {
          cart_id: this.myCartId,
          address_id: this.selectedAddressId,
          items: JSON.stringify(selectedItemsIds),
          remark: this.remark,
          uploadFile: this.uploadFile
        }).then (res => {
          if (res.code === 1) {
            this.$router.push("/thank-you");
          } else {
            if (parseFloat(this.quote_grand_total) < this.minPrice) {
              this.showMinPriceWarning = true;
            } else {
              this.showMinPriceWarning = false;
            }
            get("/min_price", {
              min_price: this.minPrice,
            }).then((res) => {
              if (res.code === 1) {
                this.minPrice = res.data.min_price;
                if (parseFloat(this.quote_grand_total) < this.minPrice) {
                  this.showMinPriceWarning = true;
                } else {
                  this.showMinPriceWarning = false;
                }
              } else {
                Message.error({
                  message: res.msg,
                  offset: 300,
                });
              }
            }).catch((err) => {
              console.log(err);
            });
            Message.error({
              message: res.msg,
              offset: 300,
            });
          }
        })
      } else {
        this.loginVisible = true;
      }
    },
    clickLogin() {
      localStorage.setItem("linkDump", this.$route.path);
      window.location.href = "/login";
    },
    getDynamicUrl(quote_item) {
      return `/product-details/?product_id=${quote_item.product_id}`;
    },
    beforeFileUpload(file){
      let fileType = true;
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.ms-excel') {
        fileType = true;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        Message.error({
          message: "files size limit is 5MB!",
          offset: 300
        });
      }
      return fileType & isLt5M;
    },
    uploadChange(file, fileList) {
      this.uploadFile = [];
      for (let i = 0; i < fileList.length; i++) {
        this.uploadFile[i] = fileList[i].raw;
      }
    },
    handleRemove(file, fileList) {
      this.uploadFile = [];
      for (let i = 0; i < fileList.length; i++) {
        this.uploadFile[i] = fileList[i].raw;
      }
    },
    handleExceed() {
      Message.warning({
        message: "Current limit Select 5 file!",
        offset: 300
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
