<template>
  <div>
    <el-card shadow="never">
      <div class="tabs">
        <el-tabs v-model="activeTab" @tab-click="handleTabChange">
          <el-tab-pane label="View all" name="-1"/>
          <el-tab-pane label="Sample Requested" name="0"/>
          <el-tab-pane label="In Preparation" name="1"/>
          <el-tab-pane label="Sample Dispatched" name="2"/>
        </el-tabs>
      </div>
    </el-card>
    <el-card class="sample-content" shadow="never">
<!--      <div v-if="amount > 0">-->
<!--        <div class="record-element" v-for="(item, index) in records" :key="index">-->
<!--          <img :src="item.product_image"/>-->
<!--          <div class="record-title">-->
<!--            <span>{{ item.product_name }}</span>-->
<!--            <span>Request time: {{item.time}}</span>-->
<!--            <span>Status: {{item.status}}</span>-->
<!--          </div>-->
<!--          <el-divider/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-else class="empty">-->
<!--        <span style="color: #696969"><b-icon icon="inbox"/>Empty</span>-->
<!--      </div>-->
      <div class="sample-table">
        <el-table
            :data="records"
            empty-text="Empty"
            v-loading="loading">
          <el-table-column label="Sort" align="center" type="index">
            <template #default="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Pictures" width="150px" align="center">
            <template #default="scope">
              <el-popover placement="top-start" title="" trigger="hover">
                <img :src="scope.row.product_image" alt="" width="250px" height="250px">
                <img slot="reference" :src="scope.row.product_image" width="120px" height="120px">
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="Application time" prop="time" align="center"/>
          <el-table-column label="Sample status" prop="status" align="center"/>
<!--          <el-table-column label="Commodity" align="center">-->
<!--            <template #default="scope">-->
<!--              <el-button type="text" @click="viewOrderDetail(scope)">view<b-icon icon="chevron-right"/></el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { post } from "@/utils/request";
const map = {
  0: "Sample Requested",
  1: "In Preparation",
  2: "Sample Dispatched"
}

export default {
  name: 'SampleRequestPanel',
  data () {
    return {
      activeTab: "-1",
      amount: 0,
      records: [],
      loading: false
    }
  },
  created() {
    this.getRecords();
  },
  methods: {
    async getRecords() {
      this.loading = true;
      this.records = [];
      let data;
      if (this.activeTab === "-1") {
        data = {};
      } else {
        data = { status: this.activeTab };
      }
      post("/my_sample_list", data).then(res => {
        if (res.code === 1) {
          res.data.forEach(item => {
            this.records.push({
              product_image: item.product_image,
              product_name: item.product_name,
              time: item.created_at,
              status: map[item.status]
            })
          })
          this.amount = this.records.length;
          this.loading = false;
        } else {
          console.log(res.msg);
        }
      })
    },
    handleTabChange() {
      this.getRecords();
    },
    // handleRowClick(row) {
    //   console.log(row);
    // }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
