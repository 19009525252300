<template>
  <div>
    <el-card class="favourite-head" shadow="never">
      <h3>Wish list({{ amount }})</h3>
    </el-card>
    <el-card class="favourite-content" shadow="never" v-loading="loading">
      <div v-if="amount > 0">
        <div v-for="(item, index) in collectList" :key="index">
          <div class="content-container">
            <div class="content-text">
              <img :src="item.product_image" @click="viewProductDetail(item)"/>
              <div class="content-title">
                <span class="content-word" @click="viewProductDetail(item)">{{ item.product_name }}</span>
                <span class="content-price">${{ item.product_price }}</span>
              </div>
            </div>
            <div class="content-button">
              <el-button type="moveCart" @click="getProductDetail(item)">Add to cart</el-button>
              <el-button type="favouriteDelete" @click="removeCollect(item)">Remove</el-button>
            </div>
          </div>
          <el-divider/>
        </div>
      </div>
      <div v-else class="empty">
        <span><b-icon icon="inbox"/>Empty</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@/utils/request";
import {Message} from "element-ui";
export default {
  name: 'FavouritePanel',
  data () {
    return {
      amount: 0,
      collectList: [],
      product: {},
      loading: false
    }
  },
  created() {
    this.getCollectList();
  },
  methods: {
    async getCollectList() {
      this.loading = true;
      get("/my_collect").then(res => {
        if (res.code === 1) {
          this.amount = res.data.gather.length;
          this.collectList = res.data.items;
          this.loading = false;
        }
      })
    },
    async getProductDetail(item) {
      post("/product_detail", {
        ip: "127.0.0.1",
        product_id: item.source_id,
        country: "en"
      }).then(res => {
        if (res.code === 1) {
          this.product = {
            ip: "127.0.0.1",
            product_name: res.data.subject,
            product_name_trans: res.data.subjectTrans,
            product_image: res.data.productImage.images[0],
            product_id: res.data.offerId,
            qty: res.data.minOrderQuantity,
            base_row_single: res.data.productSaleInfo.priceRangeList[0].price,
            quote_row_single: res.data.productSaleInfo.transPriceRangeList[0].price
          }
          this.moveToCart();
        } else {
          console.log(res.msg);
        }
      })
    },
    async moveToCart() {
      post("/add_final_to_cart", this.product).then(res => {
        if (res.code === 1) {
          Message.success("Added to the cart");
        } else {
          console.log(res.msg);
        }
      })
    },
    removeCollect(item) {
      post("/remove_collect", {
        source_id: "[" + item.source_id + "]"
      }).then(res => {
        if (res.code === 1) {
          Message.success("Favourite removed");
          this.getCollectList();
        } else {
          console.log(res.msg);
        }
      })
    },
    viewProductDetail(item) {
      if (item.source_id) {
        window.location.href = `/product-details/?product_id=${item.source_id}`;
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
