<template>
  <div class="aboutus">
    <div class="section1" id="whoweare">
      <div class="content">
        <div class="left">
          <h3>Who is <span>ChinaBulkBuy</span></h3>
          <div class="text">
            <p><span>ChinaBulkBuy</span> is a one-stop wholesale purchasing platform for daily household goods, with
              over 200 million products sourced from over 50,000 factories and suppliers in China, covering Apparel,
              Fashion Accessories, Pets & Garden, Beauty & Health, Toys & Hobbies, Kitchen & Tabletop, Sports &
              Entertainment, and Gifts & Crafts, among almost all daily goods.</p>
            <p>ChinaBulkBuy is dedicated to providing product procurement and sourcing from China to global businesses,
              especially providing end-to-end services for small and medium-sized enterprises (wholesalers,
              retailers, subscription businesses, online sellers, etc.), reducing their purchasing costs by 10-50%.</p>
            <p>With over 2,000 professional product development and procurement staff, we now provide high-quality
              products and services to more than 5,000 customers from 80 countries. We sincerely hope to support the
              growth of your business.</p>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/images/about-whoweare.png"/>
        </div>
      </div>
      <a href="/request-for-quotation">Get A Quick Quote</a>
      <!-- <button @click="showInquiryPopup()">Get A Quick Quote</button> -->
    </div>
    <div class="section mission_vision_content">
      <div class="mission_vision">
        <div class="mission">
          <h3>Mission</h3>
          <p>Providing excellent supply chain service for clients worldwide.</p>
        </div>
        <div class="vision">
          <h3>Vision</h3>
          <p>Create the supply chain benchmarking services, jointly build a humanistic and harmonious enterprise.</p>
        </div>
      </div>
    </div>
    <!--    for-business  start-->
    <div class="section image-left for-business" id="whatwedo">
      <h1 class="for-business-title">What does ChinaBulkBuy do for businesses?</h1>
      <div class="content">
        <div class="img">
          <div class="for-business-left-img-2">
            <img src="@/assets/images/about-us/for_business_2.png" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Trending Product Recommendations</h3>
            <p>Our product managers conduct regular analysis and collection of the top-selling products in different
              markets and the trending products from the designers.</p>
            <p><strong class="website">ChinaBulkBuy</strong>recommends these products to you through
              our website's dedicated section and analysis report emails, allowing you to stay informed and make
              purchasing decisions promptly.</p>
          </div>
        </div>
      </div>
      <div class="embellishment for-business-1">
        <img src="@/assets/images/about-us/for_business_1.png" alt="" loading="lazy">
      </div>
      <div class="embellishment for-business-2">
        <img src="@/assets/images/about-us/about-circle-04.png" alt="" loading="lazy">
      </div>
    </div>
    <!--    for-business  end-->

    <!--    china product support  start-->
    <div class="section image-right chinese-product-supply">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="for-business-left-img-2">
            <img src="@/assets/images/about-us/chinese_product_supply.png" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Chinese Product Supply</h3>
            <p>You can browse <strong class="website">ChinaBulkBuy.com</strong> to select your desired products based on
              reference prices and product descriptions. Simply submit the required quantity.</p>
            <p>If you cannot find the product you are looking for, feel free to submit your inquiry directly.</p>
            <p>We will provide you with accurate product quotations and delivery options within 24 hours.</p>
          </div>
        </div>
      </div>
    </div>
    <!--    china product support  end-->

    <!--    china sourcing agent  start-->
    <div class="section image-left china-sourcing-agent">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="china-sourcing-agent-left-img-2">
            <img src="@/assets/images/about-us/china-sourcing-agent.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">China Sourcing Agent</h3>
            <p>If you have a specific list of products to purchase and supplier <strong
                class="website">ChinaBulkBuy</strong> also offer sourcing agent services in major wholesale markets and
              industrial factories in China, such as Yiwu agent, Shenzhen agent, Guangzhou agent, Chaozhou agent, etc.
            </p>
            <p>Leveraging our business resources, we provide one-stop procurementagent services including order
              placement, order tracking, qualityinspection, warehousing, shipping, and financial services.</p>
          </div>
        </div>
      </div>
      <div class="embellishment china-sourcing-agent-left">
        <img src="@/assets/images/about-us/china-sourcing-agent-left.png" alt="" loading="lazy">
      </div>
      <div class="embellishment china-sourcing-agent-right">
        <img src="@/assets/images/about-us/china-sourcing-agent-right.png" alt="" loading="lazy">
      </div>
    </div>
    <!--    china sourcing agent  end-->

    <!--    Varied Logistics Options  start-->
    <div class="section image-right varied-logistics-options">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="varied-Logistics-Options-left-img-2">
            <img src="@/assets/images/about-us/varied_logistocs_options_main.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Varied Logistics Options</h3>
            <p><strong class="website">ChinaBulkBuy</strong> provides a range of transportation methods, including sea,
              air, and rail freight, supporting both full container (FCL) and less than container(LCL) shipments.</p>
            <p>In addition to FOB and CIF terms, we can tailor optimal door-to-door solutions to meet the time and cost
              needs of small and medium-sized enterprises, enabling hassle-free procurement from China without prior
              import experience.</p>
          </div>
        </div>
      </div>
    </div>
    <!--    Varied Logistics Options  end-->

    <!--    Consolidation And Warehousing Services  start-->
    <div class="section image-left consolidation-warehousing-services">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="consolidation-warehousing-services-left-img-2">
            <img src="@/assets/images/about-us/consolidation-and-warehousing-services.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Consolidation And Warehousing Services</h3>
            <p><strong class="website">ChinaBulkBuy</strong> has over <strong class="website">100,000</strong> square
              meters of warehouse space in Ningbo/Yiwu to assist customers in purchasing, collecting their products, and
              providing packaging and transportation services.</p>
          </div>
        </div>
      </div>
      <div class="embellishment consolidation-warehousing-services-left">
        <img src="@/assets/images/about-us/for_business_1.png" alt="" loading="lazy">
      </div>
    </div>
    <!--    Consolidation And Warehousing Services  end-->

    <!--    Flexible Financial Services  start-->
    <div class="section image-right flexible-financial-services">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="flexible-financial-services-left-img-2">
            <img src="@/assets/images/about-us/flexible-financial-services.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Flexible Financial Services</h3>
            <p>For eligible long-term clients, <strong class="website">ChinaBulkBuy</strong> offers <strong
                class="website">30-90</strong> days of credil or freight financing services under the premise of credit
              insurance, helping maximize their capital efficiency.</p>
          </div>
        </div>
      </div>
      <div class="embellishment flexible-financial-services-right">
        <img src="@/assets/images/about-us/about-circle-04.png" alt="" loading="lazy">
      </div>
    </div>
    <!--    Flexible Financial Services  end-->

    <!--    Product Display And Selection Services  start-->
    <div class="section image-left product-selection-services">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="product-selection-services-left-img-2">
            <img src="@/assets/images/about-us/product-display-and-selection-services.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Product Display And Selection Services</h3>
            <p><strong class="website">ChinaBulkBuy</strong> has established sample rooms covering over <strong
                class="website">10,000</strong> square meters in Ningbo/Yiwu/Guangzhou/Hangzhou, where we have collected
              over <strong class="website">200,000</strong> samples for convenient selection by visiting customers.</p>
            <p>We are also in the process of setting up our showrooms in Vietnam/Mexico and other locations.</p>
          </div>
        </div>
      </div>
      <div class="embellishment product-selection-services-left">
        <img src="@/assets/images/about-us/about-ellipse-04.png" alt="" loading="lazy">
      </div>
    </div>
    <!--    Product Display And Selection Services  end-->

    <!--    Online Marketing Services  start-->
    <div class="section image-right online-marketing-services">
      <h1></h1>
      <div class="content">
        <div class="img">
          <div class="online-marketing-services-left-img-2">
            <img src="@/assets/images/about-us/online-marketing-services.webp" alt="" loading="lazy">
          </div>
        </div>
        <div class="text">
          <div class="text-content">
            <h3 class="title">Online Marketing Services</h3>
            <p>In the era of internet, online marketing has become crucial for business sales. For client lacking online
              marketing capabilities, our IT team can provide website and app design and development services, as well
              as <strong class="website">SEO/SEM</strong> marketing and promotion services to help them grow their
              sales.</p>
          </div>
        </div>
      </div>
    </div>
    <!--    Online Marketing Services  end-->

    <div class="market">
      <div class="market-content">
        <div class="market-title">
          <h3>The cooperating manufacturers and wholesale markets by Chinabulkbuy</h3>
          <p>Through 20+ years of effort, <strong class="website">ChinaBulkBuy</strong> has established deep cooperative
            relationships with manufacturers and wholesalers from over <strong class="website">100</strong>
            manufacturing bases and wholesale markets across China. We have set up our procurement and service offices
            in multiple cities, enabling us to quickly respond to customer procurement needs.</p>
        </div>
        <div class="manufacturing-hubs">
          <div class="manufacturing-hubs-item">
            <div class="title left">China's key manufacturing hubs</div>
            <div class="desc">
              <ul>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Zibo, Shandong - Glass and Ceramic Products</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Qixian, Shanxi - Glass and Ceramic Products</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Suzhou, Jiangsu - Textiles and Apparel</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Qixian, Shanxi - Glass and Ceramic Products</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Suzhou, Jiangsu - Textiles and Apparel</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Foshan, Guangdong - Furniture and Home Decor</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Dongguan, Guangdong - Electronics and Technology</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Xiamen, Fujian - Footwear and Sportswear</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Ningbo, Zhejiang - Machinery and Equipment</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Wenzhou, Zhejiang - Eyewear and Accessories</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Yiwu, Zhejiang - Small Commodities and Gifts</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Changzhou, Jiangsu - Machinery and Tools</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Qingdao, Shandong - Shipbuilding and Marine Equipment</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Jinan, Shandong - Machinery and Heavy Equipment</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Xian, Shaanxi - Aerospace and Defense Technology</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Chengdu, Sichuan - Automotive Parts and Components</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Chongqing - Machinery and Industrial Equipment</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Tianjin - Chemicals and Petrochemicals</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Beijing - Pharmaceuticals and Biotechnology</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Hangzhou, Zhejiang - Home Appliances and Electronics</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Guangzhou, Guangdong - Automotive and Motorcycle Parts</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Dalian, Liaoning - Shipbuilding and Marine Engineering</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Zhuhai, Guangdong - Electronics and Semiconductor Manufacturing</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Taizhou, Zhejiang - Plastic Products and Molding</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Yangzhou, Jiangsu - Solar Energy Equipment and Components</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Jilin City, Jilin - Automotive Parts and Components</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Huizhou, Guangdong - LED Lighting and Electronics</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Datong, Shanxi - Coal Mining Equipment and Machinery</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Lanzhou, Gansu - Petrochemicals and Refining</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Huzhou, Zhejiang - Textile Dyeing and Finishing</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="manufacturing-hubs-item">
            <div class="title right">China's key manufacturing hubs</div>
            <div class="desc">
              <ul>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Yiwu International Trade City</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Guangzhou Baima Clothing Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Shenzhen SEG Electronics Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Suzhou Silk Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Wenzhou Glasses City</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Yantai Seafood Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Foshan Furniture Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Xian Antique Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Lanzhou Agricultural Products Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Hohhot Cashmere Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Nanning Tea Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Jinan Flower and Plant Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Changchun Automobile Parts Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Hefei Agricultural Products Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Kunming Flower and Bird Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Urumqi International Bazaar</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Xining Tibetan Handicraft Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Guiyang Tea Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Taiyuan Coal and Mining Equipment Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Nanchang Jewelry Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Changzhou Toy Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Shijiazhuang Leather Goods Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Xuzhou Building Materials Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Quanzhou Garment Wholesale Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Shantou Toy City</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Jinan Agricultural Machinery Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Nantong Textile Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Jinhua Hardware and Tools Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Taizhou Plastic Products Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Huzhou Silk and Fabric Market</span>
                </li>
                <li><span><img src="@/assets/images/about-us/supply.svg" alt="" loading="lazy" width="18"></span><span>Zhuhai Electronics Wholesale Market</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="content">
        <h3>Get products tailored to your needs</h3>
        <p>Provide quotation and delivery solution within 24 hours</p>
        <a class="contact-us" href="/request-for-quotation" target="_blank">
          <div class="left">Fill in your sourcing requirements</div>
          <div><span>or</span></div>
          <div class="right">+ Upload sourcing documents</div>
        </a>
        <a href="/request-for-quotation" target="_blank">
          <div class="quote">
            <span>Get A Quick Quote</span>
          </div>
        </a>

      </div>
    </div>


    <el-dialog
        title="Leave Us Message"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="aboutusForm" status-icon :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Name" prop="name">
              <el-input v-model="aboutusForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Email" prop="email">
              <el-input v-model="aboutusForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Company" prop="company">
              <el-input v-model="aboutusForm.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Phone" prop="phone">
              <el-input v-model="aboutusForm.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Have you ever imported from China?" prop="is_china">
          <el-radio-group v-model="aboutusForm.is_china">
            <el-radio label="1">Yes, i have</el-radio>
            <el-radio label="0">No, i haven't</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Message" prop="message">
          <el-input
              type="textarea"
              :rows="5"
              v-model="aboutusForm.message">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button type="quote" @click="submit">Get A Quote</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
// import {API_BASE_URL} from "@/api/apiConfig";
import {Message} from "element-ui";
import {post} from "@/utils/request";

export default {
  name: 'AboutUsPage',
  data() {
    return {
      dialogVisible: false,
      aboutusForm: {
        ip: '24.132.65.82',
        name: "",
        email: "",
        company: "",
        phone: "",
        is_china: "",
        message: ""
      },
      rules: {
        name: [
          {required: true, message: 'Please fill your name.', trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'Please fill your email.', trigger: 'blur'},
          {type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change']},
        ],
        company: [
          {required: true, message: 'Please fill your company.', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Please fill your phone.', trigger: 'blur'}
        ],
        is_china: [
          {required: true, message: 'Please select one option.', trigger: 'change'}
        ],
        message: [
          {required: true, message: 'Please fill your request.', max: 150, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    submit() {
      post("/add_inquiry", this.aboutusForm).then(res => {
        if (res.code === 1) {
          Message.success("Request Success");
          this.dialogVisible = false;
          this.aboutusForm = {};
        } else {
          Message.error("Request error: " + res.msg);
        }
      }).catch(err => {
        console.log(err);
      })
    },
    // showInquiryPopup() {
    //   this.aboutusForm = {};
    //   this.dialogVisible = true;
    // }
  }

}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
