import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/index.less'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// ElementUI
Vue.use(ElementUI);

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

//VueLazyComponent
import VueLazyComponent from '@xunlei/vue-lazy-component'
Vue.use(VueLazyComponent)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
