import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage'
import SearchPage from '../views/SearchPage'
import RegisterPage from '../views/RegisterPage'
import LoginPage from '../views/LoginPage'
import ResetPasswordPage from '../views/ResetPasswordPage'
import ProductDetailsPage from '../views/ProductDetailsPage'
import CartPage from '../views/CartPage'
import PersonalCenterPage from '../views/PersonalCenterPage'
import OrderDetailPage from '../views/OrderDetailPage'
import AboutUsPage from '@/views/AboutUsPage/index.vue'
import ContactUsPage from '@/views/ContactUsPage/index.vue';
import productCategoryPage from "@/views/ProductCategoryPage/index.vue";
import RequestForQuotation from "@/views/RequestForQuotation/index.vue";
import RecommendCategoryPage from "@/views/RecommendCategoryPage/index.vue";
import PrivacyPolicyPage from "@/views/PrivacyPolicyPage/index.vue";
import FAQPage from "@/views/FAQPage/index.vue";
import ThankYou from '@/views/ThankYou/index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPasswordPage,
  },
  {
    path: '/product-details/',
    name: 'product-details',
    component: ProductDetailsPage,
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage,
  },
  {
    path: '/account',
    name: 'account',
    component: PersonalCenterPage,
  },
  {
    path: '/order-detail',
    name: 'order-detail',
    component: OrderDetailPage,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsPage,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUsPage,
  },
  {
    path: '/product-category/:urlPath',
    name: 'product-category',
    component: productCategoryPage,
  },
  {
    path: '/request-for-quotation',
    name: 'request-for-quotation',
    component: RequestForQuotation,
  },
  {
    path: '/recommend-category/:urlPath',
    name: 'recommend-category',
    component: RecommendCategoryPage,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQPage,
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: ThankYou,
  },
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

export default router
