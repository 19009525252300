<template>
  <div>
    <el-drawer
        title=""
        :visible.sync="drawerVisible"
        :before-close="handleClose"
    >
      <div class="top">
        <h3><b-icon icon="check-circle" />Added to cart</h3>
        <a href="/cart">Go to cart</a>
        <a v-if="!login" href="/login">Sign in</a>
      </div>
      <div class="products">
        <h4>Items purchased together</h4>
        <div v-loading="loading">
          <div class="items" v-for="(item, index) in productList" :key="index">
            <div class="images">
              <a href="">
                <img :src="item.imageUrl" />
              </a>
              <div class="collect" @click="addToCollect(item)">
                <b-icon :icon="item.isCollect ? 'heart-fill' : 'heart'" />
              </div>
            </div>
            <div class="text">
              <a href="" title="5S 9-piece disassembly machine 6th ...5S"
              >{{ item.subjectTrans }}</a
              >
              <div class="d-flex justify-content-between">
                <span>${{ item.transPriceInfo.price }}</span>
                <button class="cart" :class="{ 'cart-checked': item.isCart }" @click="addRelateToCart(item)"><b-icon :icon="item.isCart ? 'check' : 'cart-plus'" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </div>
</template>

<script>
import { post } from "@/utils/request";
import { isLogin } from "@/utils/utils";
import { Message } from "element-ui";
import LoginDialog from "@/components/LoginDialog/index.vue";
// import { eventBus } from "@/common/eventBus";

export default {
  name: 'myDrawer',
  components: {LoginDialog},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      default: function () {}
    }
  },
  data() {
    return {
      login: false,
      drawerVisible: false,
      id: "",
      ip: "127.0.0.1",
      loading: false,
      relativeProductElement: {
        imageUrl: "",
        subject: "",
        subjectTrans: "",
        priceInfo: {},
        transPriceInfo: {},
        product_id: "",
        isCollect: false,
        isCart: false,
      },
      productList: [],
      product: {
        ip: "",
        product_name: "",
        product_name_trans: "",
        product_id: "",
        sku_image: "",
        // categoryId: "",
        qty: 1,
        base_row_single: 0,
        quote_row_single: 0
      },
      categoryId: "",
      collectList: [],
      cartList: [],
      loginDialog: false
    }
  },
  watch: {
    // 监听 改变
    isShow: {
      handler (val) {
        this.drawerVisible = val;
        if (this.drawerVisible) {
          this.main();
          // this.id = this.sid;
          // this.getProductDetail();
          // console.log(111);
        }
      },
      immediate: true
    }
  },
  methods: {
    main() {
      this.login = isLogin();
      if (localStorage.getItem("cartList")) {
        this.cartList = JSON.parse(localStorage.getItem("cartList"));
      }

      if (this.item.category_id) {
        this.categoryId = this.item.category_id;
        this.product = this.item;
        this.getRelatedProduct();
        this.addToCart();
      } else {
        this.id = this.item.product_id;
        this.getProductDetail();
      }
    },
    async getProductDetail() {
      this.loading = true;
      post("product_detail", {
        ip: "127.0.0.1",
        product_id: this.id,
        country: "en"
      }).then(res => {
        if (res.code === 1) {
          this.product = {
            ip: this.ip,
            product_name: res.data.subject,
            product_name_trans: res.data.subjectTrans,
            product_image: res.data.productImage.images[0],
            sku_image: res.data.productImage.images[0],
            product_id: res.data.offerId,
            qty: res.data.minOrderQuantity,
            base_row_single: res.data.productSaleInfo.priceRangeList[0].price,
            quote_row_single: res.data.productSaleInfo.transPriceRangeList[0].price
          }
          this.categoryId = res.data.categoryId;
          this.addToCart();
          this.getRelatedProduct();
        } else {
          console.log(res);
        }
      });
    },
    async getRelatedProduct() {
      this.loading = true;
      post("related_products", {
        ip: "127.0.0.1",
        category_id: this.categoryId
      }).then(res => {
        if (res.code === 1) {

          res.data.forEach(item => {
            this.relativeProductElement = {
              imageUrl: item.imageUrl,
              subject: item.subject,
              subjectTrans: item.subjectTrans,
              priceInfo: item.priceInfo,
              transPriceInfo: item.transPriceInfo,
              product_id: item.offerId,
              isCollect: false,
              isCart: false
            }

            // if (this.collectList !== null && this.collectList !== undefined) {
            //   this.collectList.forEach(m => {
            //     if (m.source_id === this.relativeProductElement.product_id) {
            //       this.relativeProductElement.isCollect = true;
            //     }
            //   })
            // }
            if (this.cartList !== null && this.cartList !== undefined) {
              this.cartList.forEach(n => {
                if (n.product_id === this.relativeProductElement.product_id) {
                  this.relativeProductElement.isCart = true;
                }
              })
            }

            this.productList.push(this.relativeProductElement);
          })
          this.loading = false;
        }
      }).catch(err => {
        console.log(err);
      })
    },
    async addToCart() {
      if (isLogin()) {
        post("/add_final_to_cart", this.product).then(res => {
          if (res.code !== 1) {
            Message.warning({
              message: res.msg,
              offset: 300
            });
          }
        })
      } else {
        let flag = false;
        this.cartList.forEach(item => {
          if (item.product_id.toString() === this.product.product_id.toString()) {
            item.qty += this.product.qty;
            flag = true;
          }
        });
        if (!flag) {
          this.cartList.push(this.product);
        }
        localStorage.setItem("cartList", JSON.stringify(this.cartList));
      }
    },
    addRelateToCart(item) {
      if (!item.isCart) {
        item.isCart = true;
        if (isLogin()) {
          post("/add_final_to_cart", {
            ip: "127.0.0.1",
            product_id: item.product_id,
            product_name: item.subject,
            product_name_trans: item.subjectTrans,
            product_image: item.imageUrl,
            qty: 1,
            base_row_single: item.priceInfo.price,
            quote_row_single: item.transPriceInfo.price
          }).then(res => {
            console.log(res);
          })
        } else {
          let cartElement = {
            ip: "127.0.0.1",
            product_id: item.product_id,
            product_name: item.subject,
            product_name_trans: item.subjectTrans,
            product_image: item.imageUrl,
            sku_image: item.imageUrl,
            qty: 1,
            base_row_single: item.priceInfo.price,
            quote_row_single: item.transPriceInfo.price
          }
          this.cartList.push(cartElement);
          localStorage.setItem("cartList", JSON.stringify(this.cartList));
        }
      }
    },
    addToCollect(item) {
      if (isLogin()) {
        if (item.isCollect) {
          //如果已收藏，则取消收藏
          item.isCollect = false;
          post("/remove_collect", {
            source_id: "[" + item.product_id + "]"
          }).then(res => {
            if (res.code === 1) {
              Message.success({
                message: "Remove success.",
                offset: 500
              });
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          item.isCollect = true;
          //未收藏，则添加收藏
          let image;
          if (item.imageUrl.startsWith("http")) {
            image = item.imageUrl;
          } else {
            image = "";
          }
          post("/add_collect", {
            source_id: item.product_id,
            product_name: item.subjectTrans,
            product_price: item.transPriceInfo.price,
            product_image: image,
            category: "product_detail_001" + this.categoryId
          }).then(res => {
            if (res.code === 1) {
              Message.success({
                message: "Collect success!",
                offset: 500
              });
            }
          })
        }
      } else {
        this.loginDialog = true;
      }
    },
    handleClose () {
      this.$emit('update:isShow', false);
      this.productList = [];
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
