<template>
  <div class="quote-content">
    <div class="quote">
      <div class="quote-cont">
        <el-row type="flex" :gutter="65" align="middle" justify="space-between">
          <el-col :span="14" class="form-contain" >
            <!-- <el-form :model="inquiryForm" status-icon :rules="rules" v-if="loggedIn"> -->
            <el-form :model="inquiryForm" status-icon :rules="rules" ref="inquiryForm" :class="{ 'opacity': !loggedIn }">
              <el-row>
                <h1>Start Your Customization Journey</h1>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Name" prop="name">
                    <el-input v-model="inquiryForm.name" @input="onInput()"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="inquiryForm.email" @input="onInput()"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Company" prop="company">
                    <el-input v-model="inquiryForm.company" @input="onInput()"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Phone" prop="phone">
                    <el-input v-model="inquiryForm.phone" @input="onInput()"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col>
                  <el-form-item label="Upload File" v-if="showFile">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :before-upload="beforeFileUpload"
                        :on-change="uploadChange"
                        :on-remove="handleRemove"
                        :file-list="inquiryForm.fileList"
                        :limit="5"
                        accept=".xlsx,.xls,.pdf,.txt,.doc,.docx,.png,.jpg,.jpeg "
                        :on-exceed="handleExceed"
                        :auto-upload="false"
                    >
                      <el-button size="small" type="primary">Upload</el-button>
                      <div slot="tip" class="el-upload__tip">Tips: Please upload any files( &lt; 5MB) related to your requirements, such as product design drawings/product lists/or others. This will help us understand and meet your needs more accurately.</div>
                    </el-upload>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col>
                  <el-form-item label="Message" prop="message">
                    <el-input
                        v-model="inquiryForm.message"
                        type="textarea"
                        placeholder="Please specify the type of product you are looking for, the quantity, and any special requirements."
                        :rows="6"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="privacy">
                <el-checkbox v-model="checked">consent to the processing of my data in accordance with the data protecfion</el-checkbox>
                <p><span>*</span>Privacy Assurance: Your privacy is of utmost importance to us. Rest assured, all information provided will be treated with the highest level of confidentiality.</p>
              </div>
              <div class="submit" @click="submit">
                <span>Submit Your Inquiry</span>
              </div>
            </el-form>
            <el-col class="login-button-content" v-if="!loggedIn">
              <el-button @click="linkDump" class="login-button">To submit an RFQ, please <a href="/login">log in </a>first.</el-button>
            </el-col>
          </el-col>
          <el-col :span="10" class="form-image">
            <img src="@/assets/images/quick-quote.png" width="700"/>
          </el-col>
        </el-row>
      </div>
<!--      <el-dialog-->
<!--          :visible.sync="popVisible"-->
<!--          width="30%">-->
<!--        <span>Please check our privacy policy</span>-->
<!--        <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="confirm" @click="popVisible = false">Confirm</el-button>-->
<!--      </span>-->
<!--      </el-dialog>-->
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { postForm } from "@/utils/request";

export default {
  name: "RequestForQuotation",
  data() {
    const messageValidator = (rule, value, callback) => {
      if (value.length > 500) {
        callback(new Error("Message length limit 150"));
      } else {
        callback();
      }
    };
    return {
      showFile: true,
      fileList: [],
      checked: false,
      // popVisible: false,
      inquiryForm: {},
      loggedIn: false,
      userInfo: {},
      uploadList: [],
      rules: {
        name: [
          {required: true, message: 'Please fill your name.', trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'Please fill your email.', trigger: 'blur'},
          {type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change']},
        ],
        company: [
          {required: true, message: 'Please fill your company.', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Please fill your phone.', trigger: 'blur'}
        ],
        message: [
          { required: true, message: 'Please fill your request.', max: 500, trigger: 'blur' },
          { validator: messageValidator, trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  created() {
    this.checkLocalStorage();
    window.addEventListener('storage', this.handleStorageChange);
  },
  destroyed() {
    window.removeEventListener('storage', this.handleStorageChange);
  },
  methods: {
    onInput() {this.$forceUpdate();},
    checkLocalStorage() {
      const loginUserEmail = localStorage.getItem('loginUserEmail');
      const userInfo = JSON.parse(localStorage.getItem('userinfo'));
      if (loginUserEmail && userInfo) {
        this.loggedIn = true;
        this.userInfo = userInfo;
        this.inquiryForm.name = userInfo.username;
        this.inquiryForm.email = userInfo.email;
        if (userInfo.company !== null) {
          this.inquiryForm.company = userInfo.company;
        }
        if (userInfo.phone !== null) {
          this.inquiryForm.phone = userInfo.phone;
        }
      } else {
        this.loggedIn = false;
        this.userInfo = {};
      }
    },
    handleStorageChange(event) {
      // 当本地存储发生变化时触发
      if (event.key === 'token' || event.key === 'userinfo') {
        this.checkLocalStorage();
      }
    },
    handleExceed() {
      Message.warning({
        message: "Current limit Select 5 file!",
        offset: 300
      });
    },
    beforeFileUpload(file){
      let fileType = true;
      if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.ms-excel') {
        fileType = true;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        Message.error({
          message: "files size limit is 5MB!",
          offset: 300 
        });
      }
      return fileType & isLt5M;
    },
    uploadChange(file, fileList) {
      // this.inquiryForm.uploadFile = fileList[0].raw;
      this.inquiryForm.uploadFile = [];
      for (let i = 0; i < fileList.length; i++) {
        this.inquiryForm.uploadFile[i] = fileList[i].raw;
      }
    },
    handleRemove(file, fileList) {
      this.inquiryForm.uploadFile = [];
      for (let i = 0; i < fileList.length; i++) {
        this.inquiryForm.uploadFile[i] = fileList[i].raw;
      }
    },
    linkDump(){
      const currentUrl = window.location.pathname + window.location.search;
      localStorage.setItem('linkDump', currentUrl);
      this.$router.push('/login');
    },
    submit() {
      this.$refs.inquiryForm.validate(valid => {
        if (valid) {
          if (!this.checked) {
            Message.warning({
              message: "Please check our privacy policy",
              offset: 300
            })
          } else {
            postForm("/customer_inquiry", this.inquiryForm).then(res => {
              if (res.code === 1) {
                Message.success({
                  message: "Success! Your Inquiry Has Been Received. We're on it! Look out for our response within the next 24 hours.",
                  offset: 300
                });
                // this.inquiryForm = {};
                // this.fileList = [];
                window.open("/thank-you", "_blank");
                window.location.reload();
              }
            })
          }
        } else {
          Message.warning({
            message: "Please fill all required parts of the form",
            offset: 300
          })
        }
      })
      // this.$refs.upload.submit()
    },
  }
}
</script>

<style scoped lang="less">
@import "./index.less";
</style>