export const getToken = function () {
    let tokenKey = localStorage.getItem("loginUserEmail") + "_token";
    let token = localStorage.getItem(tokenKey);

    return token;
}

export const isLogin = function () {
    let logInEmail = localStorage.getItem("loginUserEmail");
    let loginName = localStorage.getItem(logInEmail + "_username");

    let tokenKey = localStorage.getItem("loginUserEmail") + "_token";
    let token = localStorage.getItem(tokenKey);

    if (loginName && token) {
        return true;
    } else {
        return false;
    }
}
