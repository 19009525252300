
<template>
  <el-container>
    <el-header class="pc-home-top-banner">
      <div class="wrap">
        <p>
          <strong>10%</strong> off on first order for new users. <a href="/register">Register for a Free Account Now!</a>
        </p>
      </div>
    </el-header>
    <el-main>
      <div class="home-main-banner">
        <div class="header-and-searchbar">
          <div class="header-wrapper">
            <div
              class="the-new-header"
              :class="{
                'theme-bg-dark': !isScrolled,
                'theme-bg-white': isScrolled,
                'theme-bg-fixed': isScrolled,
                'theme-white-overlay': isHovering,
                'theme-bg-mobile': isMobile,
              }"
            >
              <div class="header-content">
                <div class="header-main">
                  <div class="logo">
                    <a href="/">
                      <img loading="lazy"
                        v-if="isScrolled"
                        :src="
                          isScrolled
                            ? require('@/assets/images/logo.png')
                            : require('@/assets/images/logo-white.png')
                        "
                        alt="logo"
                      />
                      <img loading="lazy"
                        v-else
                        :src="
                          isHovering
                            ? require('@/assets/images/logo.png')
                            : require('@/assets/images/logo-white.png')
                        "
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div class="searchbar" v-show="isSearchBarScrolled">
                    <input
                      type="text"
                      v-model="searchTermNav"
                      @keyup.enter="searchNav"
                      @input="inputValueNav"
                      @blur="associationNav = false"
                      @focus="associationNav = associationResultNav.length > 0"
                      placeholder="Keywords or the image of the product you are looking for"
                    />
                    <div class="upload">
                      <el-upload
                          class="upload-demo"
                          action="#"
                          accept=".png,.jpg,.jpeg"
                          :auto-upload="false"
                          :on-change="(file) => { return imageSearch(file) } ">
                        <el-button size="small" type="primary" icon="el-icon-camera"></el-button>
                      </el-upload>
<!--                      <b-icon icon="camera" />-->
<!--                      <input-->
<!--                        ref="fileInput"-->
<!--                        type="file"-->
<!--                        name="file"-->
<!--                        accept=".jpg,.jpeg,.png"-->
<!--                        style="display: none"-->
<!--                        @change="handleImageUpload"-->
<!--                      />-->
                    </div>
                    <button class="search" @click="searchNav">
                      <b-icon icon="search" />Search
                    </button>
                    <div v-if="associationNav" class="association">
                      <div class="title" v-if="searchTermNav.trim() == ''">Recommended for you</div>
                      <div class="list" v-for="item in associationResultNav" :key="item.query_text">
                        <a @mousedown="handleAssociationLinkClickNav($event, item)"><span>{{item.query_text}}</span></a>
                      </div>
                    </div>
                  </div>
                  <div class="functional">
                    <div class="sign-in">
                      <div v-if="!isLoggedIn">
                        <a @click="clickLogin">
                          <b-icon icon="person" />
                          <span>Sign in</span>
                        </a>
                      </div>
                      <div v-else>
                        <a>
                          <b-icon icon="person" />
                          <span>{{ username }}</span>
                        </a>
                      </div>
                      <div class="tooltip">
                        <h3>
                          Hello,<strong v-if="username">{{ username }}</strong
                          ><br />Welcome to chinabulkbuy.com!
                        </h3>
                        <button v-if="isLoggedIn" @click="logout">
                          Log Out
                        </button>
                        <a @click="clickLogin" v-else>Sign in</a>
                        <p>
                          By signing in via social media, I agree to the
                          chinabulkbuy.com Free Membership Agreement and
                          <a href="/privacy-policy">Privacy Policy,</a> and to receive emails
                          about the platform's products and services.
                        </p>
                        <ul>
                          <li @click="myAccount">My Account</li>
                          <li>
                            <a href="/request-for-quotation"
                              >Request for Quotation</a
                            >
                          </li>
                          <li @click="goWishList">Wish list</li>
                        </ul>
                      </div>
                    </div>
                    <div class="cart">
                      <a href="/cart"><b-icon icon="cart" /></a>
                      <span>{{ amount }}</span>
                    </div>
                    <a href="/register" class="register">Sign up</a>
                  </div>
                </div>
              </div>
              <div class="sub-header" v-if="!isScrolled">
                <div class="menu-toggle" @click="toggleSubMenu" :class="{ 'animate-toggle': subHeaderVisible }">
                  <i></i>
                  <i></i>
                  <i></i>
                </div>
                <div class="sub-header-default" v-if="subHeaderVisible">
                  <div class="sub-main">
                    <div
                      @mouseenter="handleMouseEnter"
                      @mouseleave="handleMouseLeave"
                    >
                      <div class="tab-title">
                        <b-icon icon="list-task" />
                        All categories
                      </div>
                      <div class="animated-tab-content">
                        <div class="tab-content">
                          <div class="animated-tab-content-children">
                            <div class="header-categories">
                              <!-- primary categories -->
                              <div class="panel-wrapper main-cate">
                                <div class="panel-content main-cate-content">
                                  <a
                                    :href="getDynamicUrl(category)"
                                    v-for="(category, index) in categories"
                                    :key="index"
                                    :class="{
                                      current: activeFirstCategory === category,
                                    }"
                                    @mouseover="showSubCategories(category)"
                                  >
                                    {{ category.category_name_trans }}
                                  </a>
                                </div>
                              </div>
                              <!-- secondary categories -->
                              <div
                                class="panel-wrapper secondary-cate"
                                v-if="currentSubCategories"
                              >
                                <div
                                  class="panel-content secondary-cate-content"
                                >
                                  <a
                                    :href="getDynamicUrl(subCategory)"
                                    v-for="(
                                      subCategory, index
                                    ) in currentSubCategories"
                                    :key="index"
                                    :class="{
                                      current:
                                        activeSecondCategory === subCategory,
                                    }"
                                    @mouseover="
                                      showFinalCategories(subCategory)
                                    "
                                  >
                                    {{ subCategory.category_name_trans }}
                                  </a>
                                </div>
                              </div>
                              <!-- final categories -->
                              <div class="final-cate">
                                <a
                                  v-if="activeSecondCategory"
                                  :href="getDynamicUrl(activeSecondCategory)"
                                  class="title"
                                >
                                  {{ activeSecondCategory.category_name_trans
                                  }}<b-icon icon="chevron-right" />
                                </a>
                                <ul>
                                  <li
                                    v-for="(
                                      finalCategory, index
                                    ) in currentFinalCategories"
                                    :key="index"
                                  >
                                    <a :href="getDynamicUrl(finalCategory)"
                                      ><span :title="finalCategory.category_name_trans">{{
                                        finalCategory.category_name_trans
                                      }}</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="tab-title">
                        <a href="/product-category/home-garden">Home & Garden</a>
                      </div>
                    </div>
                    <div>
                      <div class="tab-title">
                        <a href="/product-category/gifts-promotion">Gifts & Promotion</a>
                      </div>
                    </div>
                    <div>
                      <div class="tab-title">
                        <a href="/request-for-quotation">Request for Quotation</a>
                      </div>
                    </div>
                    <div>
                      <div class="tab-title">
                        <a href="/about-us">About us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-banner">
          <swiper :options="mainBannerOption" ref="mySwiper">
            <swiper-slide v-for="(banner, index) in mainBanner" :key="index">
              <img :src="banner.image" :alt="banner.image_alt" />
              <div class="content">
                <div v-html="banner.description"></div>
                <div v-html="banner.short_description" class="short-desc"></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="pc-home-search">
            <input
              type="text"
              v-model="searchTermBanner"
              @keyup.enter="searchBanner"
              @input="inputValueBanner"
              @blur="associationBanner = false"
              @focus="associationBanner = associationResultBanner.length > 0"
              placeholder="Keywords or the image of the product you are looking for"
            />
            <div class="upload">
              <el-upload
                  class="upload-demo"
                  action="#"
                  accept=".png,.jpg,.jpeg"
                  :auto-upload="false"
                  :on-change="(file) => { return imageSearch(file) } ">
                <el-button size="small" type="primary" icon="el-icon-camera"></el-button>
              </el-upload>
<!--              <b-icon icon="camera" />-->
<!--              <input-->
<!--                ref="fileInput"-->
<!--                type="file"-->
<!--                name="file"-->
<!--                accept=".jpg,.jpeg,.png"-->
<!--                style="display: none"-->
<!--                @change="handleImageUpload"-->
<!--              />-->
            </div>
            <button class="search" @click="searchBanner">
              <b-icon icon="search" />Search
            </button>
            <div v-if="associationBanner" class="association">
              <div class="title" v-if="searchTermBanner.trim() == ''">Recommended for you</div>
              <div class="list" v-for="item in associationResultBanner" :key="item.query_text">
                <a @mousedown="handleAssociationLinkClickBanner($event, item)"><span>{{item.query_text}}</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-lazy-component>
        <nav-wrapper/>
      </vue-lazy-component>

      <vue-lazy-component>
        <new-arrivals v-if="componentRendered" />
      </vue-lazy-component>

      <vue-lazy-component>
        <home-process v-if="componentRendered" />
      </vue-lazy-component>

      <vue-lazy-component>
        <hot-sale-product v-if="componentRendered" />
      </vue-lazy-component>

      <vue-lazy-component>
        <recommend-product v-if="componentRendered" />
      </vue-lazy-component>

      <div class="contact" v-if="componentRendered">
        <h3>
          Get a Free Account and Send Your Inquery Now!
        </h3>
        <p>
          Now, over 500 wholesalers, retailers, online sellers are purchasing hot-selling products on chinabulkbuy.com, <br />
          and reducing their procurement costs by 10-50% compared to before, and greatly increasing their sales volume. <br />
          Want to explore the reasons behind this and join them?
          <a href="/request-for-quotation">Take 1 minute to Get a Free Account Now!</a>
        </p>
        <ul>
          <li><b-icon icon="check" />0 commission permanently</li>
          <li><b-icon icon="check" />10% off for first order</li>
          <li><b-icon icon="check" />Free samples available</li>
          <li><b-icon icon="check" />7*24 service accessible</li>
        </ul>
      </div>

      <my-drawer :isShow.sync="drawer" :item="item"></my-drawer>

      <login-dialog :isShow.sync="loginDialog"></login-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin} from "@/utils/utils";
import { eventBus } from "@/common/eventBus";
import cartDrawer from "@/components/CartDrawer/index.vue";
import LoginDialog from "@/components/LoginDialog/index.vue";

export default {
  name: "HomePage",
  components: {
    LoginDialog,
    myDrawer: cartDrawer,
    NavWrapper: () => import('@/components/HomePage/NavWrapper/index.vue'),
    NewArrivals: () => import('@/components/HomePage/NewArrivals/index.vue'),
    HomeProcess: () => import('@/components/HomePage/HomeProcess/index.vue'),
    HotSaleProduct: () => import('@/components/HomePage/HotSaleProduct/index.vue'),
    RecommendProduct: () => import('@/components/HomePage/RecommendProduct/index.vue')
  },
  data() {
    return {
      metaData: {
        home_title: "",
        home_keyword: "",
        home_description: ""
      },
      searchTermNav: "",
      searchTermBanner: "",
      username: "",
      isLoggedIn: false,
      isLoading: false,
      isImageSearch: false,
      imageBase64: null,
      associationNav: false,
      associationBanner: false,
      associationResultNav: [],
      associationResultBanner: [],
      isScrolled: false,
      isSearchBarScrolled: false,
      isHovering: false,
      categories: [],
      currentSubCategories: [],
      currentFinalCategories: [],
      activeFirstCategory: null,
      activeSecondCategory: null,
      mainBanner: [],
      mainBannerOption: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 6000,
          autoplayDisableOnInteraction: true,
        },
      },
      drawer: false,
      item: {},
      collectedProducts: {},
      amount: 0,
      componentRendered: false,
      subHeaderVisible: true,
      isMobile: false,
      loginDialog: false
    };
  },
  metaInfo() {
    return {
      title: this.metaData.home_title,
      meta: [
        { charset: "utf-8" },
        { name: "keywords", content: this.metaData.home_keyword },
        { name: "description", content: this.metaData.home_description },
      ],
    };
  },
  async created() {
    this.isLoggedIn = isLogin();
    if (this.isLoggedIn) {
      let logInEmail = localStorage.getItem("loginUserEmail");
      this.username = localStorage.getItem(logInEmail + "_username");
    } else {
      this.username = "Visitor";
    }
    this.getMeta()
        .then(this.getMainBanner)
        .then(this.getCategories)
        .then(this.getCartAmount);
  },
  mounted() {
    if (!this.isMobileDevice()) {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("scroll", this.handleSearchBarScroll);
    }
    if (this.isMobileDevice()) {
      this.subHeaderVisible = false;
      this.isMobile = true;
    }
    eventBus.$on("openDrawer", (msg) => {
      this.item = {
        ip: "127.0.0.1",
        product_id: msg.source_id.toString(),
        product_name: msg.title,
        product_name_trans: msg.title_trans,
        product_image: msg.image,
        sku_image: msg.image,
        qty: msg.moq === 0 ? 1 : msg.moq,
        base_row_single: msg.price,
        quote_row_single: msg.transPrice,
        category_id: msg.source_category_id
      };
      // console.log(this.item);
      this.drawer = true;
    })

    eventBus.$on("openLoginDialog", (msg) => {
      this.loginDialog = msg;
    })
  },
  beforeDestroy() {
    if (!this.isMobileDevice()) {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleSearchBarScroll);
    }
  },
  methods: {
    toggleSubMenu() {
      this.subHeaderVisible = !this.subHeaderVisible;
    },
    logout() {
      get("/logout").then((res) => {
        if (res.code === 1) {
          // 清除本地存储中的用户信息
          // localStorage.clear();
          for (let key in localStorage) {
            if (key !== "historyViewed") {
              delete localStorage[key];
            }
          }
          this.isLoggedIn = false;
          this.username = "";
        } else {
          Message.error({
            message: res.msg,
            offset: 500
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    searchNav() {
      if (this.searchTermNav.trim() === "") {
        Message.error({
          message: "Please enter your keywords!",
          offset: 300,
        });
        return;
      }
    
      const currentRoute = this.$router.currentRoute;
      const newRoute = { name: "search", query: { SearchText: this.searchTermNav } };
      if (currentRoute.name === newRoute.name && JSON.stringify(currentRoute.query) === JSON.stringify(newRoute.query)) {
        eventBus.$emit("keywordSearch", this.searchTermNav);
      } else {
        this.$router.push(newRoute);
      }
    },
    handleAssociationLinkClickNav(event, item) {
      event.stopPropagation();
      this.searchTermNav = item.query_text;
      this.searchNav();
    },
    inputValueNav() {
      post("/search_lenovo", {
        keyword: this.searchTermNav,
      })
        .then((res) => {
          if (res.code === 1) {
            this.associationResultNav = res.data;
            this.associationNav = this.associationResultNav.length;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchBanner() {
      if (this.searchTermBanner.trim() === "") {
        Message.error({
          message: "Please enter your keywords!",
          offset: 300,
        });
        return;
      }
    
      const currentRoute = this.$router.currentRoute;
      const newRoute = { name: "search", query: { SearchText: this.searchTermBanner } };
      if (currentRoute.name === newRoute.name && JSON.stringify(currentRoute.query) === JSON.stringify(newRoute.query)) {
        eventBus.$emit("keywordSearch", this.searchTermBanner);
      } else {
        this.$router.push(newRoute);
      }
    },
    handleAssociationLinkClickBanner(event, item) {
      event.stopPropagation();
      this.searchTermBanner = item.query_text;
      this.searchBanner();
    },
    inputValueBanner() {
      post("/search_lenovo", {
        keyword: this.searchTermBanner,
      })
        .then((res) => {
          if (res.code === 1) {
            this.associationResultBanner = res.data;
            this.associationBanner = this.associationResultBanner.length;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    imageSearch(file) {
      if (this.$router.currentRoute.name === "search") {
        eventBus.$emit("imageSearch", file.raw);
      } else {
        this.$router.push({
          name: 'search',
          params: {
            file: file.raw
          }
        });
      }
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 106;
    },
    handleSearchBarScroll() {
      this.isSearchBarScrolled = window.scrollY > 500;
    },
    handleMouseEnter() {
      this.isHovering = true;
    },
    handleMouseLeave() {
      this.isHovering = false;
    },
    getMeta() {
      let that = this;
      const p = new Promise(function (resolve) {
        get("/home_meta").then(res => {
          if (res.code === 1) {
            that.metaData = res.data;
            resolve(true);
          } else {
            Message.error({
              message: res.msg,
              offset: 300
            });
          }
        })
      })
      return p;

      // try {
      //   const res = await get("/home_meta");
      //   if (res.code === 1) {
      //     this.metaData = res.data;
      //   } else {
      //     Message.error({
      //       message: res.msg,
      //       offset: 500
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
    },
    getMainBanner() {
      let that = this;
      const p = new Promise(function (resolve) {
        get("/home_banner").then(res => {
          if (res.code === 1) {
            that.mainBanner = res.data;
            resolve(true);
          } else {
            Message.error({
              message: res.msg,
              offset: 300
            });
          }
        })
      })
      return p;
      // try {
      //   const res = await get("/home_banner");
      //   if (res.code === 1) {
      //     this.mainBanner = res.data;
      //   } else {
      //     Message.error({
      //       message: res.msg,
      //       offset: 500
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
    },
    getCategories() {
      let that = this;
      const p = new Promise(resolve => {
        get("/home_category").then(res => {
          if (res.code === 1) {
            that.categories = res.data;
            that.showSubCategories(that.categories[0]);
            resolve(true);
          } else {
            Message.error({
              message: res.msg,
              offset: 500
            });
          }
        })
      })
      return p;
      // try {
      //   const res = await get("/home_category");
      //   if (res.code === 1) {
      //     this.categories = res.data;
      //     this.showSubCategories(this.categories[0]);
      //   } else {
      //     Message.error({
      //       message: res.msg,
      //       offset: 500
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
    },
    showSubCategories(category) {
      this.currentFinalCategories = [];
      this.currentSubCategories = category.children || [];
      this.activeFirstCategory = category;

      if (category.children && Array.isArray(category.children)) {
        let num = 1;
        category.children.forEach((subCategory) => {
          if (num === 1) {
            this.showFinalCategories(subCategory);
            num++;
          }
        });
      }
    },
    showFinalCategories(subCategory) {
      this.currentFinalCategories = subCategory.children || [];

      if (this.currentFinalCategories.length === 0) {
        this.activeSecondCategory = null;
      } else {
        this.activeSecondCategory = subCategory;
      }
    },
    getDynamicUrl(itemOrCategory) {
      if (itemOrCategory.source_id) {
        // 对于产品详情
        return `/product-details/?product_id=${itemOrCategory.source_id}`;
      } else if (itemOrCategory.url_path) {
        // 对于产品分类
        return `/product-category/${itemOrCategory.url_path}`;
      }
    },
    // openDrawer(item) {
    //   this.item = item;
    //   eventBus.$emit("openDrawer", item.source_id);
    // },
    myAccount(){
      if (isLogin()) {
        this.$router.push("/account");
      } else {
        this.loginDialog = true;
      }
    },
    clickLogin() {
      const currentUrl = window.location.pathname + window.location.search;
      localStorage.setItem("linkDump", currentUrl);
      window.location.href="/login";
    },
    getCartAmount() {
      let that = this;
      const p = new Promise(resolve => {
        if (isLogin()) {
          get("/cart").then(res => {
            if (res.code === 1) {
              if (res.data.items) {
                that.amount = res.data.items.length;
              }
              resolve(true);
            }
          })
        } else {
          if (localStorage.getItem("cartList")) {
            let cartList = JSON.parse(localStorage.getItem("cartList"));
            that.amount = cartList.length;
            resolve(true);
          }
        }
      })
      that.componentRendered = true;
      return p;
    },
    goWishList() {
      if (isLogin()) {
        if (this.$router.currentRoute.name === 'account') {
          eventBus.$emit("toPanel", "2");
        } else {
          this.$router.push({
            name: 'account',
            params: {
              index: "2"
            }
          })
        }
      } else {
        this.loginDialog = true;
      }
    },
    
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
