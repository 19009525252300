
<template>
  <div class="category-wrap">
    <div class="category-top-banner">
      <img loading="lazy" :src="banner" />
      <div class="content">
        <h1>{{ category }}</h1>
        <p>{{ categoryDescription }}</p>
      </div>
    </div>

    <div class="nav-wrapper">
      <div class="home-dot-element">
        <el-row>
          <el-col :span="24">
            <el-row class="row-first" type="flex" :gutter="20">
              <el-col class="row-first-left" :span="14">
                <h3>
                  Perfectly Tailored to Your Business Needs for Bulk Buying
                  China {{ category }}
                </h3>
              </el-col>
              <el-col :span="10">
                <el-row type="flex" :gutter="20" justify="end" class="num">
                  <el-col :span="12">
                    <h4>50K+</h4>
                    <span>Factories</span>
                  </el-col>
                  <el-col :span="6">
                    <h4>200M+</h4>
                    <span>Products</span>
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="20" justify="end" class="num">
                  <el-col :span="12">
                    <h4>2000+</h4>
                    <span>Procurement Staff</span>
                  </el-col>
                  <el-col :span="6">
                    <h4>5000+</h4>
                    <span>Customers</span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-row class="row-second" type="flex" :gutter="20">
              <el-col :span="6" align="center">
                <div class="item">
                  <img loading="lazy" src="@/assets/images/flexible-purchasing.png" />
                  <h3>Flexible Order Quantity</h3>
                  <p>From large OEM orders to dropshipping single items, all are supported.</p>
                </div>
              </el-col>
              <el-col :span="6" align="center">
                <div class="item">
                  <img loading="lazy" src="@/assets/images/instant-market-insights.png" />
                  <h3>One-Stop Sourcing</h3>
                  <p>200+ million products for selection, with thousands of new arrivals daily.</p>
                </div>
              </el-col>
              <el-col :span="6" align="center">
                <div class="item">
                  <img loading="lazy" src="@/assets/images/comprehensive-selection.png" />
                  <h3>Hot-selling Product Insights</h3>
                  <p>Best-selling, trending items, and market reports are featured periodically for your selection.</p>
                </div>
              </el-col>
              <el-col :span="6" align="center">
                <div class="item">
                  <img loading="lazy" src="@/assets/images/convenient-transactions.png" />
                  <h3>No import experience needed</h3>
                  <p>Door-to-door shipping and customs clearance services are available.</p>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="category-top-rank">
      <div class="top-ranking-img">
        <img loading="lazy" src="@/assets/images/top_ranking_image.png" />
      </div>
      <div v-if="topRank.length > 0">
        <swiper :options="topRankOption" ref="mySwiper">
          <swiper-slide v-for="slide in topRank" :key="slide.id">
            <a :href="getDynamicUrl(slide)">
              <img loading="lazy" :src="slide.image" />
            </a>
          </swiper-slide>
        </swiper>
        <div v-if="topRank.length > 0" class="swiper-button-prev"></div>
        <div v-if="topRank.length > 0" class="swiper-button-next"></div>
        <div v-if="topRank.length > 0" class="swiper-pagination"></div>
      </div>
    </div>

    <div class="products" id="products">
      <div class="second-category">
        <el-tabs v-model="activeSecond" @tab-click="secondCategoryChange">
          <el-tab-pane
            v-for="(item, index) in secondCategory"
            :key="index"
            :label="item.category_name"
            :name="item.id"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="third-category">
        Related searches:
        <div v-if="thirdCategory">
          <el-radio-group
            v-model="activeThird"
            size="small"
            @change="thirdCategoryChange"
          >
            <div v-for="(item, index) in thirdCategory" :key="index">
              <el-radio-button :label="item.id">
                {{ item.category_name }}
              </el-radio-button>
            </div>
          </el-radio-group>
        </div>
        <div v-else class="all-span">
          <span>ALL</span>
        </div>
      </div>
      <div class="product-area" v-loading="loading">
        <div
          class="product-card"
          v-for="(item, index) in productList"
          :key="index"
        >
          <div class="image">
            <a :href="getDynamicUrl(item)">
              <img loading="lazy" :src="item.imageUrl" :alt="item.imageUrl" />
            </a>
            <div class="collect" @click="addCollect(item)">
              <b-icon
                :icon="isCollected(item.offerId) ? 'heart-fill' : 'heart'"
              />
            </div>
          </div>
          <a
            :href="getDynamicUrl(item)"
            class="title"
            :title="item.subjectTrans"
            >{{ item.subjectTrans }}</a
          >
          <span class="price">${{ item.transPriceInfo.price }}</span>
          <div class="order">
            <span>Door to Door Delivery</span>
          </div>
          <div class="sample">
            <img loading="lazy" src="@/assets/images/free.png" />
            <span>Sample</span>
          </div>
          <div class="deal">
            <div class="scrolling-text">
              <div>First Deal <strong>10% OFF</strong></div>
              <div>First Deal2 <strong>10% OFF</strong></div>
              <div>First Deal3 <strong>10% OFF</strong></div>
              <div>First Deal4 <strong>10% OFF</strong></div>
            </div>
          </div>
          <button @click="openDrawer(item)" class="addtocart">
            Add to cart
          </button>
        </div>
      </div>
      <div class="pagination" @click="scrollToProduct">
        <el-pagination
          layout="prev, pager, next"
          :page-count="pagination.totalPage"
          :current-page="pagination.currentPage"
          @current-change="handlePageChange"
          @prev-click="handlePrevClick"
          @next-click="handleNextClick"
        >
        </el-pagination>
      </div>
    </div>

    <div class="contact">
      <h3>
        Get a Free Account and Send Your Inquery Now!
      </h3>
      <p>
        Now, over 500 wholesalers, retailers, online sellers are purchasing hot-selling products on chinabulkbuy.com, <br />
        and reducing their procurement costs by 10-50% compared to before, and greatly increasing their sales volume. <br />
        Want to explore the reasons behind this and join them?
        <a href="/request-for-quotation">Take 1 minute to Get a Free Account Now!</a>
      </p>
      <ul>
        <li><b-icon icon="check" />0 commission permanently</li>
        <li><b-icon icon="check" />10% off for first order</li>
        <li><b-icon icon="check" />Free samples available</li>
        <li><b-icon icon="check" />7*24 service accessible</li>
      </ul>
    </div>

    <my-drawer :isShow.sync="drawer" :item="item"></my-drawer>

    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </div>
</template>
<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
import cartDrawer from "@/components/CartDrawer/index.vue";
import LoginDialog from "@/components/LoginDialog/index.vue";
// import {eventBus} from "@/common/eventBus";
export default {
  name: "ProductCategoryPage",
  components: {
    myDrawer: cartDrawer,
    LoginDialog
  },
  metaInfo() {
    return {
      title: this.metaData.meta_title,
      meta: [
        { charset: "utf-8" },
        { name: "keywords", content: this.metaData.meta_keyword },
        { name: "description", content: this.metaData.meta_description },
      ],
    };
  },
  data() {
    return {
      metaData: {
        meta_title: "",
        meta_keyword: "",
        meta_description: "",
      },
      categoryId: "",
      banner: "",
      category: "",
      categoryDescription: "",
      topRank: [],
      topRankOption: {
        loop: false,
        autoplay: {
          delay: 30000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            slidesPerGroup: 1
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 38,
            slidesPerGroup: 1
          },
        },
      },
      loading: false,
      secondElement: {
        url_path: "",
        category_name: "",
        id: "",
        children: {},
      },
      secondCategory: [],
      thirdCategory: [],
      activeSecond: "",
      activeThird: "",
      activeCategory: "",
      productList: [],
      pagination: {
        totalRecords: 0,
        totalPage: 0,
        pageSize: 30,
        currentPage: 1,
      },
      drawer: false,
      sid: "",
      item: {},
      collectedProducts: {},
      loginDialog: false
    };
  },
  async created() {
    if (window.innerWidth < 769) {
      this.topRankOption.slidesPerView = 1;
    }
    const urlPath = this.$route.params.urlPath;
    await this.getCategoryInfo(urlPath);
    await this.loadCollectedList();
  },
  methods: {
    async getCategoryInfo(urlPath) {
      try {
        const res = await post("/category", {
          //category_id: this.categoryId
          path: urlPath,
        });
        if (res.code === 1) {
          //meta信息
          this.metaData.meta_title = res.data.topCategoryMsg.meta_title;
          this.metaData.meta_keyword = res.data.topCategoryMsg.meta_keyword;
          this.metaData.meta_description =
            res.data.topCategoryMsg.meta_description;

          //一些顶级分类信息
          this.banner = res.data.topCategoryMsg.banner;
          this.category = res.data.topCategoryMsg.category_name;
          this.categoryDescription = res.data.topCategoryMsg.description;
          this.topRank = res.data.recommendProduct;

          //遍历二级分类
          this.secondCategory.push({
            id: res.data.topCategoryMsg.id.toString(),
            category_name: "All",
          });
          res.data.Level.forEach((item) => {
            this.secondElement = {
              id: item.id.toString(),
              category_name: item.category_name,
              children: item.children,
            };
            this.secondCategory.push(this.secondElement);

            if (item.active !== undefined && item.active === 1) {
              //如果二级分类有选中的
              this.activeSecond = item.id.toString();
              this.thirdCategory.push({
                id: this.activeSecond,
                category_name: "All",
              });

              if (item.children !== undefined) {
                item.children.forEach((item) => {
                  this.thirdCategory.push({
                    id: item.id.toString(),
                    category_name: item.category_name,
                  });
                  if (item.active !== undefined && item.active === 1) {
                    this.activeThird = item.id;
                    this.activeCategory = this.activeThird;
                  }
                });
              }

              if (this.activeThird === "") {
                this.activeCategory = this.activeSecond;
                this.activeThird = this.activeCategory;
              }
            }
          });
          //如果遍历二级分类无active，则为一级分类
          if (this.activeCategory <= 1 && this.activeSecond <= 1) {
            this.activeCategory = res.data.topCategoryMsg.id.toString();
            this.activeSecond = this.activeCategory;
          }
        } else {
          Message.error({
            message: res.msg,
            offset: 300 
          });
        }
      } catch (err) {
        console.log(err);
      }
      await this.searchProducts();
    },
    async searchProducts() {
      this.loading = true;
      try {
        const res = await post("/category_product", {
          ip: "127.0.0.1",
          category_id: this.activeCategory,
          beginPage: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
        });
        if (res.code === 1) {
          this.productList = res.data.data;
          this.pagination.totalPage = res.data.totalPage;
          this.pagination.currentPage = res.data.currentPage;
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    secondCategoryChange(activeName) {
      this.activeCategory = activeName.name;
      this.activeThird = "";
      this.pagination.currentPage = 1;
      this.secondCategory.forEach((item) => {
        if (item.id === this.activeCategory) {
          this.thirdCategory = [
            {
              id: item.id,
              category_name: "All",
            },
          ];
          if (item.children !== undefined) {
            item.children.forEach((e) => {
              this.thirdCategory.push({
                id: e.id,
                category_name: e.category_name,
              });
            });
          }
          this.activeThird = item.id;
        }
      });
      this.searchProducts();
    },
    thirdCategoryChange() {
      this.activeCategory = this.activeThird;
      this.pagination.currentPage = 1;
      this.searchProducts();
    },
    handlePageChange(page) {
      this.pagination.currentPage = page;
      this.searchProducts();
    },
    handlePrevClick() {
      this.pagination.currentPage -= 1;
      this.searchProducts();
    },
    handleNextClick() {
      this.pagination.currentPage += 1;
      this.searchProducts();
    },
    async loadCollectedList() {
      try {
        const res = await get("/my_collect_list");
        if (res.code === 1) {
          // 如果返回的数据包含已收藏的 offerId，则将其添加到 collectedProducts 中
          res.data.forEach((offerId) => {
            this.$set(this.collectedProducts, offerId, true);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    addCollect(item) {
      if (isLogin()) {
        let productImage = "";
        if (item.imageUrl.includes("http")) {
          productImage = item.imageUrl;
        }
        if (this.isCollected(item.offerId)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + item.offerId + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的offerId
                this.$set(this.collectedProducts, item.offerId, false);
                // Message.success({
                //   message: "Remove success.",
                //   offset: 500 
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 300 
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          post("/add_collect", {
            category: this.activeCategory,
            source_id: item.offerId,
            product_image: productImage,
            product_name: item.subjectTrans,
            product_price: item.transPriceInfo.price,
          })
            .then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的offerId
                this.$set(this.collectedProducts, item.offerId, true);
                // Message.success({
                //   message: "Collect success!",
                //   offset: 500 
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 300 
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    isCollected(offerId) {
      // 判断产品是否已被收藏
      return (
        Object.prototype.hasOwnProperty.call(this.collectedProducts, offerId) &&
        this.collectedProducts[offerId]
      );
    },
    getDynamicUrl(itemOrSlide) {
      if (itemOrSlide.offerId) {
        // 如果有 offerId，说明是 item
        return `/product-details/?product_id=${itemOrSlide.offerId}`;
      } else if (itemOrSlide.source_id) {
        // 如果有 source_id，说明是 slide
        return `/product-details/?product_id=${itemOrSlide.source_id}`;
      }
    },
    openDrawer(item) {
      this.item = {
        ip: "127.0.0.1",
        product_id: item.offerId.toString(),
        product_name: item.subject,
        product_name_trans: item.subjectTrans,
        product_image: item.imageUrl,
        sku_image: item.imageUrl,
        qty: 1,
        base_row_single: item.priceInfo.price,
        quote_row_single: item.transPriceInfo.price,
        category_id: item.source_category_id
      };
      this.drawer = true;
      // console.log(this.item);
      // eventBus.$emit("categoryCart", item);
      // this.sid = item.offerId.toString();
    },
    scrollToProduct() {
      document.getElementById("products").scrollIntoView();
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>