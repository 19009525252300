<template>
  <el-dialog
      width="30%"
      :visible.sync="visible"
      :before-close="handleClose">
    <div class="title">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Sign in" name="first">
        <div class="login-form">
          <el-form :model="loginForm" status-icon :rules="loginRules">
            <el-form-item label="Email address" prop="email">
              <el-input type="email" v-model="loginForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                  :type="passwordFieldType"
                  v-model="loginForm.password"
              ></el-input>
              <b-icon
                  class="password-eye"
                  icon="eye-slash-fill"
                  @click="togglePasswordVisibility('text')"
                  v-if="!showPassword"
              />
              <b-icon
                  class="password-eye"
                  icon="eye-fill"
                  @click="togglePasswordVisibility('password')"
                  v-if="showPassword"
              />
            </el-form-item>
            <div class="remember">
              <el-checkbox v-model="loginChecked">Remember Me</el-checkbox>
            </div>
            <div class="submit" @click="submit">
              <span>Login Now</span>
            </div>
            <div class="forgot">
              <a href="/forgot-password">Forgot your password?</a>
            </div>
          </el-form>
          <p>New User? <a @click="activeTab = 'second'">Join Free</a></p>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Register" name="second">
        <div class="register-form">
          <el-form
              :model="registerForm"
              status-icon
              :rules="registerRules"
              ref="registerForm"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <h3>Get Started with a Free account!</h3>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="First name" prop="firstname">
                  <el-input v-model="registerForm.firstname"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Last name" prop="lastname">
                  <el-input v-model="registerForm.lastname"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item label="Email address" prop="email">
                  <el-input type="email" v-model="registerForm.email"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item label="Password" prop="password">
                  <el-input
                      :type="passwordFieldType"
                      v-model="registerForm.password"
                  ></el-input>
                  <b-icon
                      class="password-eye"
                      icon="eye-slash-fill"
                      @click="togglePasswordVisibility('text')"
                      v-if="!showPassword"
                  />
                  <b-icon
                      class="password-eye"
                      icon="eye-fill"
                      @click="togglePasswordVisibility('password')"
                      v-if="showPassword"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Enter Verification Code" prop="verify_code">
                  <el-input v-model="registerForm.verify_code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="register-code">
              <span
                  v-show="!showSuccess && !showError"
                  @click.prevent="getVerificationCode"
                  class="code"
              >Get Verification Code</span
              >
                  <div class="success" v-show="showSuccess">
                    <b-icon icon="check-circle" />Code sent {{ timerSeconds }}s
                  </div>
                  <div class="error" v-show="showError">
                    Didn't receive it?
                    <span @click.prevent="getVerificationCode" class="code"
                    >Get another code</span
                    >
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-checkbox v-model="registerChecked"
                >Send me emails about new arrivals, hot items, daily savings, &
                  more.</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" align="middle">
                <el-button type="primary" @click="register" plain
                ><strong>Creat Account</strong></el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import {get, post} from "@/utils/request";
import { Message } from "element-ui";

export default {
  name: 'LoginDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      activeTab: "first",
      loginForm: {},
      loginChecked: false,
      loginRules: {
        email: [
          {
            required: true,
            message: "Please fill your email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address.",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill your password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;
              if (!regex.test(value)) {
                callback(
                    new Error(
                        "Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."
                    )
                );
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
      },
      showPassword: false,
      registerForm: {
        ip: "131.113.55.2",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        verify_code: "",
      },
      registerRules: {
        firstname: [
          {
            required: true,
            message: "Please fill your first name.",
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: "Please fill your last name.",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please fill your email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email address.",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill your password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=?[\]{};':"\\|,.<>/])(?=.*[0-9])(?!.*\s).{6,20}$/;
              if (!regex.test(value)) {
                callback(
                    new Error(
                        "Password must include: 6-20 characters, both upper & lower letters, and at least one number or symbol."
                    )
                );
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
        verify_code: [
          {
            required: true,
            message: "Please enter verification code.",
            trigger: ["blur"],
          },
        ],
      },
      registerChecked: true,
      showSuccess: false,
      showError: false,
      timerSeconds: 0,
      timerInterval: null
    }
  },
  watch: {
    // 监听 改变
    isShow: {
      handler (val) {
        this.visible = val;
      },
      immediate: true
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    togglePasswordVisibility(type) {
      this.showPassword = type === "text";
    },
    getVerificationCode() {
      if (!this.registerForm.email) {
        Message.error({
          message: "Please Enter Your Email.",
          offset: 300,
        });
        return;
      }
      const url = `/verifyCode/?email=${this.registerForm.email}&type=0`;
      get(url)
          .then((res) => {
            if (res.code === 1) {
              this.showSuccess = true;
              this.showError = false;
              this.startTimer();
            } else {
              Message.error({
                message: "Sending code error: " + res.msg,
                offset: 300,
              });
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
    },
    handleClose () {
      this.$emit('update:isShow', false);
      this.loginForm = {};
      this.registerForm = {};
    },
    submit() {
      post("/login", this.loginForm).then((res) => {
        if (res.code === 1) {
          Message.success({
            message: "Login success!",
            offset: 300,
          });
          // 将 token 存储在本地
          const token = res.data.token;
          const email = res.data.userinfo.email;
          localStorage.setItem("loginUserEmail", res.data.userinfo.email); //email
          localStorage.setItem(email + "_token", token); //token
          const username = res.data.userinfo.username; // 提取用户名
          localStorage.setItem(email + "_username", username); //username
          localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo)); //userinfo

          this.syncCart().then(function () {
            location.reload();
          })
        } else {
          Message.error({
            message: "Submit Error: " + res.msg,
            offset: 300,
          });
        }
      });
    },
    startTimer() {
      this.timerSeconds = 60; // 设置倒计时初始时间
      this.timerInterval = setInterval(() => {
        if (this.timerSeconds > 0) {
          this.timerSeconds--; // 每秒减少一秒
        } else {
          clearInterval(this.timerInterval); // 倒计时结束，清除计时器
          this.showSuccess = false;
          this.showError = true; // 显示错误提示
        }
      }, 1000); // 每秒执行一次
    },
    register() {
      post("/register", this.registerForm)
          .then((res) => {
            if (!this.checked) {
              Message.warning({
                message:
                    "Please check the send me emails about new arrivals, hot items, daily savings, & more..",
                offset: 300,
              });
            }
            if (res.code === 1) {
              Message.success({
                message: "Registered successfully.",
                offset: 300,
              });
              this.registerForm = {};
              this.showSuccess = false;
              this.showError = false;

              // 将 token 存储在本地
              const token = res.data.token;
              localStorage.setItem("loginUserEmail", res.data.userinfo.email); //email
              localStorage.setItem(res.data.userinfo.email + "_token", token); //token
              const username = res.data.userinfo.username; // 提取用户名
              localStorage.setItem(
                  res.data.userinfo.email + "_username",
                  username
              ); //username
              localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo)); //userinfo

              this.syncCart().then(function () {
                location.reload();
              })
            } else {
              Message.error({
                message: "Register Error: " + res.msg,
                offset: 300,
              });
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
    },
    async syncCart() {
      const p = new Promise(function (resolve, reject) {
        let cartList;
        if (localStorage.getItem("cartList")) {
          cartList = JSON.parse(localStorage.getItem("cartList"));
          localStorage.setItem("cartList", "");
        } else {
          resolve();
        }

        if (cartList !== undefined) {
          cartList.forEach(n => {
            setTimeout(() => {
              if (n.spec_id) {
                post("/add_to_cart", n).then(res => {
                  if (res.code === 1) {
                    resolve();
                  } else {
                    console.log(res);
                    reject();
                  }
                })
              } else {
                post("/add_final_to_cart", n).then(res => {
                  if (res.code === 1) {
                    resolve();
                  } else {
                    console.log(res);
                    reject();
                  }
                })
              }
            }, 300);
          });
        }
      });
      return p;
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
