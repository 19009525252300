<template>
  <div>
    <el-card shadow="never">
      <div class="address-header">
        <h3>Address Management</h3>
        <el-button type="custom" @click="openDialog">Add new Address</el-button>
      </div>
    </el-card>
    <el-card class="address-card" shadow="never">
      <div class="address-form" v-loading="loading">
        <div v-for="(item, index) in addressList" :key="index">
          <div class="address-element">
            <div class="address-name">
              <span>{{ item.name + ", " }}</span>
              <span>{{ item.phone }}</span>
            </div>
            <div class="address-content">
              <span>{{ item.address + ", " + item.city + ", " + item.country + ", " + item.postcode }}</span>
            </div>
            <div class="address-edit">
              <el-link @click="openDialog(item)" :underline="false"><b-icon icon="pencil"/></el-link>
              <el-link @click="deleteAddress(item)" :underline="false"><b-icon icon="trash"/></el-link>
              <el-button type="default" v-if="item.is_default === 1">default</el-button>
              <el-button type="normal" v-else @click="setDefault(item)">default</el-button>
            </div>
          </div>
          <el-divider/>
        </div>
      </div>
      <div class="address-pagination">
        <el-pagination
          layout="prev, pager, next"
          :total="pagination.total_page"
          @current-change="handlePageChange"
          @prev-click="handlePrevClick"
          @next-click="handleNextClick">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
        title="Address edit"
        width="40%"
        class="address-dialog"
        :visible.sync="dialogVisible">
      <el-form
          :model="form"
          label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="Postcode">
              <el-input v-model="form.postcode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="City">
              <el-input v-model="form.city"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Country">
              <el-input v-model="form.country"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="confirm" @click="formConfirm">Confirm</el-button>
        <el-button type="cancel" @click="dialogVisible = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { post, get } from "@/utils/request";
import {Message} from "element-ui";

export default {
  name: 'AddressPanel',
  data() {
    return {
      addressList: [],
      beginPage: 1,
      dialogVisible: false,
      form: {},
      loading: false,
      pageSize: 10,
      pagination: {
        total_page: 10,
        current_page: 1,
        begin_page: 1,
        page_size: 10
      }
    }
  },
  created() {
    this.getAddressList();
  },
  methods: {
    async getAddressList() {
      this.loading = true;
      this.addressList = {};
      post("/address/list", {
        beginPage: this.beginPage,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 1) {
          this.pagination = {
            total_page: res.data.total_page,
            current_page: res.data.current_page,
            page_size: res.data.page_size
          }
          this.addressList = res.data.list;
          this.loading = false;
        }
      })
    },
    formConfirm() {
      if (this.form.id === undefined) {
        this.form.is_default = 0;
        post("/address/store", this.form).then(res => {
          if (res.code === 1) {
            Message.success("Address added");
            this.getAddressList();
          } else {
            console.log(res.msg);
          }
        })
      } else {
        post("/address/update", this.form).then(res => {
          if (res.code === 1) {
            Message.success("Address updated");
            this.getAddressList();
          } else {
            console.log(res.msg);
          }
        })
      }
      this.dialogVisible = false;
    },
    deleteAddress(item) {
      post("/address/delete", {
        id: item.id
      }).then(res => {
        if (res.code === 1) {
          Message.success("Address deleted");
          this.getAddressList();
        }
      })
    },
    setDefault(item) {
      get("/address/update_default?id=" + item.id).then(res => {
        if (res.code === 1) {
          Message.success("Default address set");
          this.getAddressList();
        }
      })
    },
    openDialog(item) {
      if (item.id) {
        this.form = item;
      } else {
        this.form = {};
      }
      this.dialogVisible = true;
    },
    handlePageChange() {
      this.beginPage = this.pagination.current_page;
      this.getAddressList();
    },
    handlePrevClick() {
      this.beginPage = --this.pagination.current_page;
      this.getAddressList();
    },
    handleNextClick() {
      this.beginPage = ++this.pagination.current_page;
      this.getAddressList();
    },
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
