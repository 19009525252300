<template>
  <div>
    <div class="home-recommend-product">
      <div class="recommend-cont">
        <div
          class="item"
          v-for="(product, index) in currentProduct"
          :key="index"
        >
          <div class="image">
            <a :href="getDynamicUrl(product)">
              <img loading="lazy" :src="product.image" :alt="product.title_trans" />
            </a>
            <div class="collect" @click="addCollect(product)">
              <b-icon
                :icon="isCollected(product.source_id) ? 'heart-fill' : 'heart'"
              />
            </div>
          </div>
          <a
            :href="getDynamicUrl(product)"
            class="title"
            :title="product.title_trans"
            >{{ product.title_trans }}</a
          >
          <span class="price">${{ product.transPrice }}</span>
          <div class="order">
            <span>Min Order: {{ product.moq }} pieces</span>
            <span>Door to Door Delivery</span>
          </div>
          <div class="sample">
            <img loading="lazy" src="@/assets/images/free.png" />
            <span>Sample</span>
          </div>
          <div class="deal">
            <div class="scrolling-text">
              <div>First Deal <strong>10% OFF</strong></div>
              <div>First Deal2 <strong>10% OFF</strong></div>
              <div>First Deal3 <strong>10% OFF</strong></div>
              <div>First Deal4 <strong>10% OFF</strong></div>
            </div>
          </div>
          <button @click="openDrawer(product)" class="addtocart">Add to cart</button>
        </div>
      </div>
    </div>

    <my-drawer :isShow.sync="drawer" :sid="sid"></my-drawer>
    <login-dialog :isShow.sync="loginDialog"></login-dialog>
  </div>
</template>

<script>
import { get, post } from "@/utils/request";
import { Message } from "element-ui";
import { isLogin } from "@/utils/utils";
import cartDrawer from "@/components/CartDrawer/index.vue";
import LoginDialog from "@/components/LoginDialog/index.vue";
export default {
  name: "RecommendCategoryPage",
  components: {
    myDrawer: cartDrawer,
    LoginDialog
  },
  data() {
    return {
      currentProduct: [],
      recommendCategoryProduct: [],
      collectedProducts: {},
      item: {},
      drawer: false,
      loginDialog: false
    };
  },
  async created() {
    const urlPath = this.$route.params.urlPath;
    await this.getrecommendCategoryProduct(urlPath);
    await this.loadCollectedList();
  },
  methods: {
    async getrecommendCategoryProduct(urlPath) {
      console.log(urlPath);
      try {
        const res = await get("/home_recommend_category");
        if (res.code === 1) {
          this.recommendCategoryProduct = res.data;

          // 获取当前页面的 urlPath，动态参数的值
          const currentUrlPath = this.$route.params.urlPath;

          // 遍历产品数据，查找与当前页面的 urlPath 匹配的产品
          for (let product of this.recommendCategoryProduct) {
            if (product.url_path === currentUrlPath) {
              this.currentProduct = product.products;
              break; // 找到匹配的产品后，结束循环
            }
          }
        } else {
          Message.error({
            message: res.msg,
            offset: 500 
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadCollectedList() {
      try {
        const res = await get("/my_collect_list");
        if (res.code === 1) {
          // 如果返回的数据包含已收藏的 source_id，则将其添加到 collectedProducts 中
          res.data.forEach((source_id) => {
            this.$set(this.collectedProducts, source_id, true);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    addCollect(product) {
      if (isLogin()) {
        let productImage = "";
        if (product.image.includes("http")) {
          productImage = product.image;
        }
        if (this.isCollected(product.source_id)) {
          // 取消收藏
          post("/remove_collect", {
            source_id: "[" + product.source_id + "]",
          })
            .then((res) => {
              if (res.code === 1) {
                // 取消收藏成功后，将产品的收藏状态设置为false，并移除对应的source_id
                this.$set(this.collectedProducts, product.source_id, false);
                // Message.success({
                //   message: "Remove success.",
                //   offset: 500
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500 
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        } else {
          // 添加收藏
          let category = this.getCategoryForProduct(product);
          console.log(category);
          post("/add_collect", {
            category: category.recommend_category_name_trans,
            source_id: product.source_id,
            product_image: productImage,
            product_name: product.title_trans,
            product_price: product.transPrice,
          })
            .then((res) => {
              if (res.code === 1) {
                // 收藏成功后，将产品的收藏状态设置为true，并添加对应的source_id
                this.$set(this.collectedProducts, product.source_id, true);
                // Message.success({
                //   message: "Collect success!",
                //   offset: 500
                // });
              } else {
                Message.error({
                  message: res.msg,
                  offset: 500 
                });
              }
            })
            .catch((err) => {
              console.error("Error:", err);
            });
        }
      } else {
        this.loginDialog = true;
      }
    },
    getCategoryForProduct(product) {
      // 遍历 recommendCategoryProduct 查找与当前产品匹配的分类
      for (const category of this.recommendCategoryProduct) {
        for (const p of category.products) {
          if (p.source_id === product.source_id) {
            return category; // 返回匹配的分类
          }
        }
      }
      return null; 
    },
    isCollected(source_id) {
      // 判断产品是否已被收藏
      return (
        Object.prototype.hasOwnProperty.call(
          this.collectedProducts,
          source_id
        ) && this.collectedProducts[source_id]
      );
    },
    getDynamicUrl(product) {
      return `/product-details/?product_id=${product.source_id}`;
    },
    openDrawer(product) {
      this.item = {
        ip: "127.0.0.1",
        product_id: product.source_id.toString(),
        product_name: product.title,
        product_name_trans: product.title_trans,
        product_image: product.image,
        sku_image: product.image,
        qty: product.moq === 0 ? 1 : product.moq,
        base_row_single: product.price,
        quote_row_single: product.transPrice,
        category_id: product.source_category_id
      };
      this.drawer = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
